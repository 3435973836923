import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import ContentSection from '../../../../components/blocks/content-section';
import { Colors, Spacing } from '../../../../config/styling.constants';
import { toastifyPromise } from '../../../../utils/toast-utils';
import Col from 'react-bootstrap/Col';
import styled, { css } from 'styled-components';
import { DefaultSelectStyles } from '../../../../components/dropdown';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { IManager } from '../../../../types/manager.types';
import ServiceAPI from '../../../../utils/api/services.api';
import { RootStore } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { selectServiceOwners } from '../../../../redux/usersManagement/service-owners.selector';
import { loadServiceOwners } from '../../../../redux/usersManagement/service-owners.actions';
import { ReactComponent as RemoveIcon } from '../../../../assets/images/remove_icon.svg';

export interface OwnersPropsForm {
    serviceId: string;
    owners: IManager[];
    onSuccess: () => void;
    allServiceOwners?: IManager[] | undefined;
}

const ServiceOwnerListWrapper = styled.div`
    display: flex;
    padding-left: 40px;
    align-items: center;
`;

const ServiceOwnerTagContainer = styled.div`
    margin-left: 4px;
    background-color: ${Colors.white};
    border-radius: 2px;
    display: flex;
    margin-top: 2px;
    min-width: 0;
    box-sizing: border-box;
`;

const ServiceOwnerTagText = styled.div`
    border-radius: 2px;
    color: rgb(51, 51, 51);
    font-size: 85%;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 3px 3px 3px 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
`;

const RemoveIconContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    border-radius: 2px;

    ${({ disabled }) =>
        disabled
            ? css`
                  opacity: 0.4;
                  cursor: not-allowed;
              `
            : css`
                  &:hover {
                      background-color: rgb(255, 189, 173);
                      cursor: pointer;

                      svg {
                          fill: rgb(222, 53, 11);
                      }
                  }
              `}
`;

const StyledRemoveIcon = styled(RemoveIcon)`
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
    height: 14px;
    width: 14px;
`;

const ServiceOwnerTag = ({ text, onClick, disabled }) => {
    return (
        <ServiceOwnerTagContainer>
            <ServiceOwnerTagText>{text}</ServiceOwnerTagText>
            <RemoveIconContainer onClick={!disabled ? onClick : undefined} disabled={disabled}>
                <StyledRemoveIcon aria-hidden="true" focusable="false" />
            </RemoveIconContainer>
        </ServiceOwnerTagContainer>
    );
};

const ServiceOwnerWrapper = styled.div`
    padding: ${Spacing.md};
`;

function ServiceOwnersForm({ serviceId, owners, onSuccess }: OwnersPropsForm) {
    const [selectedOwners, setSelectedOwners] = useState<IManager[]>(owners);
    const handleSelectChange = (selectedOption: { label: string; value: IManager }) => {
        setSelectedOwners([...selectedOwners, selectedOption.value]);
    };

    const allServiceOwners = useSelector((state: RootStore) => selectServiceOwners(state));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadServiceOwners());
    }, [dispatch]);

    useEffect(() => {
        setSelectedOwners(owners);
    }, [owners]);

    const removeOwner = (qNumberToRemove: string) => {
        setSelectedOwners(selectedOwners => selectedOwners.filter(o => o.qNumber !== qNumberToRemove));
    };

    const ownerSelectOptions = useMemo(() => {
        const selectedOwnerIds = new Set(selectedOwners.map(o => o.qNumber));
        const availableOwners = allServiceOwners.filter(owner => !selectedOwnerIds.has(owner.qNumber));
        return availableOwners.map(owner => ({
            label: owner.email,
            value: owner,
        }));
    }, [allServiceOwners, selectedOwners]);

    return (
        <Formik
            initialValues={selectedOwners}
            onSubmit={(values, { setSubmitting }) => {
                const selectedOwnerIds = selectedOwners.map(o => o.qNumber);
                toastifyPromise(
                    ServiceAPI.patchService(serviceId, { owners: selectedOwnerIds }),
                    'Failed to update owner(s)',
                    'Saved owner(s)',
                )
                    .then(() => onSuccess())
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                    <ContentSection title="Service Owner(s)" padding="20px 0">
                        <ServiceOwnerListWrapper>
                            Service Owner(S):
                            {selectedOwners.map(item => (
                                <ServiceOwnerTag
                                    key={item.qNumber}
                                    text={item.email}
                                    onClick={() => removeOwner(item.qNumber)}
                                    disabled={selectedOwners.length < 2}
                                />
                            ))}
                        </ServiceOwnerListWrapper>

                        <ServiceOwnerWrapper>
                            <Form.Label>Add new service owner</Form.Label>
                            <Form.Row>
                                <Col md={5}>
                                    <Select
                                        styles={DefaultSelectStyles}
                                        onChange={handleSelectChange}
                                        value={null}
                                        options={ownerSelectOptions}
                                    />
                                </Col>
                            </Form.Row>
                            <Button
                                variant="primary"
                                style={{ marginTop: '16px' }}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Save Owners
                            </Button>
                        </ServiceOwnerWrapper>
                    </ContentSection>
                </Form>
            )}
        </Formik>
    );
}

export default ServiceOwnersForm;
