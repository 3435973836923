import React, { Component } from 'react';
import { FieldArray, Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { IService } from 'types';
import { ImageUploadForm } from './content/upload.form';
import InputListBox from 'components/inputs/inputListBox';
import ServiceFactory from 'utils/service.factory';
import ServiceArrayRender from './content/section-array-render';
import ContentSection from 'components/blocks/content-section';
import CitiesListControl from './content/cities.control';
import ServiceAPI from 'utils/api/services.api';
import PreviewButton from './content/service.preview.modal';
import { ServiceContent } from 'types';
import ServiceUtils from 'utils/service.utils';
import { toastifyPromise } from '../../../utils/toast-utils';
import { toast } from 'react-toastify';

interface ContentFormProps {
    serviceId: string;
    initialValues: ServiceContent;
    onSubmit: () => void;
}

export default class ContentForm extends Component<ContentFormProps> {
    getInitialValues() {
        // initialize sections with one element if array is empty
        const sections =
            this.props.initialValues.sections.length > 0
                ? this.props.initialValues.sections.map(ServiceUtils.toEditableSection)
                : [ServiceFactory.createEmptyBlock()];

        // initialize preconditions with two elements if count do not match
        const preconditions = this.props.initialValues.preconditions
            ? this.props.initialValues.preconditions
            : ServiceFactory.createEmptyPreconditions();

        return {
            headline: this.props.initialValues.headline || '',
            subline: this.props.initialValues.subline || '',
            headerImage: this.props.initialValues.headerImage || '',
            sections: sections || [],
            preconditions: preconditions || [],
        };
    }

    render() {
        return (
            <Formik
                initialValues={this.getInitialValues()}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    const normalizedValues = ServiceUtils.normalizeContent(values);
                    ServiceAPI.getServiceDetails(this.props.serviceId).then((serviceDetails: IService) => {
                        const emailAddress = serviceDetails.serviceEmail;
                        if (emailAddress) {
                            normalizedValues.sections = normalizedValues.sections.filter(
                                ServiceUtils.isSectionNotEmpty,
                            );
                            toastifyPromise(
                                ServiceAPI.updateContent(this.props.serviceId, normalizedValues),
                                'Failed to update content',
                                'Successfully saved',
                            )
                                .then(() => this.props.onSubmit())
                                .finally(() => setSubmitting(false));
                        } else {
                            toast.error('Please set an email Address for the service first!');
                            setSubmitting(false);
                        }
                    });
                }}
            >
                {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <h3>Edit Service Page Content</h3>
                        <ContentSection title="Service Page Header">
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Headline</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="headline"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.headline}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Subline</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="subline"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.subline}
                                        />
                                    </Form.Group>
                                </Col>

                                <Form.Control
                                    hidden={true}
                                    type="text"
                                    name={`headerImage`}
                                    value={values.headerImage}
                                    readOnly
                                />
                                <ImageUploadForm
                                    setFieldValue={setFieldValue}
                                    fieldName="headerImage"
                                    value={values.headerImage}
                                />
                            </Form.Row>
                        </ContentSection>
                        <FieldArray
                            name="sections"
                            render={arrayHelpers => (
                                <ServiceArrayRender
                                    values={values}
                                    arrayHelpers={arrayHelpers}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        />
                        <ContentSection title="Customer facing requirements">
                            <p>
                                Descriptive text explaining service requirements at the bottom of a service landing
                                page.
                            </p>
                            <Form.Row>
                                <Col>
                                    <CitiesListControl
                                        label="Markets and Cities"
                                        name="preconditions.markets"
                                        values={values.preconditions.markets}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col style={{ margin: '10px 20px 0 0' }}>
                                    <InputListBox
                                        label="Vehicle Requirements"
                                        name="preconditions.vehiclePreconditions"
                                        placeholder="Vehicle"
                                        handleChange={handleChange}
                                        stringValues={values.preconditions.vehiclePreconditions}
                                    />
                                </Col>
                                <Col style={{ marginTop: '10px' }}>
                                    <InputListBox
                                        label="Additional Requirements"
                                        name="preconditions.additionalPreconditions"
                                        placeholder="Device"
                                        handleChange={handleChange}
                                        stringValues={values.preconditions.additionalPreconditions}
                                    />
                                </Col>
                            </Form.Row>
                        </ContentSection>

                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            )}
                            Save Content
                        </Button>
                        <PreviewButton serviceContent={values} />
                    </Form>
                )}
            </Formik>
        );
    }
}
