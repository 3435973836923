import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import { loadServiceDetails } from './service-details.actions';
import { IService } from 'types';
import ServiceFactory from 'utils/service.factory';

export const defaultIsFetchingState = false;
export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
    handleAction(loadServiceDetails.next, () => true),
    handleAction([loadServiceDetails.complete, loadServiceDetails.error], () => false),
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(loadServiceDetails.complete, () => defaultErrorState),
    handleAction(loadServiceDetails.error, (_, { payload }) => payload),
]);

export const defaultCurrentServiceState: IService = ServiceFactory.createEmptyService();
export const currentServiceReducer = createReducer(defaultCurrentServiceState, handleAction => [
    handleAction(loadServiceDetails.complete, (_, { payload }) => payload),
    handleAction(loadServiceDetails.error, status => status),
]);

export type IServiceDetailsStore = ReturnType<typeof serviceDetailsReducer>;
export const serviceDetailsReducer = combineReducers({
    isFetching: isFetchingReducer,
    error: errorReducer,
    currentService: currentServiceReducer,
});
