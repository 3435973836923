import { loadUsers } from './users.actions';
import { createReducer } from 'deox';
import { combineReducers } from 'redux';
import { IUser } from 'types';

export const defaultIsLoadingState = false;
export const isLoadingReducer = createReducer(defaultIsLoadingState, handleAction => [
    handleAction(loadUsers.next, () => true),
    handleAction([loadUsers.complete, loadUsers.error], () => false),
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(loadUsers.complete, () => defaultErrorState),
    handleAction(loadUsers.error, (_, { payload }) => payload),
]);

export const defaultUsersState: IUser[] = [];
export const usersReducer = createReducer(defaultUsersState, handleAction => [
    handleAction(loadUsers.complete, (_, { payload }) => payload.users),
    handleAction(loadUsers.error, status => status),
]);

export const defaultLastUpdatedState = 0;
export const lastUpdatedReducer = createReducer(defaultLastUpdatedState, handleAction => [
    handleAction(loadUsers.complete, (_, { payload }) => payload.receivedAt),
    handleAction(loadUsers.error, status => status),
]);

export const usersManagementReducer = combineReducers({
    isLoading: isLoadingReducer,
    error: errorReducer,
    users: usersReducer,
    lastUpdated: lastUpdatedReducer,
});

export type IUsersStore = ReturnType<typeof usersManagementReducer>;
