import { IService, RootStore } from 'types';

export function selectServicesDetailsIsFetchingState(state: RootStore): boolean {
    return state.serviceDetails.isFetching;
}

export function selectServicesDetailsErrorState(state: RootStore): string {
    return state.serviceDetails.error;
}

export function selectServicesDetails(state: RootStore): IService | undefined {
    return state.serviceDetails.currentService;
}
