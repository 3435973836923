import React, { useEffect, useState } from 'react';
import { RootStore } from '../../types';
import { loadAdmins } from '../../redux/usersManagement/admins.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IManager } from '../../types/manager.types';
import UserManagementTable, { UserManagementTableColumn } from '../../components/table/user-management-table';
import { toastifyPromise } from '../../utils/toast-utils';
import ManagersApi from '../../utils/api/managers.api';

interface AdminTableProps {
    admins: IManager[] | undefined;
    error: string | null;
    loadAdmins: () => void;
}

const AdminTable: React.FC<AdminTableProps> = ({ error, loadAdmins, admins }) => {
    const paginationOptions = [25, 50, 75, 100];
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const deleteAdmins = async (selectedIds: string[]): Promise<void> => {
        if (!selectedIds || selectedIds.length === 0) {
            return;
        }

        try {
            await toastifyPromise(ManagersApi.deleteManagers(selectedIds), 'Failed to delete admins', 'Admins deleted');
            loadAdmins();
        } catch (error) {
            console.error('Error deleting admins:', error);
        }
    };

    useEffect(() => {
        setErrorMessage(error ? 'Failed to fetch admins' : null);
    }, [error]);

    useEffect(() => {
        loadAdmins();
    }, [loadAdmins]);

    const columns: UserManagementTableColumn<IManager>[] = [
        {
            title: 'Username',
            value: item => item.qNumber,
            matchesQuery: (query, item) => item.qNumber.toLowerCase().includes(query.toLowerCase()),
        },
        {
            title: 'E-mail',
            value: item => item.email,
            matchesQuery: (query, item) => item.email.toLowerCase().includes(query.toLowerCase()),
        },
        {
            title: 'Last active',
            value: item => new Date(item.lastActive).toLocaleString(),
        },
    ];

    return (
        <UserManagementTable
            columns={columns}
            paginationOptions={paginationOptions}
            rows={admins}
            error={errorMessage}
            getIdentifier={item => item.qNumber}
            onDelete={deleteAdmins}
        />
    );
};

const mapStateToProps = (state: RootStore) => ({
    error: state.adminsManagement.error,
    admins: state.adminsManagement.managers,
});

const actionCreators = { loadAdmins };
const mapDispatchToProps = dispatch => {
    return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTable);
