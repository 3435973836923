import React, { Component } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import * as Yup from 'yup';
import ServiceAPI from 'utils/api/services.api';
import { ImageUploadForm } from '../details/content/upload.form';
import Col from 'react-bootstrap/Col';
import { toastifyPromise } from '../../../utils/toast-utils';

interface CreateServiceFormProps {
    onCreated: () => void;
}

const Section = styled.section`
    background-color: #f2f2f2;
    padding: 1em;
    margin-bottom: 1em;
    margin-top: 1em;
`;

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Name should have more than 3 characters!')
        .required('Field is required'),
    serviceEmail: Yup.string()
        .email()
        .required(),
});

export default class CreateServiceForm extends Component<CreateServiceFormProps> {
    render() {
        const InitialValues = {
            name: '',
            description: '',
            imageUrl: '',
            serviceEmail: '',
        };

        return (
            <Formik
                initialValues={InitialValues}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    toastifyPromise(ServiceAPI.createService(values), 'Failed to create service', 'Service created')
                        .then(() => this.props.onCreated())
                        .finally(() => setSubmitting(false));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Section>
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        {errors.name && touched.name && errors.name}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                        />
                                        {errors.description && touched.description && errors.description}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Service Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="serviceEmail"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.serviceEmail}
                                        />
                                        {errors.serviceEmail && touched.serviceEmail && errors.serviceEmail}
                                    </Form.Group>
                                </Col>

                                <Form.Control
                                    hidden={true}
                                    type="text"
                                    name={`imageUrl`}
                                    onChange={handleChange}
                                    value={values.imageUrl}
                                />
                                <ImageUploadForm
                                    setFieldValue={setFieldValue}
                                    fieldName="imageUrl"
                                    value={values.imageUrl}
                                />
                            </Form.Row>
                        </Section>

                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            Create Service
                        </Button>
                    </Form>
                )}
            </Formik>
        );
    }
}
