import axios, { AxiosRequestConfig } from 'axios';
import Config from 'config/config';
import LocalStorageService from '../LocalStorageService';

const HEADER_ASSUMED_ROLE = 'assumed-role';

const APIClient = () => {
    const defaultOptions: AxiosRequestConfig = {
        baseURL: Config.BASE_URL,
        method: 'get',
    };

    const instance = axios.create(defaultOptions);

    instance.interceptors.request.use(
        config => {
            const token = LocalStorageService.getToken();
            if (token) {
                config.headers.Authorization = `${token}`;
            }
            const role = LocalStorageService.getAssumedRole();
            if (role) {
                config.headers[HEADER_ASSUMED_ROLE] = role;
            }
            return config;
        },
        error => {
            Promise.reject(error);
        },
    );

    // Check for unauthorized access
    instance.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response.status === 401) {
                LocalStorageService.removeSession();
                window.location.replace(Config.BASE_ROUTER_PATH);
            }
            return error;
        },
    );

    return instance;
};

export default APIClient();
