import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { loadServices } from 'redux/servicesOverview/services-overview.actions';
import { IServiceOverview, RootStore } from 'types';
import { ThunkDispatch } from 'redux-thunk';
import ServiceTile from 'components/blocks/services-tile';
import {
    selectLastServices,
    selectServicesErrorState,
    selectServicesIsFetchingState,
} from 'redux/servicesOverview/services-overview.selector';
import Button from 'components/button';
import { Spacing } from 'config/styling.constants';
import { toast } from 'react-toastify';

const GridContainer = styled.div`
    padding-bottom: 3rem;
    padding-top: ${Spacing.sm};
`;

const ServiceGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

interface DashboardGridState {
    isError: boolean;
}

interface DashboardGridProps {
    loading: boolean;
    services: IServiceOverview[];
    error: string;
    loadServices: () => void;
}
class DashboardServiceGrid extends React.Component<DashboardGridProps, DashboardGridState> {
    constructor(props) {
        super(props);
        this.state = { isError: false };
    }

    componentDidMount() {
        this.props.loadServices();
    }

    render() {
        const { loading, services, error } = this.props;

        if (error && !this.state.isError) {
            toast.error('Failed to load Services');
            this.setState({ isError: true });
        }
        return (
            <GridContainer>
                <ServiceGrid>
                    {services &&
                        services.length > 0 &&
                        services.map(service => (
                            <ServiceTile key={service.id} service={service} to={`/services/edit/${service.id}`} />
                        ))}
                    {services.length === 0 && !loading && !error && (
                        <div>
                            <span>You do not have active services. Go to Service page and activate a services.</span>
                            <Button to={'/services'}>Go to Services</Button>
                        </div>
                    )}
                </ServiceGrid>
            </GridContainer>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    loading: selectServicesIsFetchingState(state),
    error: selectServicesErrorState(state),
    services: selectLastServices(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServices: () => dispatch(loadServices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardServiceGrid);
