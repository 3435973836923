import React, { Component } from 'react';
import { Field, FieldArray, Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import ServiceAPI from 'utils/api/services.api';
import { Requirement, RequirementsPayload, RootStore, TemplateRequirement } from 'types';
import MarketsUtils from 'utils/markets.utils';
import TaggedMultiSelect from 'components/inputs/tagged-multi-select';
import ContentSection from 'components/blocks/content-section';
import { toastifyPromise } from '../../../../utils/toast-utils';
import { connect } from 'react-redux';
import { selectConfigurationRequirements } from 'redux/configuration/configuration.selector';
import TagInput from 'components/inputs/tag-input';
import Select from 'react-select';
import { Colors, Typography, Spacing } from 'config/styling.constants';
import styled from 'styled-components';
import { DeleteButton } from 'components/inputs/inputListBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { DefaultSelectStyles } from 'components/dropdown';

export interface RequirementsFormProps {
    serviceId: string;
    initialValues: RequirementsPayload;
    requirementsTemplate: TemplateRequirement[];
    onSuccess: () => void;
    windowWidth: number;
}

interface RequirementsFormState {
    serviceId: string;
    selectedRequirement: RequirementOption | null;
    isWrongInput: boolean;
    requirementsTemplate: TemplateRequirement[];
}

interface InitialValues {
    requirements: Requirement[];
}

interface RequirementOption {
    value: Requirement;
    label: string;
}

const input = {
    width: '100%',
    border: `2px solid ${Colors.darkGrey}`,
    borderRadius: '3px',
    fontFamily: `${Typography.baseFontFamily}`,
    fontSize: '14px',
    display: 'block',
    height: '50px',
    padding: '7px 8px',
    placeholder: 'Input...',
};

const RequirementsWrapper = styled.div`
    background-size: 266px 266px;
    padding: ${Spacing.md};
`;

class RequirementsForm extends Component<RequirementsFormProps, RequirementsFormState> {
    constructor(props) {
        super(props);
        this.state = {
            serviceId: '',
            selectedRequirement: null,
            isWrongInput: false,
            requirementsTemplate: [{ name: 'markets', type: 'markets' }].concat(this.props.requirementsTemplate),
        };
        this.createRequirements = this.createRequirements.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.formatRequirements = this.formatRequirements.bind(this);
        this.checkNumber = this.checkNumber.bind(this);
    }
    componentDidMount() {
        const serviceId = this.props.serviceId;
        this.setState({ serviceId: serviceId });
    }

    createRequirements(): Requirement[] {
        return this.state.requirementsTemplate
            .map(requirement => {
                const values =
                    requirement.name in this.props.initialValues ? this.props.initialValues[requirement.name] : null;
                return { name: requirement.name, type: requirement.type, options: requirement.values, values: values };
            })
            .filter(requirement => requirement.values !== null);
    }

    handleChange = selectedOption => {
        this.setState({ selectedRequirement: selectedOption });
    };

    formatRequirements(values: InitialValues) {
        let result = {};
        values.requirements.map(
            requirement =>
                (result =
                    requirement.values && requirement.values.length !== 0 && requirement.values[0] !== ''
                        ? {
                              ...result,
                              [requirement.name]: requirement.values,
                          }
                        : { ...result }),
        );
        return result;
    }

    checkNumber(requirement, state) {
        if (requirement.type === 'integer') {
            if (requirement.values !== undefined && isNaN(requirement.values)) {
                if (!state) {
                    this.setState({ isWrongInput: true });
                }
                return <p style={{ color: 'red' }}>Input is not a valid number</p>;
            } else if (requirement.values !== undefined && !isNaN(requirement.values)) {
                if (state) {
                    this.setState({ isWrongInput: false });
                }
            }
        }
    }

    render() {
        const InitialValues: InitialValues = {
            requirements: this.createRequirements() || [],
        };
        return (
            <Formik
                initialValues={InitialValues}
                onSubmit={(values, { setSubmitting }) => {
                    toastifyPromise(
                        ServiceAPI.updateRequirements(this.state.serviceId, this.formatRequirements(values)),
                        'Failed to update requirements',
                        'Saved requirements',
                    )
                        .then(() => this.props.onSuccess())
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <FieldArray
                            name="requirements"
                            render={arrayHelpers => (
                                <ContentSection title="Technical service Requirements" padding="0px">
                                    <RequirementsWrapper>
                                        <Form.Row>
                                            <Col>
                                                <div style={{ marginBottom: '20px' }}>
                                                    <Form.Row>
                                                        <Col md={9}>
                                                            <Form.Label>Enter new Requirement</Form.Label>
                                                            <Select
                                                                styles={DefaultSelectStyles}
                                                                onChange={this.handleChange}
                                                                value={this.state.selectedRequirement}
                                                                isMulti={false}
                                                                options={MarketsUtils.getRequirementOptions(
                                                                    this.state.requirementsTemplate.filter(
                                                                        requirement =>
                                                                            !values.requirements.some(
                                                                                req => req.name === requirement.name,
                                                                            ),
                                                                    ),
                                                                )}
                                                            />
                                                        </Col>
                                                        <Col
                                                            md={3}
                                                            style={{
                                                                marginTop: '28px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Button
                                                                type="button"
                                                                size="sm"
                                                                onClick={() => {
                                                                    if (this.state.selectedRequirement) {
                                                                        arrayHelpers.push(
                                                                            this.state.selectedRequirement.value,
                                                                        );
                                                                        this.setState({ selectedRequirement: null });
                                                                    }
                                                                }}
                                                            >
                                                                Add
                                                            </Button>
                                                        </Col>
                                                    </Form.Row>
                                                </div>

                                                {values.requirements.map((requirement, index) => {
                                                    let ComponentType;
                                                    let options: any[] = [];
                                                    let isMulti;
                                                    let placeholder;
                                                    switch (requirement.type) {
                                                        case 'enum':
                                                            ComponentType = TaggedMultiSelect;
                                                            options = MarketsUtils.getEnumOptions(
                                                                requirement.options || [],
                                                            );
                                                            isMulti = true;
                                                            break;
                                                        case 'string':
                                                            if (_.startCase(requirement.name) === 'Pu Step') {
                                                                ComponentType = TagInput;
                                                                placeholder =
                                                                    'Enter minimal PU Step, e.g. 0719, and hit enter';
                                                            } else if (
                                                                _.startCase(requirement.name) === 'Model Range'
                                                            ) {
                                                                ComponentType = TagInput;
                                                                placeholder =
                                                                    'Enter Model Code(s), e.g. G01, and hit enter';
                                                            } else if (_.startCase(requirement.name) === 'Sa List') {
                                                                ComponentType = TagInput;
                                                                placeholder =
                                                                    'Enter Option Code(s), e.g. 0134, and hit enter';
                                                            } else {
                                                                ComponentType = TagInput;
                                                                placeholder = 'Enter a value and press enter... ';
                                                            }
                                                            break;
                                                        case 'integer':
                                                            if (
                                                                _.startCase(requirement.name) === 'Year Of Construction'
                                                            ) {
                                                                ComponentType = TagInput;
                                                                placeholder =
                                                                    'Enter minimal year of construction, e.g. 2023';
                                                            } else {
                                                                ComponentType = 'input';
                                                                placeholder = 'Input...';
                                                            }
                                                            break;
                                                        case 'boolean':
                                                            ComponentType = TaggedMultiSelect;
                                                            options = [
                                                                { value: true, label: 'TRUE' },
                                                                { value: false, label: 'FALSE' },
                                                            ];
                                                            break;
                                                        case 'markets':
                                                            ComponentType = TaggedMultiSelect;
                                                            options = MarketsUtils.getMarketOptions();
                                                            isMulti = true;
                                                            break;
                                                    }
                                                    return (
                                                        <div key={requirement.name} style={{ marginBottom: '20px' }}>
                                                            <Form.Label>{_.startCase(requirement.name)}</Form.Label>
                                                            {this.checkNumber(requirement, this.state.isWrongInput)}
                                                            <Form.Row style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Col md={11}>
                                                                    {isMulti && (
                                                                        <Field
                                                                            name={`requirements[${index}].values`}
                                                                            component={ComponentType}
                                                                            isMulti={isMulti}
                                                                            options={options}
                                                                        />
                                                                    )}
                                                                    {!isMulti && (
                                                                        <Field
                                                                            style={input}
                                                                            name={`requirements[${index}].values`}
                                                                            component={ComponentType}
                                                                            options={options}
                                                                            placeholder={placeholder}
                                                                        />
                                                                    )}
                                                                </Col>
                                                                <Col md={1}>
                                                                    <DeleteButton
                                                                        type="button"
                                                                        onClick={() => {
                                                                            arrayHelpers.remove(index);
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon="times-circle"
                                                                            size="lg"
                                                                        />
                                                                    </DeleteButton>
                                                                </Col>
                                                            </Form.Row>
                                                        </div>
                                                    );
                                                })}
                                            </Col>
                                            {this.props.windowWidth > 770 && <Col />}
                                        </Form.Row>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={isSubmitting || this.state.isWrongInput}
                                        >
                                            Save Requirements
                                        </Button>
                                    </RequirementsWrapper>
                                </ContentSection>
                            )}
                        />
                    </Form>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    requirementsTemplate: selectConfigurationRequirements(state),
});

export default connect(mapStateToProps)(RequirementsForm);
