import React, { useState } from 'react';
import styled from 'styled-components';
import ServiceAPI from 'utils/api/services.api';
import Dropzone from './dropzone';
import { Accept } from 'react-dropzone';

type UploadState = 'LOADING' | 'ERROR' | 'UPLOAD_COMPLETE';

const FromContainer = styled.div`
    padding: 0 10px;
    margin: 0 auto;

    width: 100%;
    @media (min-width: 640px) {
        width: inherit;
        min-width: 400px;
    }
`;

type UploadFormProps = {
    value?: string;
    fieldName?: string;
    setFieldValue?: Function;
};

type InternalUploadFormProps = UploadFormProps & {
    uploadHandler: (files: File[]) => Promise<any>;
    accept: Accept;
    maxSizeMb?: number;
    maxFiles?: number;
    showPreview?: boolean;
};

function UploadForm(props: InternalUploadFormProps) {
    const [state, setState] = useState<UploadState>();

    const handleUpload = async (files: File[]) => {
        if (files.length === 0) {
            return;
        }
        setState('LOADING');

        try {
            const result = await props.uploadHandler(files);
            setState('UPLOAD_COMPLETE');
            props.setFieldValue && props.setFieldValue(props.fieldName, result);
        } catch (_) {
            setState('ERROR');
        }
    };

    return (
        <FromContainer>
            <Dropzone
                handleUpload={handleUpload}
                value={props.value}
                loading={state === 'LOADING'}
                uploadComplete={state === 'UPLOAD_COMPLETE'}
                error={state === 'ERROR'}
                accept={props.accept}
                maxSizeMb={props.maxSizeMb}
                maxFiles={props.maxFiles}
                showPreview={props.showPreview || false}
            />
        </FromContainer>
    );
}

export const ImageUploadForm = (props: UploadFormProps) =>
    UploadForm({
        ...props,
        accept: { 'image/*': [] },
        maxSizeMb: 1,
        maxFiles: 1,
        showPreview: true,
        uploadHandler: async files => {
            const result = await ServiceAPI.uploadImage(files[0]);
            if (result.status !== 200) {
                throw new Error('Upload failed');
            }
            return result.data;
        },
    });

export const ArchiveUploadForm = (props: UploadFormProps) =>
    UploadForm({
        ...props,
        accept: { 'application/zip': [] },
        maxSizeMb: 50,
        maxFiles: 1,
        uploadHandler: async files => {
            const result = await ServiceAPI.uploadCustomContent(files[0]);
            if (result.status !== 200) {
                throw new Error('Upload failed');
            }
            return result.data;
        },
    });
