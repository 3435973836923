import React from 'react';
import { Switch } from 'react-router-dom';
import RestrictedRoute, { AuthenticatedRoute } from 'components/RestrictedRoute';
// **** Pages ****
import Dashboard from 'pages/dashboard/dashboard.page';
import Mailbox from 'pages/mailbox/mailbox.page';
import Users from 'pages/users/page';
import { LoginPage } from 'pages/login/page';
import OauthRedirect from 'pages/oauthRedirect/oauth-redirect';
import CreateService from 'pages/services/create/create';
import ServicesPage from 'pages/services/overview/page';
import ServiceDetails from 'pages/services/details/details.page';
import Marketing from 'pages/marketing/marketing.page';
import CreateMarketing from 'pages/marketing/marketing.create';
import UpdateMarketing from 'pages/marketing/marketing.update';
import AuthoritySelector from './pages/login/select-role';

const Routes: React.FC = () => {
    return (
        <Switch>
            <RestrictedRoute allowedState={'LOGGED_OUT'} exact path="/" component={LoginPage} />
            <RestrictedRoute allowedState={'LOGGED_OUT'} path="/login" component={LoginPage} />
            <RestrictedRoute allowedState={'LOGGED_OUT'} path="/wen/redirect" component={OauthRedirect} />
            <RestrictedRoute allowedState={'LOGGED_OUT'} path="/oauth/redirect.html" component={OauthRedirect} />
            <RestrictedRoute
                allowedState={'REQUIRES_ROLE_SELECTION'}
                path="/select-authorities"
                component={AuthoritySelector}
            />
            <AuthenticatedRoute path="/mailbox" component={Mailbox} />
            <AuthenticatedRoute path="/mailbox/services" component={Mailbox} />
            <AuthenticatedRoute path="/mailbox/broadcasts" component={Mailbox} />
            <AuthenticatedRoute path="/dashboard" component={Dashboard} />
            <AuthenticatedRoute path="/marketing/update/:id" component={UpdateMarketing} />
            <AuthenticatedRoute path="/marketing/create" component={CreateMarketing} />
            <AuthenticatedRoute path="/marketing" component={Marketing} />
            <AuthenticatedRoute path="/services/edit/:id" component={ServiceDetails} />
            <AuthenticatedRoute path="/services/create" component={CreateService} />
            <AuthenticatedRoute path="/services" component={ServicesPage} />
            <AuthenticatedRoute path="/users" component={Users} />
        </Switch>
    );
};
export default Routes;
