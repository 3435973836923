import React from 'react';
import PageLayout from 'components/page.layout';
import CreateServiceForm from './create.form';

const CreateService = props => {
    const onCreated = () => props.history.push('/services');

    return (
        <PageLayout paddingTop="2em">
            <h1>Create New Service</h1>
            <CreateServiceForm onCreated={onCreated} />
        </PageLayout>
    );
};
export default CreateService;
