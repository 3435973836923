import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import PageLayout from 'components/page.layout';
import ServiceAPI from 'utils/api/services.api';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ContentForm from './content.form';
import PrettyPrintJson from 'components/PrettyPrintJson';
import RequirementsForm from './general/requirements.form';
import SurveyForm from './general/survey.form';
import EditServiceTileForm from './general/edit-service-tile.form';
import { Badge, FlexContainer } from 'components/styled-components';
import { IService, RootStore, UserCSV, UserFromAPI } from 'types';
import { RouteComponentProps } from 'react-router';
import BroadcastMessageForm from './general/message.form';
import EmailForm from './general/email.form';
import AnalyticsGrid from './analytics/analytics';
import ServiceFactory from 'utils/service.factory';
import { loadServiceDetails } from 'redux/serviceDetails/service-details.actions';
import {
    selectServicesDetails,
    selectServicesDetailsErrorState,
    selectServicesDetailsIsFetchingState,
} from 'redux/serviceDetails/service-details.selector';
import { Colors, Spacing } from 'config/styling.constants';
import { selectSessionIsAdmin } from 'redux/session/session.selector';
import ContentSection from 'components/blocks/content-section';
import TermsAndPrivacyComponent from './term-and-privacy';
import config from 'config/config';
import {
    ServiceStatusActiveButtons,
    ServiceStatusHiddenButtons,
    ServiceStatusInternalButtons,
} from 'pages/services/details/general/service-status-buttons';
import DataPrivacyComponent from './data-privacy';
import { toastifyPromise } from '../../../utils/toast-utils';
import InstructionForm from './instruction.form';
import { useHistory, useParams } from 'react-router-dom';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { CSVLink } from 'react-csv';
import ServiceUtils from 'utils/service.utils';
import LimitSubscriptionForm from './general/limit-subscription-member';
import ServiceOwnersForm from './general/service-owners.form';

type TParams = { id: string };

interface ServiceDetailsProps extends RouteComponentProps<TParams> {
    selectedService?: IService;
    isLoading: boolean;
    isAdmin: boolean;
    serviceDetailsFetchError?: string;
    loadServiceDetails: (serviceId: string) => void;
}

const ServiceDetails: React.FC<ServiceDetailsProps> = ({
    loadServiceDetails,
    isLoading,
    isAdmin,
    selectedService,
    serviceDetailsFetchError,
}) => {
    const history = useHistory();
    const params: { id?: string } = useParams();
    const [serviceId] = React.useState(params.id || '');
    const [downloadIcon, setDownloadIcon] = useState(false);
    const [usersCSV, setUsersCSV] = useState<UserCSV[]>([]);

    const loadServiceDetailsAction = useCallback(() => {
        loadServiceDetails(serviceId);
    }, [loadServiceDetails, serviceId]);

    const onDelete = (id: string) =>
        toastifyPromise(ServiceAPI.deleteService(id), 'Failed to delete service', 'Service deleted').then(() => {
            history.push(`/services`);
        });

    const [windowWidth, setWidth] = React.useState(window.innerWidth);

    const updateWidth = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => loadServiceDetailsAction(), [loadServiceDetailsAction]);

    // Handle redirection on error
    useEffect(() => {
        if (serviceDetailsFetchError) {
            history.push(`/dashboard`);
        }
    }, [serviceDetailsFetchError, history]);

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const response = await ServiceAPI.getServiceSubscribers(serviceId);
                const users = response.data.map((user: UserFromAPI) => {
                    return {
                        DATE_UTC: user.subscribedAt,
                        VIN: user.vin,
                        EMAIL: user.users[0],
                        SERVICE: serviceId,
                        ID_VERSION: user.hmiVersion,
                        HEAD_UNIT: user.headUnit,
                        PU_STEP: user.puStep,
                        SERVICE_PACK: user.servicePack,
                        DRIVE_TRAIN: user.driveTrain,
                        MODEL: user.model,
                        MARKET: user.homeMarket[0],
                        BRAND: user.brand,
                        GCID: user.gcid,
                    };
                });
                setUsersCSV(users);
            } catch (err) {
                console.log(err);
            }
        })();
    }, [serviceId]);

    const service: IService = selectedService || ServiceFactory.createEmptyService();
    const title = `${service.name}`;

    const activateDownloadIcon = () => {
        setDownloadIcon(true);
    };
    const deactivateDownloadIcon = () => {
        setDownloadIcon(false);
    };

    const headers = [
        'DATE_UTC',
        'VIN',
        'EMAIL',
        'SERVICE',
        'ID_VERSION',
        'HEAD_UNIT',
        'PU_STEP',
        'SERVICE_PACK',
        'DRIVE_TRAIN',
        'MODEL',
        'MARKET',
        'BRAND',
        'GCID',
    ].map((item: string) => {
        return { label: item, key: item };
    });

    return (
        <PageLayout paddingTop="2em">
            <FlexContainer>
                <h1>{title}</h1>
                <div style={{ marginTop: Spacing.sm, marginBottom: Spacing.sm }}>
                    <Badge>ID: {service.id}</Badge>
                    <Badge>{ServiceUtils.hiddenTitle(service)}</Badge>
                    <Badge>{ServiceUtils.activeTitle(service)}</Badge>

                    <CSVLink data={usersCSV} headers={headers} filename={`${title}_users_data.csv`} separator={';'}>
                        <Badge
                            onMouseEnter={() => activateDownloadIcon()}
                            onMouseLeave={() => deactivateDownloadIcon()}
                            style={{ cursor: 'pointer', backgroundColor: downloadIcon ? Colors.petrol : Colors.cyan }}
                        >
                            {service.subscribedUsersCount}{' '}
                            <FontAwesomeIcon
                                icon={downloadIcon ? (faDownload as Icon) : 'user-friends'}
                                style={{ width: Spacing.sm, marginLeft: Spacing.xxs }}
                            />
                        </Badge>
                    </CSVLink>
                </div>
            </FlexContainer>

            <Tabs defaultActiveKey="general" id="serviceDetailsTabs">
                <Tab eventKey="general" title="General Settings">
                    <h3>General Service Settings</h3>
                    <EditServiceTileForm
                        onChanged={() => loadServiceDetailsAction()}
                        serviceId={service.id}
                        name={title}
                        description={service.description}
                        imageUrl={service.imageUrl}
                        windowWidth={windowWidth}
                    />
                    <RequirementsForm
                        serviceId={serviceId}
                        key={service.id}
                        initialValues={service.requirements}
                        onSuccess={() => loadServiceDetailsAction()}
                        windowWidth={windowWidth}
                    />
                    <ServiceOwnersForm
                        owners={service.owners}
                        serviceId={serviceId}
                        onSuccess={() => loadServiceDetailsAction()}
                    />
                    <SurveyForm
                        serviceId={serviceId}
                        key={`survey.${service.id}`}
                        onSuccess={() => loadServiceDetailsAction()}
                        surveyQuestions={service.surveyQuestions}
                    />

                    <EmailForm serviceId={serviceId} />

                    <LimitSubscriptionForm
                        serviceId={serviceId}
                        maxSubscriptionCount={service.maxSubscriptionCount}
                        onSuccess={() => loadServiceDetailsAction()}
                    />

                    <BroadcastMessageForm serviceId={serviceId} />

                    <ContentSection title="Service Status">
                        <ServiceStatusActiveButtons serviceId={serviceId} status={service.active} />
                    </ContentSection>

                    <ContentSection title="Visibility Status">
                        <ServiceStatusHiddenButtons serviceId={serviceId} status={service.hidden} />
                    </ContentSection>

                    <ContentSection title="Audience">
                        <ServiceStatusInternalButtons serviceId={serviceId} status={service.isInternal} />
                    </ContentSection>

                    <Button variant="danger" onClick={() => onDelete(serviceId)}>
                        <span>
                            <FontAwesomeIcon icon="trash-alt" /> Delete
                        </span>
                    </Button>
                </Tab>

                <Tab eventKey="content" title="Content" mountOnEnter>
                    <ContentForm
                        key={service.id}
                        serviceId={serviceId}
                        initialValues={service.content}
                        onSubmit={loadServiceDetailsAction}
                    />
                </Tab>
                <Tab eventKey="instruction" title="Instructions" mountOnEnter>
                    <InstructionForm
                        key={service.id}
                        serviceId={serviceId}
                        initialValues={service.instruction}
                        onSubmit={loadServiceDetailsAction}
                    />
                </Tab>

                <Tab eventKey="analytics" title="Analytics" mountOnEnter>
                    <AnalyticsGrid serviceId={serviceId} />
                </Tab>

                <Tab eventKey="termsAndPrivacy" title="Terms &amp; Privacy" mountOnEnter>
                    <TermsAndPrivacyComponent serviceId={serviceId} />
                </Tab>

                {config.IS_DEV && (
                    <Tab eventKey="dataPrivacy" title="Data Privacy" mountOnEnter>
                        <DataPrivacyComponent serviceId={serviceId} />
                    </Tab>
                )}

                {config.IS_DEV && (
                    <Tab eventKey="json" title="JSON">
                        <h3>Service JSON</h3>
                        <PrettyPrintJson data={selectedService || {}}></PrettyPrintJson>
                    </Tab>
                )}
            </Tabs>
        </PageLayout>
    );
};
const mapStateToProps = (state: RootStore) => ({
    selectedService: selectServicesDetails(state),
    isLoading: selectServicesDetailsIsFetchingState(state),
    isAdmin: selectSessionIsAdmin(state),
    serviceDetailsFetchError: selectServicesDetailsErrorState(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServiceDetails: (serviceId: string) => dispatch(loadServiceDetails(serviceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
