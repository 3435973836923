import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchConfiguration } from 'redux/configuration/configuration.actions';

export interface AppStartProps {
    fetchConfiguration: () => void;
}

export class AppStartComponent extends React.Component<AppStartProps> {
    componentDidMount() {
        this.props.fetchConfiguration();
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    fetchConfiguration: () => dispatch(fetchConfiguration()),
});

export default connect(null, mapDispatchToProps)(AppStartComponent);
