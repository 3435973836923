import React from 'react';
import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';

const Container = styled.div`
    padding-bottom: 20px;
`;

const defaultOptions = {
    labels: [],
    responsive: [
        {
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: 'bottom',
                },
            },
        },
    ],
    theme: {
        monochrome: {
            enabled: true,
            color: '#04829B',
            shadeTo: 'light',
            shadeIntensity: 0.65,
        },
    },
    legend: {
        position: 'bottom',
    },
    dataLabels: {
        enabled: false,
    },
};

interface ChartEntry {
    name: string;
    value: number;
}

export interface PieChartProps {
    items: ChartEntry[];
    showLegend: boolean;
}

interface PieChartState {
    labels: [];
    series: number[];
}

class PieChart extends React.Component<PieChartProps, PieChartState> {
    constructor(props) {
        super(props);
        this.state = {
            labels: props.items.map(item => item.name),
            series: props.items.map(item => item.value),
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                series: this.props.items.map(item => item.value),
            });
        }
    }

    render() {
        const options = {
            ...defaultOptions,
            legend: this.props.showLegend ? defaultOptions.legend : { show: false },
            labels: this.state.labels,
        };
        return (
            <Container id="chart">
                <ReactApexChart options={options} series={this.state.series} type="pie" width="220" />
            </Container>
        );
    }
}

export default PieChart;
