import {
    EditableServiceSection,
    IService,
    IServiceOverview,
    ServiceContent,
    ServiceInstruction,
    ServiceSection,
} from 'types';
import { IOwnedService } from 'types/manager.types';

export function normalizeYouTubeLink(url: string) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url && url.match(regExp);
    const videoIdLength = 11;

    return match && match[2].length === videoIdLength ? 'https://www.youtube.com/embed/' + match[2] : url;
}

class ServiceUtils {
    static normalizeContent = <T extends ServiceContent | ServiceInstruction>(value: T): T => {
        const normalizedSections = value.sections.map((block: EditableServiceSection) => {
            const normalizedContentType =
                block.contentType === 'VIDEO' && (block.videoUrl === undefined || block.videoUrl.length === 0)
                    ? 'IMAGE'
                    : block.contentType;

            let section: ServiceSection = ({
                id: block.id,
                contentType: normalizedContentType,
            } as Partial<ServiceSection>) as ServiceSection;
            if (normalizedContentType === 'CUSTOM') {
                const customContent = block.customContent;
                const url =
                    customContent && customContent.uploadResult && customContent.entrypoint
                        ? customContent.uploadResult.baseUrl + customContent.entrypoint
                        : customContent && customContent.url;
                section = {
                    ...section,
                    contentUrl: url || '',
                    title: '',
                    content: '',
                };
            } else {
                section = {
                    ...section,
                    title: block.title,
                    content: block.content,
                    contentUrl:
                        block.contentType === 'VIDEO'
                            ? normalizeYouTubeLink(block.videoUrl || '')
                            : block.imageUrl || '',
                };
            }
            section.title = section.title && section.title.trim();
            section.content = section.content && section.content.trim();
            section.contentUrl = section.contentUrl.trim();
            return section;
        });
        return { ...value, sections: normalizedSections };
    };

    static isSectionNotEmpty(section: ServiceSection) {
        return (
            section.content !== '' ||
            section.title !== '' ||
            (section.contentType === 'CUSTOM' && section.contentUrl !== '')
        );
    }

    static toEditableSection(section: ServiceSection): EditableServiceSection {
        return {
            imageUrl: section.contentType === 'IMAGE' ? section.contentUrl : undefined,
            videoUrl: section.contentType === 'VIDEO' ? section.contentUrl : undefined,
            customContent: {
                url: section.contentType === 'CUSTOM' ? section.contentUrl : undefined,
            },
            ...section,
        };
    }

    static activeTitle = (service: IService | IServiceOverview) => (service.active ? 'ACTIVE' : 'INACTIVE');
    static hiddenTitle = (service: IService | IServiceOverview) => (service.hidden ? 'HIDDEN' : 'VISIBLE');
    static internalTitle = (service: IService | IServiceOverview) => (service.isInternal ? 'INTERNAL' : 'PUBLIC');

    static deepLinkForService(service: IService | IOwnedService): string {
        return `/services/edit/${service.id}`;
    }
}

export default ServiceUtils;
