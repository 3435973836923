import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';
import { IManager, Manager } from 'types/manager.types';

export class ManagersApi {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }
    getManagerForToken(token: string) {
        return this.client.get<Manager>('/auth/manager', {
            headers: {
                Authorization: token,
            },
        });
    }

    async getAdmins() {
        const response = await this.client.get<IManager[]>(`/managers?type=admin`);
        return response.data;
    }

    async getServiceOwners() {
        const response = await this.client.get<IManager[]>(`/managers?type=service_owner`);
        return response.data;
    }

    deleteManagers(ids: string[]) {
        const params = { ids: ids.join(',') };
        return this.client.delete('/managers', { params });
    }
}

export default new ManagersApi(Client);
