import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import InputBox from '../inputs/inputBox';
import { Colors } from '../../config/styling.constants';
import styled from 'styled-components';
import { ConfirmAlert } from 'utils/ConfirmAlert';

export interface UserManagementTableColumn<Item> {
    title: string;
    value: (item: Item) => string | JSX.Element;
    matchesQuery?: (query: string, item: Item) => boolean;
}

export interface UserManagementTableProps<Item, Identifier> {
    columns: UserManagementTableColumn<Item>[];
    paginationOptions: number[];
    rows: Item[] | undefined;
    error: string | null;
    getIdentifier: (item: Item) => Identifier;
    onDelete: (selectedIds: Identifier[]) => Promise<void>;
}

const Cell = styled.td`
    padding: 0.3rem;
`;

function UserManagementTable<Item, Identifier>({
    columns,
    paginationOptions,
    rows,
    error,
    getIdentifier,
    onDelete,
}: UserManagementTableProps<Item, Identifier>) {
    const [selectedIds, setSelectedIds] = useState<Identifier[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(paginationOptions[0]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredRows = (rows || []).filter(item =>
        columns.some(column => column.matchesQuery && column.matchesQuery(debouncedSearchTerm, item)),
    );

    const totalPages = Math.ceil(filteredRows.length / itemsPerPage);
    const currentRows = filteredRows.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage: number) => {
        if (newPage < currentPage && newPage > 0) {
            setCurrentPage(newPage);
        }
    };

    const handleCheckboxChange = (id: Identifier) => {
        setSelectedIds(prevSelectedIds => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter(selectedId => selectedId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const allIds = currentRows.map(getIdentifier);
            setSelectedIds(allIds);
        } else {
            setSelectedIds([]);
        }
    };

    const handleDeleteConfirm = async () => {
        await onDelete(selectedIds);
        setSelectedIds([]);
    };

    const handleDeleteSelect = () => {
        setShowDeleteModal(true);
    };

    if (error) {
        toast.error(error);
    }

    return (
        <div>
            <ConfirmAlert
                show={showDeleteModal}
                title={'Delete users'}
                onDone={function(confirmed: boolean): void {
                    setShowDeleteModal(false);
                    if (confirmed) {
                        handleDeleteConfirm();
                    }
                }}
            >
                {`Are you sure you want to delete ${selectedIds.length} users?`}
            </ConfirmAlert>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}
            >
                <div style={{ width: '50%' }}>
                    <InputBox label={''} placeholder={'Search'} handleChange={handleSearchChange} value={searchTerm} />
                </div>
                <Button variant="primary" disabled={selectedIds.length === 0} onClick={handleDeleteSelect}>
                    Delete
                </Button>
            </div>

            <Table>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center' }}>
                            <input
                                type="checkbox"
                                checked={
                                    selectedIds.length > 0 &&
                                    selectedIds.length === (currentRows ? currentRows.length : 0)
                                }
                                onChange={handleSelectAllChange}
                            />
                        </th>
                        {columns.map(({ title }) => (
                            <th key={title}>{title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentRows && (
                        <>
                            {currentRows.map((item, index) => (
                                <tr key={index}>
                                    <Cell style={{ textAlign: 'center' }}>
                                        <input
                                            type="checkbox"
                                            checked={selectedIds.includes(getIdentifier(item))}
                                            onChange={() => handleCheckboxChange(getIdentifier(item))}
                                        />
                                    </Cell>
                                    {columns.map(column => (
                                        <Cell key={column.title}>{column.value(item)}</Cell>
                                    ))}
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </Table>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}
            >
                <span style={{ color: Colors.mediumGrey, fontStyle: 'italic' }}>
                    {filteredRows.length === 0
                        ? 'No entries'
                        : `Showing ${(currentPage - 1) * itemsPerPage + 1} to ${Math.min(
                              currentPage * itemsPerPage,
                              filteredRows.length,
                          )} of ${filteredRows.length} entries`}
                </span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ margin: '0 10px' }}>
                        <span style={{ color: Colors.mediumGrey, fontStyle: 'italic' }}>Items per page: </span>
                        <select value={itemsPerPage} onChange={handleItemsPerPageChange} style={{ marginLeft: '5px' }}>
                            {paginationOptions.map(paginationOption => (
                                <option key={paginationOption} value={paginationOption}>
                                    {paginationOption}
                                </option>
                            ))}
                        </select>
                    </label>
                    <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        style={{
                            padding: '4px 4px',
                            fontSize: '12px',
                            marginRight: '0',
                        }}
                    >
                        &laquo;
                    </Button>
                    <span style={{ margin: '0 10px' }}>
                        {currentPage} of {totalPages || 1}
                    </span>
                    <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage >= totalPages}
                        style={{
                            padding: '4px 4px',
                            fontSize: '12px',
                        }}
                    >
                        &raquo;
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default UserManagementTable;
