import usersAPI from 'utils/api/users.api';
import { createActionCreator } from 'deox';
import { RootStore, LoadUsersPayload } from 'types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

function _loadUsersThunk() {
    return async (dispatch: ThunkDispatch<RootStore, {}, AnyAction>) => {
        dispatch(loadUsers.next());

        try {
            const users = await usersAPI.getUsers();
            dispatch(loadUsers.complete({ receivedAt: Date.now(), users: users }));
        } catch (error) {
            dispatch(loadUsers.error(error));
        }
    };
}

export const loadUsers = Object.assign(_loadUsersThunk, {
    next: createActionCreator('LOAD_USERS_NEXT'),
    complete: createActionCreator('LOAD_USERS_COMPLETE', resolve => (users: LoadUsersPayload) => resolve(users)),
    error: createActionCreator('LOAD_USERS_ERROR', resolve => (error: string) => resolve(error)),
});
