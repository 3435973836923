import React from 'react';
import styled from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';

interface TileContainerProps {
    selected?: boolean;
    isChild?: boolean;
}

const TileContainer = styled.div<TileContainerProps>`
    background-color: ${Colors.white};
    padding: ${Spacing.sm};
    ${props => props.selected && `border-left: solid 4px ${Colors.cyan};`}
    ${props =>
        !props.selected &&
        !props.isChild &&
        `border-left: solid 4px ${Colors.darkGrey};`}
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: ${Spacing.xs};
    ${props => props.isChild && `margin-left: ${Spacing.md};`}
    ${props => props.isChild && `width: 80%;`}
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;

const TextItem = styled.div`
    color: ${Colors.black};
    font-size: ${Typography.baseFontSize};
    margin: auto 0;
    flex: 1;
`;
const Name = styled(TextItem)`
    text-align: left;
`;

export interface SurveyTileProps {
    vin?: string;
    users?: string[];
    isChild: boolean;
    selected?: boolean;
    question?: string;
    response?: string;
    open?: boolean;
    onClick?: () => void;
}

const Line = styled.div`
    border-left: 2px solid ${Colors.cyan};
    height: auto;
    margin-right: 20px;
`;

const SurveyTile: React.FC<SurveyTileProps> = props => {
    return (
        <div>
            {!props.isChild && (
                <TileContainer onClick={props.onClick} selected={props.selected}>
                    <Name>VIN: {props.vin}</Name>
                    {props.users && <Name> ( {props.users[0]} )</Name>}
                </TileContainer>
            )}
            {props.isChild && props.open && (
                <TileContainer isChild={true}>
                    <Name>{props.question}</Name>
                    <Line />
                    <Name>{props.response}</Name>
                </TileContainer>
            )}
        </div>
    );
};
export default SurveyTile;
