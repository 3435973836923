import React, { Component } from 'react';
import ServiceAPI from 'utils/api/services.api';
import styled from 'styled-components';
import CountTile from './count-tile';
import PieTile from './pie-tile';
import { ServiceAnalytics, ServiceSurveyElement } from 'types';
import { toastifyPromise } from '../../../../utils/toast-utils';
import SurveyTile from './surveyTile';

interface AnalyticsGridProps {
    serviceId: string;
}

const AnalyticsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const Label = styled.label`
    font-family: Arial, sans-serif;
    font-size: 32px;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
    margin: 60px 0 20px 0;
`;

interface AnalyticsGridState {
    analyticsData: ServiceAnalytics;
    surveyElements: ServiceSurveyElement[];
}

class AnalyticsGridComponent extends Component<AnalyticsGridProps, AnalyticsGridState> {
    constructor(props: AnalyticsGridProps) {
        super(props);
        this.state = {
            analyticsData: {
                serviceId: '',
                subscribedUsersCount: 0,
                numberFeedbackMessages: 0,
                markets: [],
                headUnits: [],
                servicePacks: [],
                driveTrains: [],
                hubs: [],
                modelRanges: [],
                series: [],
            },
            surveyElements: [],
        };
    }

    componentDidMount() {
        toastifyPromise(ServiceAPI.getAnalytics(this.props.serviceId), 'Failed to fetch analytics').then((res: any) => {
            this.setState({ analyticsData: { ...res.data } });
        });

        toastifyPromise(ServiceAPI.getServiceSurvey(this.props.serviceId), 'Failed to fetch Survey Information').then(
            res => {
                this.setState({ surveyElements: res.data });
            },
        );
    }

    onSurveyClick(index: number) {
        const newSurvey = this.state.surveyElements;
        newSurvey[index].selected = !newSurvey[index].selected;
        this.setState({ surveyElements: newSurvey });
    }

    render() {
        return (
            <div>
                <AnalyticsGrid>
                    <CountTile
                        name="USER COUNT"
                        count={this.state.analyticsData.subscribedUsersCount}
                        icon={'user-friends'}
                    />
                    <CountTile name="FEEDBACK MESSAGES" count={this.state.analyticsData.numberFeedbackMessages} />
                    <PieTile name="HEAD UNITS" items={this.state.analyticsData.headUnits} />
                    <PieTile name="SERVICE PACKS" items={this.state.analyticsData.servicePacks} />
                    <PieTile name="DRIVE TRAINS" items={this.state.analyticsData.driveTrains} />
                    <PieTile name="HUBS" items={this.state.analyticsData.hubs} />
                    <PieTile name="MODEL RANGES" items={this.state.analyticsData.modelRanges} />
                    <PieTile name="SERIES" items={this.state.analyticsData.series} />
                    <PieTile name="MARKETS" items={this.state.analyticsData.markets} />
                </AnalyticsGrid>
                <Label>Survey Information</Label>
                {this.state.surveyElements.length === 0 && (
                    <div style={{ textAlign: 'center' }}>
                        <span>Survey information not available. Add survey items or wait until a user subscribes.</span>
                    </div>
                )}
                {this.state.surveyElements &&
                    this.state.surveyElements.map((elem, index) => (
                        <div key={elem.vin}>
                            <SurveyTile
                                key={elem.vin}
                                vin={elem.vin}
                                users={elem.users}
                                isChild={false}
                                selected={elem.selected}
                                onClick={() => this.onSurveyClick(index)}
                            />
                            {elem.surveyResponses &&
                                elem.surveyResponses.map(pair => (
                                    <SurveyTile
                                        key={pair.question}
                                        question={pair.question}
                                        response={pair.answer}
                                        isChild={true}
                                        open={elem.selected}
                                    />
                                ))}
                        </div>
                    ))}
            </div>
        );
    }
}
export default AnalyticsGridComponent;
