import { RootStore } from 'types';
import { ROLE_ADMIN } from 'utils/session.utils';

export type AuthenticationStateEnum = 'LOGGED_OUT' | 'LOGGED_IN' | 'REQUIRES_ROLE_SELECTION';

export function selectSessionUsername(state: RootStore): string {
    return state.session.username;
}

export function selectSessionRole(state: RootStore): string {
    return state.session.role;
}

export function selectSessionIsAdmin(state: RootStore): boolean {
    return state.session.role === ROLE_ADMIN;
}

export function selectSessionGetAuthenticationState(state: RootStore): AuthenticationStateEnum {
    if (state.session.token === '') {
        return 'LOGGED_OUT';
    } else {
        return state.session.role === '' ? 'REQUIRES_ROLE_SELECTION' : 'LOGGED_IN';
    }
}
