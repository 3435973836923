import { createReducer } from 'deox';
import { combineReducers } from 'redux';
import { fetchConfiguration } from './configuration.actions';
import { IConfigMarket, TemplateRequirement } from 'types';

export const defaultConfigurationState: IConfigMarket[] = [];
export const configReducer = createReducer(defaultConfigurationState, handleAction => [
    handleAction(fetchConfiguration.complete, (_, { payload }) => payload.markets),
    handleAction(fetchConfiguration.error, state => state),
]);

export const defaultConfigurationRequirementsState: TemplateRequirement[] = [];
export const configRequirementsReducer = createReducer(defaultConfigurationRequirementsState, handleAction => [
    handleAction(fetchConfiguration.complete, (_, { payload }) => payload.requirements),
    handleAction(fetchConfiguration.error, state => state),
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(fetchConfiguration.complete, () => defaultErrorState),
    handleAction(fetchConfiguration.error, (_, { payload }) => payload),
]);

export const defaultIsFetchingState = false;
export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
    handleAction(fetchConfiguration.next, () => true),
    handleAction([fetchConfiguration.complete, fetchConfiguration.error], () => false),
]);

export type ConfigurationState = ReturnType<typeof configurationReducer>;
export const configurationReducer = combineReducers({
    markets: configReducer,
    requirements: configRequirementsReducer,
    error: errorReducer,
    isFetching: isFetchingReducer,
});
