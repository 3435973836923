import React, { Component } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ServiceAPI from 'utils/api/services.api';
import { ServiceSurveyQuestion } from 'types';
import ContentSection from 'components/blocks/content-section';
import { toastifyPromise } from '../../../../utils/toast-utils';
import InputListBox from 'components/inputs/inputListBox';
import Col from 'react-bootstrap/Col';
import { Spacing } from 'config/styling.constants';
import styled from 'styled-components';

export interface SurveyFormProps {
    serviceId: string;
    surveyQuestions: ServiceSurveyQuestion[];
    onSuccess: () => void;
}

interface SurveyFormState {
    serviceId: string;
}

const SurveyWrapper = styled.div`
    background-size: 266px 266px;
    padding: ${Spacing.md};
`;

class SurveyForm extends Component<SurveyFormProps, SurveyFormState> {
    componentDidMount() {
        const serviceId = this.props.serviceId;
        this.setState({ serviceId });
    }

    render() {
        const InitialValues = {
            surveyQuestions: this.props.surveyQuestions || [],
        };

        return (
            <Formik
                initialValues={InitialValues}
                onSubmit={(values, { setSubmitting }) => {
                    toastifyPromise(
                        ServiceAPI.updateServiceSurvey(
                            this.state.serviceId,
                            values.surveyQuestions.filter(question => question.question.trim() !== ''),
                        ),
                        'Failed to update survey questions',
                        'Saved survey',
                    )
                        .then(() => this.props.onSuccess())
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <ContentSection title="Service Survey" padding="0px">
                            <SurveyWrapper>
                                <Form.Row>
                                    <Col>
                                        <div style={{ marginBottom: '20px' }}>
                                            <InputListBox
                                                label="Survey Questions"
                                                name="surveyQuestions"
                                                placeholder="Entry"
                                                handleChange={handleChange}
                                                surveyValues={values.surveyQuestions}
                                            />
                                        </div>
                                    </Col>
                                    <Col />
                                </Form.Row>
                                <Button variant="primary" type="submit" disabled={isSubmitting}>
                                    Save Survey
                                </Button>
                            </SurveyWrapper>
                        </ContentSection>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default SurveyForm;
