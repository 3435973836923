import React from 'react';
import PageLayout from 'components/page.layout';
import CreateMarketingForm from './create.form';
import { RootStore } from 'types';
import { connect } from 'react-redux';
import { selectMarketing } from 'redux/marketing/marketing.selector';

const UpdateMarketing = props => {
    const onCreated = () => props.history.push('/marketing');

    return (
        <PageLayout paddingTop="2em">
            <h1>Change Marketing Entry</h1>
            <CreateMarketingForm
                onCreated={onCreated}
                buttonLabel="Change Entry"
                entry={props.marketing.find(elem => elem.id === Number(props.match.params.id))}
            />
        </PageLayout>
    );
};
const mapStateToProps = (state: RootStore) => ({
    marketing: selectMarketing(state),
});

export default connect(mapStateToProps)(UpdateMarketing);
