import React from 'react';
import PageLayout from '../../components/page.layout';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserTable from './user-table';
import AdminTable from './admin-table';
import ServiceOwnersTable from './service-owners-table';

const Page: React.FC = () => {
    return (
        <PageLayout paddingTop="2em">
            <h1>User Management</h1>
            <Tabs defaultActiveKey="users" id="userManagementTabs">
                <Tab eventKey="users" title="Users">
                    <UserTable />
                </Tab>
                <Tab eventKey="serviceOwners" title="Service Owners">
                    <ServiceOwnersTable />
                </Tab>
                <Tab eventKey="admins" title="Admins">
                    <AdminTable />
                </Tab>
            </Tabs>
        </PageLayout>
    );
};

export default Page;
