import LocalStorageService from './LocalStorageService';
import { v4 as uuidv4 } from 'uuid';

function generateNonce() {
    const array = new Uint32Array(10);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}

export const generateAndSaveOAuthState = () => {
    const state = { nonce: generateNonce(), state: uuidv4() };
    LocalStorageService.saveNonce(state.nonce);
    LocalStorageService.saveState(state.state);
    return state;
};

export const getAndRemoveOAuthState = () => {
    const nonce = LocalStorageService.getNonce();
    const state = LocalStorageService.getState();
    LocalStorageService.removeNonce();
    LocalStorageService.removeState();
    return { nonce, state };
};
