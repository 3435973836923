import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './root.reducer';
import config from 'config/config';
import { loadingBarMiddleware } from 'react-redux-loading-bar';

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk, loadingBarMiddleware(config.LOADING_BAR_CONFIG))),
);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
