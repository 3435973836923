import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Colors, Typography } from 'config/styling.constants';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ServiceSurveyQuestion } from 'types';

const Label = styled.label`
    font-family: ${Typography.baseFontFamily};
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    color: ${Colors.black};
    font-weight: bold;
`;

const Input = styled.input`
    border: 2px solid ${Colors.darkGrey};
    border-radius: 3px;
    font-family: ${Typography.baseFontFamily};
    font-size: 14px;
    height: 45px;
    padding: 10px 20px;
    flex: 1 1;
`;

export const DeleteButton = styled.button`
    margin-left: 5px;
    background-color: transparent;
    border: 0;
    height: 35px;
    color: ${Colors.mediumGrey};

    :focus {
        outline: 0;
    }
    .fa-lg {
        font-size: 1.5em;
    }
    :hover {
        opacity: 0.75;
    }
`;

const InnerContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
    align-items: center;
`;

export interface InputListBoxProps {
    stringValues?: string[];
    surveyValues?: ServiceSurveyQuestion[];
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    name: string;
    placeholder: string;
}

function InputListBox(props: InputListBoxProps) {
    const { stringValues, surveyValues, handleChange, name, label, placeholder } = props;
    return (
        <Fragment>
            {stringValues && <Label>{label}</Label>}
            {surveyValues && <Form.Label>{label}</Form.Label>}
            <FieldArray
                name={name}
                render={arrayHelpers => (
                    <Fragment>
                        {stringValues && stringValues.length === 0 && (
                            <p>{label} is hidden. Add items to enable this section.</p>
                        )}
                        {stringValues &&
                            stringValues.map((field, index) => (
                                <InnerContainer key={index}>
                                    <Input
                                        name={`${name}.${index}`}
                                        value={field}
                                        placeholder={placeholder}
                                        onChange={handleChange}
                                    />
                                    <DeleteButton type="button" onClick={() => arrayHelpers.remove(index)}>
                                        <FontAwesomeIcon icon="times-circle" size="lg" />
                                    </DeleteButton>
                                </InnerContainer>
                            ))}
                        {surveyValues && surveyValues.length === 0 && (
                            <p>{label} is hidden. Add items to enable this section.</p>
                        )}
                        {surveyValues &&
                            surveyValues.map((field, index) => (
                                <InnerContainer key={index}>
                                    <Input
                                        name={`${name}.${index}.question`}
                                        value={field.question}
                                        placeholder={placeholder}
                                        onChange={handleChange}
                                    />
                                    <Form.Check
                                        name={`${name}.${index}.required`}
                                        style={{ marginLeft: '15px' }}
                                        onChange={handleChange}
                                        type="checkbox"
                                        checked={field.required}
                                    />
                                    <DeleteButton type="button" onClick={() => arrayHelpers.remove(index)}>
                                        <FontAwesomeIcon icon="times-circle" size="lg" />
                                    </DeleteButton>
                                </InnerContainer>
                            ))}

                        {surveyValues && surveyValues.length !== 0 && (
                            <p>Check the box if the field should be required</p>
                        )}
                        {stringValues && (
                            <Button type="button" size="sm" onClick={() => arrayHelpers.push('')}>
                                Add item
                            </Button>
                        )}
                        {surveyValues && (
                            <Button
                                type="button"
                                size="sm"
                                onClick={() => arrayHelpers.push({ id: 0, question: '', required: false })}
                            >
                                Add item
                            </Button>
                        )}
                    </Fragment>
                )}
            />
        </Fragment>
    );
}

export default InputListBox;
