import { Borders, Typography } from 'config/styling.constants';

export const DefaultSelectStyles = {
    control: provided => ({
        ...provided,
        border: Borders.darkGreyBorder,
        fontFamily: Typography.baseFontFamily,
        fontSize: Typography.bodyTextFontSize,
        padding: '5px 0px',
        ':hover': {
            border: Borders.darkGreyBorder,
        },
    }),
};
