import axios from 'axios';
import Client from 'utils/api/api-client';
import { createActionCreator } from 'deox';
import LocalStorageService from 'utils/LocalStorageService';
import Config from 'config/config';
import managersApi from 'utils/api/managers.api';

export const assignRoleAction = Object.assign(
    () => {
        return true;
    },
    {
        complete: createActionCreator('LOGIN_ASSIGN_ROLE', resolve => (role: string) => resolve({ role })),
    },
);

const _loginActionThunk = (authCode: string, nonce: string) => {
    return async dispatch => {
        dispatch(loginAction.next());
        const getToken = async (authCode: string, nonce: string) => {
            const response = await axios.post(`${Config.BASE_URL}/authV2/wen/token`, {
                wenAuthCode: authCode,
                nonce,
            });
            return response;
        };
        try {
            const tokenResponse = await getToken(authCode, nonce);

            if (tokenResponse.status === 200) {
                const { access_token: accessToken } = tokenResponse.data;
                const bearerToken = 'Bearer ' + accessToken;
                const sessionTokenResponse = await managersApi.getManagerForToken(bearerToken);
                if (sessionTokenResponse.status === 200) {
                    const { name, authorities } = sessionTokenResponse.data;
                    if (authorities.length === 0) {
                        LocalStorageService.removeSession();
                        return dispatch(loginAction.error('User not authorized'));
                    }
                    LocalStorageService.saveSession(bearerToken, name, authorities);
                    return dispatch(loginAction.complete(bearerToken, name, authorities));
                } else {
                    LocalStorageService.removeSession();
                    return dispatch(loginAction.error('Failed to retrieve manager details'));
                }
            } else {
                LocalStorageService.removeSession();
                return dispatch(loginAction.error('Login error'));
            }
        } catch (error) {
            LocalStorageService.removeSession();
            return dispatch(loginAction.error('Failed to login'));
        }
    };
};

export const loginAction = Object.assign(_loginActionThunk, {
    next: createActionCreator('FETCH_LOGIN_NEXT'),
    complete: createActionCreator(
        'FETCH_LOGIN_REQUEST_COMPLETE',
        resolve => (token: string, username: string, roles: string[]) => resolve({ token, username, roles }),
    ),
    error: createActionCreator('FETCH_LOGIN_REQUEST_ERROR', resolve => (error: string) => resolve(error)),
});

const _logoutActionThunk = () => {
    return async dispatch => {
        dispatch(logoutAction.next());
        try {
            await Client.post('/authV2/wen/token/revoke');
        } catch (error) {
            console.error('Failed to revoke token. Logging out anyways.', JSON.stringify(error));
        }
        LocalStorageService.removeSession();
        return dispatch(logoutAction.complete());
    };
};

export const logoutAction = Object.assign(_logoutActionThunk, {
    next: createActionCreator('LOGOUT_NEXT'),
    complete: createActionCreator('LOGOUT_COMPLETE'),
});
