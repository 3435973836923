import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Colors, Typography } from 'config/styling.constants';

const Label = styled.label`
    font-family: ${Typography.baseFontFamily};
    font-size: 12px;
    display: block;
    color: ${Colors.black};
    font-weight: bold;
`;

const Input = styled.input`
    width: 100%;
    border: 2px solid ${Colors.darkGrey};
    border-radius: 3px;
    font-family: ${Typography.baseFontFamily};
    font-size: 14px;
    display: block;
    height: 50px;
    padding: 10px 20px;
`;

const Container = styled.div`
    width: 100%;

    :first-child {
        margin: 0;
    }
`;

export interface InputBoxProps {
    label: string;
    placeholder: string;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value: string | undefined;
}

function InputBox(props: InputBoxProps) {
    return (
        <Container>
            <Label>{props.label}</Label>
            <Input type="text" placeholder={props.placeholder} onChange={props.handleChange} value={props.value} />
        </Container>
    );
}

export default InputBox;
