import { IConversationOverview, IMessage, RootStore } from '../../types';

export function selectMailboxServiceConversations(state: RootStore): IConversationOverview[] {
    return state.mailbox.conversations.filter(c => !!c.serviceId);
}

export function selectMailboxAdminConversations(state: RootStore): IConversationOverview[] {
    return state.mailbox.conversations.filter(c => !c.serviceId);
}

export function selectMailboxConversationSlice(state: RootStore, count: number): IConversationOverview[] {
    return state.mailbox.conversations.slice(0, count);
}

export function selectMailboxIsFetching(state: RootStore): boolean {
    return state.mailbox.isFetchingMessages;
}

export function selectMailboxError(state: RootStore): string {
    return state.mailbox.error;
}

export function selectMailboxMessages(state: RootStore): IMessage[] {
    return state.mailbox.conversationMessages.messages;
}
