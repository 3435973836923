import { RootStore, MarketingEntry } from 'types';

export function selectMarketingIsFetchingState(state: RootStore): boolean {
    return state.marketing.isFetching;
}

export function selectMarketingErrorState(state: RootStore): string {
    return state.marketing.error;
}

export function selectMarketing(state: RootStore): MarketingEntry[] | undefined {
    return state.marketing.marketing;
}
