import React from 'react';
import styled from 'styled-components';
import { Colors, Spacing } from 'config/styling.constants';
import { APP_VERSION, BMW_ENVIRONMENT } from '../config/constants';

const FooterWrapper = styled.footer`
    background-color: ${Colors.lightElementGrey};
    padding: ${Spacing.sm};
`;

const Container = styled.div`
    max-width: 1024px;
    margin: 0 auto;

    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: space-between;
`;

const Footer: React.FC = () => {
    return (
        <FooterWrapper>
            <Container>
                <strong>© BMW AG {new Date().getFullYear()}</strong>
                Version: {APP_VERSION}
                {(BMW_ENVIRONMENT === 'INT' || BMW_ENVIRONMENT === 'LOCAL') && ` (${BMW_ENVIRONMENT})`}
            </Container>
        </FooterWrapper>
    );
};
export default Footer;
