import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import { FieldProps } from 'formik';
import { DefaultSelectStyles } from 'components/dropdown';

const components = {
    DropdownIndicator: null,
};

interface InputValue {
    label: string;
    value: string;
}

interface InputProps extends FieldProps {
    placeholder: string;
}

const createOption = (label: string) => ({
    label,
    value: label,
});

const createInitialValues = (values: string[]) => {
    return values.map(item => createOption(item));
};

interface TagInputState {
    inputValue: string;
    value: InputValue[];
}

export default class TagInput extends Component<InputProps, TagInputState> {
    state = {
        inputValue: '',
        value: createInitialValues(this.props.field.value) || [],
    };

    handleChange = (value: InputValue[]) => {
        if (value !== null) {
            this.setState({ value }, () => this.handleStateChange());
            this.state.value.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
        } else {
            // set to empty array if last item will be removed
            this.setState({ value: [] }, () => this.handleStateChange());
        }
    };

    handleInputChange = (inputValue: string) => {
        this.setState({ inputValue });
    };

    handleStateChange = () => {
        const { field, form } = this.props;
        form.setFieldValue(
            field.name,
            this.state.value.map((item: InputValue) => item.value),
        );
        this.state.value.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
    };

    handleKeyDown = (event: KeyboardEvent) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (value.find((item: InputValue) => item.label === inputValue)) {
                    // reset if input value already included
                    this.setState({ inputValue: '' });
                    event.preventDefault();
                    return;
                }

                this.setState(
                    {
                        inputValue: '',
                        value: [...value, createOption(inputValue)],
                    },
                    () => this.handleStateChange(),
                );
                event.preventDefault();
        }
    };

    render() {
        const { inputValue, value } = this.state;
        return (
            <CreatableSelect
                components={components}
                inputValue={inputValue}
                styles={DefaultSelectStyles}
                isClearable={false}
                isMulti
                menuIsOpen={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                placeholder={this.props.placeholder}
                value={value}
            />
        );
    }
}
