import styled from 'styled-components';
import { Colors, Spacing } from 'config/styling.constants';

type TitleProps = {
    marginBottom?: string;
};
export const Title = styled.h1<TitleProps>`
    ${props => (props.marginBottom ? `margin-bottom: ${props.marginBottom};` : `margin-bottom: ${Spacing.md};`)}
`;

export const AddLinkInfo = styled.p`
    font-size: 12px;
    color: ${Colors.darkElementGrey};
    margin-bottom: 2.5rem;
    margin-top: 0;
`;

export const StyledLink = styled.a`
    color: ${Colors.cyan};
    &:hover {
        color: ${Colors.lightCyan};
        text-decoration: none;
    }
`;

export const LoginContainer = styled.div`
    max-width: 500px;
    margin: 0 auto;
    min-height: 70vh;
    text-align: center;
    margin-top: 2em;
`;

export const LegacyLoginContainer = styled.div`
    max-width: 500px;
    margin: 0 auto;
    min-height: 70vh;
`;

export const FlexContainer = styled.div`
    @media (min-width: 640px) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
`;

export const Badge = styled.span`
    padding: calc(10px / 2) 10px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    will-change: background-color, color;
    line-height: 16px;
    margin-right: 3px;
    text-transform: uppercase;

    background-color: ${Colors.cyan};
    color: ${Colors.white};

    .service:hover &,
    .service:focus & {
        background-color: ${Colors.white};
        color: ${Colors.cyan};
    }
`;

export const GridElement = styled.article`
    flex: 0 0 100%;
    margin-top: ${Spacing.xs};

    @media (min-width: 768px) {
        flex-basis: calc(50% - 10px / 2);

        :nth-child(2n + 1) {
            margin-right: ${Spacing.xs};
        }
    }

    @media (min-width: 992px) {
        flex-basis: calc(33% - 11px / 3);

        /* Reset of min-width: 768px */
        :nth-child(2n + 1) {
            margin-right: 0;
        }

        :nth-child(3n + 2) {
            margin-left: ${Spacing.xs};
            margin-right: ${Spacing.xs};
        }
    }
`;
