import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AuthenticationStateEnum, selectSessionGetAuthenticationState } from 'redux/session/session.selector';
import { RootStore } from 'types';
import { connect } from 'react-redux';

interface AuthRouteProps extends RouteProps {
    authenticationState: AuthenticationStateEnum;
    allowedState: AuthenticationStateEnum;
}

class RestrictedRoute extends Route<AuthRouteProps> {
    render() {
        if (this.props.allowedState === this.props.authenticationState) {
            return <Route {...this.props} />;
        }
        switch (this.props.authenticationState) {
            case 'LOGGED_IN':
                return <Redirect to={{ pathname: '/dashboard' }} />;
            case 'REQUIRES_ROLE_SELECTION':
                return (
                    <Redirect
                        to={{
                            pathname: '/select-authorities',
                            state: { from: this.props.location },
                        }}
                    />
                );
            default:
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: this.props.location },
                        }}
                    />
                );
        }
    }
}

const mapStateToProps = (state: RootStore) => ({
    authenticationState: selectSessionGetAuthenticationState(state),
});

export default connect(mapStateToProps)(RestrictedRoute);

const mapStateToPropsLoggedInRequired = (state: RootStore) => ({
    authenticationState: selectSessionGetAuthenticationState(state),
    allowedState: 'LOGGED_IN' as AuthenticationStateEnum,
});

export const AuthenticatedRoute = connect(mapStateToPropsLoggedInRequired)(RestrictedRoute);
