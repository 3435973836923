const Colors = {
    white: '#ffffff',
    black: '#000000',
    lightElementGrey: '#d6d6d6',
    darkElementGrey: '#6f6f6f',
    textGrey: '#494949',
    lightGrey: '#f2f2f2',
    mediumGrey: '#bbbbbb',
    darkGrey: '#666666',
    petrol: '#004750',
    cyan: '#04829B',
    lightCyan: '#28b7d3',
    lightBlue: '#2b82fc',
    darkBlue: '#0046BD',
    turquoise: '#30D5C8',
    green: '#008000',
    brown: '#964B00',
    transparentBlue: 'rgba(4, 130, 155, 0.2)',
};

const Spacing = {
    xxs: '5px',
    xs: '10px',
    sm: '20px',
    md: '40px',
    lg: '60px',
    xl: '80px',
    servicesGridSpacing: '3px',
};

const Watermark = {
    light:
        "data:image/svg+xml,%3Csvg width='270' height='270' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='rotate(90 122 135)' fill='none' fill-rule='evenodd' opacity='.1'%3E%3Cpath d='M121 7v117H5v-1C5 59 57 7 121 7zm0 234V124h116v1c0 64-52 116-116 116z' fill='%23FFF' fill-rule='nonzero'/%3E%3Ccircle stroke='%23FFF' stroke-width='22' cx='122' cy='122' r='122'/%3E%3C/g%3E%3C/svg%3E",
    white:
        "data:image/svg+xml,%3Csvg width='270' height='270' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='rotate(90 122 135)' fill='none' fill-rule='evenodd'%3E%3Cpath d='M121 7v117H5v-1C5 59 57 7 121 7zm0 234V124h116v1c0 64-52 116-116 116z' fill='%23FFF' fill-rule='nonzero'/%3E%3Ccircle stroke='%23FFF' stroke-width='22' cx='122' cy='122' r='122'/%3E%3C/g%3E%3C/svg%3E",
};

const Gradients = {
    defaultBlue: `linear-gradient(320deg, ${Colors.petrol}, ${Colors.cyan})`,
    serviceHeader: `linear-gradient(to right, ${Colors.petrol}, ${Colors.cyan} 55%, rgba(0, 0, 0, 0.3) 55%, rgba(0, 0, 0, 0.3))`,
    mobileServiceHeader: `linear-gradient(to right, ${Colors.petrol}, ${Colors.cyan} 100%, rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.3))`,
    serviceTileDefault: `linear-gradient(to bottom, ${Colors.black}, ${Colors.black} 21%, rgba(0, 0, 0, 0))`,
    serviceTileDisabled: `linear-gradient(to right, #4e4e4e, rgba(102, 102, 102, 0.25))`,
    serviceTileBlue: `linear-gradient(
    to right,
    ${Colors.petrol},
    rgba(4, 130, 155, 0.75)
  )`,
};

const Typography = {
    baseFontFamily: 'Arial, sans-serif',
    boldFontFamily: 'BMWBold, Arial, sans-serif',
    baseFontSize: '16px',

    logoNormalFontSize: '32px',
    logoSmallFontSize: '16px',

    headingLevelOneFontSize: '32px',
    headingLevelOneLineHeight: 1,
    headingLevelOneLetterSpacing: '-1px',
    headingLevelTwoFontSize: '22px',
    headingLevelTwoLineHeight: 1.42,
    headingLevelTwoLetterSpacing: 0,

    bodyTextFontSize: '14px',
    bodyTextLineHeight: 1.36,
    bodyTextLetterSpacing: 0,

    smallTextFontSize: '12px',
    smallTextLineHeight: 1.5,
    smallTextLetterSpacing: '1px',

    headerTitleFontSize: `calc(32px * 1.4)`,
};

const Borders = {
    baseBorderWidth: `2px`,
    baseBorderStyle: `solid`,
    blackBorder: `2px solid ${Colors.black}`,
    whiteBorder: `2px solid ${Colors.white}`,
    lightGreyBorder: `2px solid ${Colors.lightGrey}`,
    mediumGreyBorder: `2px solid ${Colors.mediumGrey}`,
    darkGreyBorder: `2px solid ${Colors.darkGrey}`,
    solidTransparentBorder: `2px solid transparent`,
    dottedWhiteBorder: `2px dotted ${Colors.white}`,
    cssTriangleSize: `7px`,
    inputBorderRadius: `3px`,
};

export { Colors, Spacing, Watermark, Gradients, Typography, Borders };
