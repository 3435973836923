import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { loadServices } from 'redux/servicesOverview/services-overview.actions';
import { IServiceOverview, RootStore } from 'types';
import { ThunkDispatch } from 'redux-thunk';
import ServiceTile from 'components/blocks/services-tile';
import { Spacing } from 'config/styling.constants';
import { toast } from 'react-toastify';
import ServiceFilters from './service-filters';

const Container = styled.div`
    padding-bottom: 3rem;
    padding-top: ${Spacing.sm};
`;

const ServiceGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const FilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
`;

interface ServiceGridProps {
    services: IServiceOverview[];
    error: string;
    loadServices: () => void;
}

interface ServiceGridState {
    filters: {
        active: string | string[] | null;
        visible: string | string[] | null;
        internal: string | string[] | null;
    };
}

class ServicesGridComponent extends React.Component<ServiceGridProps, ServiceGridState> {
    constructor(props: ServiceGridProps) {
        super(props);
        this.state = {
            filters: {
                active: null,
                visible: null,
                internal: null,
            },
        };
    }

    componentDidMount() {
        this.props.loadServices();
    }

    handleFilterChange = (tag: keyof ServiceGridState['filters'], selectedValue: string | string[] | null) => {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [tag]: selectedValue,
            },
        }));
    };

    getFilteredServices() {
        const { services } = this.props;
        const { filters } = this.state;

        return services.filter(service => {
            const matchActive = filters.active === null || service.active === (filters.active === 'active');
            const matchVisible = filters.visible === null || service.hidden === (filters.visible === 'hidden');
            const matchInternal =
                filters.internal === null ||
                (service.isInternal !== undefined ? service.isInternal : false) === (filters.internal === 'internal');

            return matchActive && matchVisible && matchInternal;
        });
    }

    render() {
        const { error } = this.props;
        const { filters } = this.state;

        if (error) {
            toast.error('Failed to load Services');
        }

        const filteredServices = this.getFilteredServices();

        return (
            <Container>
                <FilterContainer>
                    <ServiceFilters filters={filters} onFilterChange={this.handleFilterChange} />
                </FilterContainer>

                <ServiceGrid>
                    {filteredServices.length > 0 ? (
                        filteredServices.map(service => (
                            <ServiceTile key={service.id} service={service} to={`/services/edit/${service.id}`} />
                        ))
                    ) : (
                        <p>No services match the selected filters.</p>
                    )}
                </ServiceGrid>
            </Container>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    error: state.servicesOverview.error,
    services: state.servicesOverview.services,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServices: () => dispatch(loadServices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicesGridComponent);
