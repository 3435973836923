import React from 'react';
import styled, { css } from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';
import { Link, useLocation } from 'react-router-dom';

export interface ButtonProps {
    readonly style?: object;
    readonly outline?: boolean;
    readonly header?: boolean;
    readonly to?: string;
    readonly activeStyle?: object;
}

const ButtonLink = styled.div<ButtonProps>`
    padding: ${Spacing.xs} ${Spacing.md};
    display: inline-block;
    font-size: ${Typography.baseFontSize};
    font-weight: bold;
    line-height: 1;
    text-decoration: none;
    border: 0;
    margin-top: ${Spacing.sm};
    will-change: background-color;
    transition: background-color 250ms ease-out, color 250ms ease-out;
    cursor: pointer;
    text-align: center;
    scroll-behavior: smooth;

    background-color: ${Colors.cyan};
    color: ${Colors.white};

    a,
    a:hover {
        color: ${Colors.white};
        text-decoration: none;
    }

    &:hover {
        background-color: ${Colors.petrol};
        color: ${Colors.white};
        text-decoration: none;
    }

    &:focus:active {
        outline: none;
        box-shadow: inset 0 0 5px ${Colors.black};
    }
    ${props =>
        props.outline &&
        css`
            background-color: transparent;
            border: 2px solid ${Colors.white};

            &:hover {
                background-color: ${Colors.white};
                color: ${Colors.petrol};
                text-decoration: none;
                a {
                    color: ${Colors.petrol};
                    text-decoration: none;
                }
            }
        `}

    ${props => props.header && `margin-top: ${Spacing.md};`} 
	
	@media(prefers-reduced-motion) {
        transition: none;
    }
`;

const Button: React.FC<ButtonProps> = ({ header, outline, children, to, style, activeStyle }) => {
    const { pathname } = useLocation();
    const buttonStyle = pathname === to ? { ...style, ...activeStyle } : style;

    const button = (
        <ButtonLink header={header} outline={outline} style={buttonStyle}>
            {children}
        </ButtonLink>
    );

    return <div>{to ? <Link to={to}>{button}</Link> : button}</div>;
};
export default Button;
