import React, { Component, Fragment } from 'react';
import ServiceAPI from 'utils/api/services.api';
import ContentSection from 'components/blocks/content-section';
import { Button } from 'react-bootstrap';
import AceEditor from 'react-ace';
import Form from 'react-bootstrap/Form';

import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-tomorrow';
import { toastifyPromise } from '../../../utils/toast-utils';

interface DataPrivacyProps {
    serviceId: string;
}

interface DataPrivacyState {
    graphqlQuery: string;
    serviceId: string;
}

class DataPrivacyComponent extends Component<DataPrivacyProps, DataPrivacyState> {
    constructor(props) {
        super(props);
        this.state = {
            graphqlQuery: '',
            serviceId: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        toastifyPromise(ServiceAPI.getDataPrivacy(this.props.serviceId), 'Failed to fetch query').then(res => {
            this.setState({
                graphqlQuery: res.data.graphqlQuery || '',
                serviceId: res.data.topic,
            });
        });
    }

    handleChange(value: string) {
        this.setState({ graphqlQuery: value });
    }

    handleSave() {
        toastifyPromise(
            ServiceAPI.updateDataPrivacyGraphqlQuery(this.props.serviceId, this.state.graphqlQuery),
            'Failed to update query',
            'Query saved successfully',
        );
    }

    render() {
        const editorOptions = {
            showLineNumbers: true,
            tabSize: 2,
            useWorker: false,
        };

        return (
            <Fragment>
                <ContentSection title="Data Privacy Settings">
                    <Form.Group>
                        <Form.Label>Service ID</Form.Label>
                        {this.state.serviceId}
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>GraphQl Query</Form.Label>
                        <AceEditor
                            placeholder="// Insert your GraphQl Query ..."
                            mode="javascript"
                            theme="tomorrow"
                            name="graphqlQuery-editor"
                            onChange={this.handleChange}
                            fontSize={14}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={false}
                            value={this.state.graphqlQuery}
                            setOptions={editorOptions}
                        />
                    </Form.Group>
                </ContentSection>

                <Button onClick={this.handleSave}>Save</Button>
            </Fragment>
        );
    }
}
export default DataPrivacyComponent;
