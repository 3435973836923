import React, { Component } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

export interface ReplyFormProps {
    onSendMessage: (text: string) => void;
}

export default class ReplyForm extends Component<ReplyFormProps> {
    render() {
        const InitialValues = {
            body: '',
        };

        return (
            <Formik
                initialValues={InitialValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(false);
                    this.props.onSendMessage(values.body);
                    resetForm();
                }}
            >
                {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <InputGroup>
                            <Form.Control
                                as="textarea"
                                name="body"
                                placeholder="Your Answer ..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.body}
                            />
                            <InputGroup.Append>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    style={{ height: '66px' }}
                                    disabled={isSubmitting || !values.body}
                                >
                                    Send
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form>
                )}
            </Formik>
        );
    }
}
