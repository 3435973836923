import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';
import { IBroadcastConversation, IConversation, IConversationOverview, IPostMessage } from 'types';

export class MessagesAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    getConversations() {
        return this.client.get<IConversationOverview[]>(`/conversations/overview`).then(res => res.data);
    }

    getMessages(conversationId: string) {
        return this.client.get<IConversation>(`/conversations/${conversationId}`).then(res => res.data);
    }

    getBroadcasts() {
        return this.client.get<IBroadcastConversation[]>('/conversations/broadcasts').then(res => res.data);
    }

    postMessage(serviceId: string | undefined, message: IPostMessage) {
        return this.client.post(serviceId ? `/services/${serviceId}/messages` : `/conversations/general`, message);
    }

    postBroadcastMessage(serviceId: string, message: IPostMessage) {
        return this.client.post(`/services/${serviceId}/messages/broadcast`, message);
    }
}

export default new MessagesAPI(Client);
