import React, { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ServiceHeader from 'components/header/header-service';
import RequirementsSection from 'components/blocks/requirements-section';
import { ServiceContent, ServiceInstruction } from 'types';
import SectionBlock from 'components/blocks/showcase-section';
import PageLayout from 'components/page.layout';
import ServiceUtils from 'utils/service.utils';
import { StyledLink } from 'components/styled-components';

interface ServicePreviewModalProps {
    serviceContent: ServiceContent | ServiceInstruction;
}

interface ServicePreviewModalState {
    open: boolean;
}

class ServicePreviewModal extends React.Component<ServicePreviewModalProps, ServicePreviewModalState> {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    render() {
        const closeModal = () => this.setState({ open: false });

        const openModal = () => this.setState({ open: true });

        const content = ServiceUtils.normalizeContent(this.props.serviceContent);

        let serviceHeader;
        if ('headline' in this.props.serviceContent && 'subline' in content) {
            serviceHeader = (
                <ServiceHeader title={content.headline} image={content.headerImage} subtitle={content.subline} />
            );
        }

        const contentHandler = (content: string) => {
            const regex = /<([^>]+)>/g;
            const matches = content.match(regex);

            if (matches) {
                const extractedLinks = matches.map(match => match.slice(1, -1));

                const paragraphs = content.split(regex);

                return (
                    <>
                        {paragraphs.map((paragraph, index) => (
                            <React.Fragment key={index}>
                                {extractedLinks.includes(paragraph) && (
                                    <StyledLink key={index} href={paragraph} target="_blank" rel="noopener noreferrer">
                                        <p>{paragraph}</p>
                                    </StyledLink>
                                )}
                                {!extractedLinks.includes(paragraph) && <p>{paragraph}</p>}
                            </React.Fragment>
                        ))}
                    </>
                );
            } else {
                return content;
            }
        };

        return (
            <Fragment>
                <Button variant="outline-primary" onClick={openModal}>
                    Preview
                </Button>

                <Modal show={this.state.open} onHide={closeModal} size={'xl'}>
                    <Form onSubmit={closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Service Description</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PageLayout>
                                {serviceHeader}
                                {content.sections.filter(ServiceUtils.isSectionNotEmpty).map((section, index) => (
                                    <SectionBlock
                                        key={index}
                                        contentType={section.contentType}
                                        contentUrl={section.contentUrl}
                                        swap={index % 2 === 0}
                                        greyBackground={index === 0}
                                    >
                                        <h2>{section.title}</h2>
                                        <div style={{ whiteSpace: 'pre-line' }}>
                                            {contentHandler(section.content || '')}
                                        </div>
                                    </SectionBlock>
                                ))}
                                {'preconditions' in content && (
                                    <RequirementsSection
                                        title="TEST USER REQUIREMENTS"
                                        preconditions={content.preconditions}
                                    />
                                )}
                            </PageLayout>
                        </Modal.Body>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default ServicePreviewModal;
