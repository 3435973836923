import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors, Gradients, Spacing, Typography } from 'config/styling.constants';
import DefaultImage from 'assets/images/services/coming_soon_defaultpic.png';
import { GridElement } from 'components/styled-components';
import { MarketingEntry } from '../../types';

interface MarketingChildProps {
    image?: string;
    disabled?: boolean;
}

interface MarketingTileProps {
    entry: MarketingEntry;
    to: string;
}

const GridItem = styled(GridElement)`
    a:hover {
        text-decoration: none;
    }
`;

const ServiceItem = styled.div<MarketingChildProps>`
    min-height: 300px;
    display: block;
    color: ${Colors.white};
    text-decoration: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    
    background-image: url("${props => props.image || `${DefaultImage}`}");
`;

const ServiceGradient = styled.div<MarketingChildProps>`
    background-image: ${Gradients.serviceTileDefault};
    padding: ${Spacing.sm};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .service:hover &,
    .service:focus & {
        background-image: ${props =>
            props.disabled ? `${Gradients.serviceTileDisabled}` : `${Gradients.serviceTileBlue}`};
    }
`;

const Title = styled.h3`
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.headingLevelOneFontSize};
    line-height: ${Typography.headingLevelOneLineHeight};
    letter-spacing: ${Typography.headingLevelOneLetterSpacing};
    margin-top: ${Spacing.sm};
`;

export default function MarketingTile({ entry, to }: MarketingTileProps) {
    const upperCaseTitle = entry.headline ? entry.headline.toUpperCase() : '';

    return (
        <GridItem>
            <Link to={to} className="marketing">
                <ServiceItem image={entry.imageUrl}>
                    <ServiceGradient>
                        <Title>{upperCaseTitle}</Title>
                        <p>{entry.description}</p>
                    </ServiceGradient>
                </ServiceItem>
            </Link>
        </GridItem>
    );
}
