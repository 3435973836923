import React from 'react';
import { FieldProps } from 'formik';
import Select from 'react-select';
import { DefaultSelectStyles } from 'components/dropdown';

type MultiSelectOption = {
    value?: string;
    label?: string;
};

interface TaggedMultiSelectProps extends FieldProps {
    options: MultiSelectOption[];
    isMulti?: boolean;
}

const TaggedMultiSelect = ({ field, form, options, isMulti = false }: TaggedMultiSelectProps) => {
    const onChange = (value: MultiSelectOption[]) => {
        if (value) {
            form.setFieldValue(
                field.name,
                isMulti
                    ? (value as MultiSelectOption[]).map((item: MultiSelectOption) => item.value)
                    : (value as MultiSelectOption).value,
            );
        } else {
            form.setFieldValue(field.name, isMulti ? [] : '');
        }
    };

    const getValue = () => {
        if (options) {
            return isMulti
                ? options.filter(option => field.value.indexOf(option.value) >= 0)
                : options.find(option => option.value === field.value);
        } else {
            return isMulti ? [] : ('' as any);
        }
    };

    return (
        <Select
            name={field.name}
            styles={DefaultSelectStyles}
            value={getValue()}
            onChange={onChange}
            options={options}
            isMulti={isMulti}
        />
    );
};
export default TaggedMultiSelect;
