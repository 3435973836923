import React from 'react';
import styled from 'styled-components';
import { Typography } from 'config/styling.constants';
import { Checkbox } from 'components/inputs/Checkbox'; // Make sure to import your Checkbox component

const CheckboxContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    align-items: center;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Label = styled.label`
    font-family: ${Typography.baseFontFamily};
    font-size: 12px;
    color: black;
    margin-left: 5px;
    margin-bottom: 0;
`;

interface CheckboxGroupProps {
    options: { label: string; value: string }[];
    selectedValue: string | string[] | null;
    handleCheckboxChange: (selectedValue: string | string[] | null) => void;
    isExclusive: boolean;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ options, selectedValue, handleCheckboxChange, isExclusive }) => {
    const handleChange = (value: string) => {
        if (isExclusive) {
            handleCheckboxChange(selectedValue === value ? null : value);
        } else {
            if (Array.isArray(selectedValue)) {
                const newValue = selectedValue.includes(value)
                    ? selectedValue.filter(item => item !== value)
                    : [...selectedValue, value];
                handleCheckboxChange(newValue);
            } else if (selectedValue === value) {
                handleCheckboxChange(null);
            } else {
                handleCheckboxChange([value]);
            }
        }
    };

    return (
        <CheckboxContainer>
            {options.map(option => (
                <CheckboxWrapper key={option.value}>
                    <Checkbox
                        isSelected={
                            Array.isArray(selectedValue)
                                ? selectedValue.includes(option.value)
                                : selectedValue === option.value
                        }
                        onClick={() => handleChange(option.value)}
                    />
                    <Label htmlFor={option.value}>{option.label}</Label>
                </CheckboxWrapper>
            ))}
        </CheckboxContainer>
    );
};

export default CheckboxGroup;
