import React, { Component, Fragment } from 'react';
import ServiceAPI from 'utils/api/services.api';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import ContentSection from 'components/blocks/content-section';
import { Button } from 'react-bootstrap';
import { toastifyPromise } from '../../../utils/toast-utils';

interface TermsAndPrivacyProps {
    serviceId: string;
}

interface TermsAndPrivacyState {
    termsAndConditions: string;
    dataPrivacy: string;
}

class TermsAndPrivacyComponent extends Component<TermsAndPrivacyProps, TermsAndPrivacyState> {
    constructor(props: TermsAndPrivacyProps) {
        super(props);
        this.state = {
            termsAndConditions: '',
            dataPrivacy: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        toastifyPromise(
            ServiceAPI.getServicePolicy(this.props.serviceId).then(res => {
                this.setState({
                    termsAndConditions: res.data.termsAndConditions,
                    dataPrivacy: res.data.dataPrivacy,
                });
                return res;
            }),
            'Failed to load terms and privacy policy',
        );
    }

    handleChange(value: string, fieldName: 'TERMS' | 'PRIVACY') {
        switch (fieldName) {
            case 'TERMS':
                this.setState({ termsAndConditions: value });
                break;
            case 'PRIVACY':
                this.setState({ dataPrivacy: value });
                break;
        }
    }

    handleSave() {
        toastifyPromise(
            ServiceAPI.updateServicePolicy(this.props.serviceId, this.state),
            'Failed to update terms and policy',
            'Successfully saved',
        );
    }

    render() {
        return (
            <Fragment>
                <ContentSection title="Terms and Conditions">
                    <ReactQuill
                        value={this.state.termsAndConditions}
                        onChange={value => this.handleChange(value, 'TERMS')}
                    />
                </ContentSection>

                <ContentSection title="Privacy Policy">
                    <ReactQuill
                        value={this.state.dataPrivacy}
                        onChange={value => this.handleChange(value, 'PRIVACY')}
                    />
                </ContentSection>

                <Button onClick={this.handleSave}>Save</Button>
            </Fragment>
        );
    }
}
export default TermsAndPrivacyComponent;
