import { createReducer } from 'deox';
import { combineReducers } from 'redux';
import { IManager } from '../../types/manager.types';
import { loadServiceOwners } from './service-owners.actions';

const defaultIsLoadingState = false;
export const isLoadingReducer = createReducer(defaultIsLoadingState, handleAction => [
    handleAction(loadServiceOwners.next, () => true),
    handleAction([loadServiceOwners.complete, loadServiceOwners.error], () => false),
]);

const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(loadServiceOwners.complete, () => defaultErrorState),
    handleAction(loadServiceOwners.error, (_, { payload }) => payload),
]);

const defaultServiceOwnersState: IManager[] = [];
export const serviceOwnersReducer = createReducer(defaultServiceOwnersState, handleAction => [
    handleAction(loadServiceOwners.complete, (_, { payload }) => payload),
    handleAction(loadServiceOwners.error, status => status),
]);

export const serviceOwnersManagementReducer = combineReducers({
    isLoading: isLoadingReducer,
    error: errorReducer,
    managers: serviceOwnersReducer,
});

export type IServiceOwnersStore = ReturnType<typeof serviceOwnersManagementReducer>;
