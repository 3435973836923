import Client from 'utils/api/api-client';
import {
    IService,
    IServiceOverview,
    IServicePatch,
    IServiceTile,
    IServiceTilePatch,
    RequirementsPayload,
    ServiceContent,
    ServiceDataPrivacy,
    ServiceInstruction,
    ServiceMaxSubscriptionCount,
    ServicePolicy,
    ServiceSurveyQuestion,
} from 'types';
import axios, { AxiosInstance } from 'axios';
import { ArchiveUploadResult } from 'types/service.api.types';

function getErrorMessage(error: any) {
    if (error && error.response) {
        const response = error.response;
        const data = response.data;
        switch (response.status) {
            case 0:
            case 504:
                return 'Server not reachable';
            case 404:
                return data.message || 'The requested resource could not be found';
            case 403:
                return data.message || 'Forbidden';
            case 401:
                return 'Unauthorized';
            case 400:
                return data.message || 'Bad request';
            case 500:
                return 'Internal server error';
            default:
                return data.status;
        }
    }
    return (error && error.message) || null;
}

export class ServiceAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    getServices() {
        return this.client.get<IServiceOverview[]>(`/services`).then(response => response.data);
    }

    async getServiceDetails(serviceId: string): Promise<IService> {
        try {
            const result = await this.client.get<IService>(`/services/${serviceId}`);

            if (axios.isAxiosError(result)) {
                throw result;
            }

            return result.data;
        } catch (error) {
            const messageDetail = getErrorMessage(error);
            if (messageDetail) {
                console.log(`errorMessage:\n${messageDetail}`);
            }

            return Promise.reject(error);
        }
    }

    updateContent(serviceId: string, content: ServiceContent) {
        return this.client.put(`/services/${serviceId}/content`, content);
    }

    updateInstruction(serviceId: string, instruction: ServiceInstruction) {
        return this.client.put(`/services/${serviceId}/instruction`, instruction);
    }

    updateRequirements(serviceId: string, requirements: RequirementsPayload) {
        return this.client.put(`/services/${serviceId}/requirements`, requirements);
    }

    patchService(serviceId: string, service: IServiceTilePatch) {
        return this.client.patch(`/services/${serviceId}`, service);
    }

    createService(service: IServiceTile) {
        return this.client.post('/services', service);
    }

    updateService(serviceId: string, service: IServicePatch) {
        return this.client.patch(`/services/${serviceId}`, service);
    }

    deleteService(serviceId: string) {
        return this.client.delete(`/services/${serviceId}`);
    }

    getAnalytics(serviceId: string) {
        return this.client.get(`/analytics/${serviceId}`);
    }

    getServicePolicy(serviceId: string) {
        return this.client.get<ServicePolicy>(`/services/${serviceId}/policy`);
    }

    updateServicePolicy(serviceId: string, policyData: ServicePolicy) {
        return this.client.put(`/services/${serviceId}/policy`, policyData);
    }

    getDataPrivacy(serviceId: string) {
        return this.client.get<ServiceDataPrivacy>(`/services/${serviceId}/privacy`);
    }

    updateDataPrivacyGraphqlQuery(serviceId: string, graphqlQuery: string) {
        return this.client.put(`/services/${serviceId}/privacy`, { graphqlQuery });
    }

    uploadImage(file: File) {
        const form = new FormData();
        form.append('file', file);
        return this.client.post('/uploads/images', form);
    }

    uploadCustomContent(file: File) {
        const form = new FormData();
        form.append('file', file);
        return this.client.post<ArchiveUploadResult>('/uploads/content', form);
    }

    getServiceSurvey(serviceId: string) {
        return this.client.get(`/services/${serviceId}/survey/responses`);
    }

    updateServiceSurvey(serviceId: string, questions: ServiceSurveyQuestion[]) {
        return this.client.put(`/services/${serviceId}/survey`, questions);
    }

    getServiceSubscribers(serviceId: string) {
        return this.client.get(`/export/${serviceId}`);
    }

    updateLimitSubscriptionMember(serviceId: string, maxSubscriptionCount: number | null) {
        const body: ServiceMaxSubscriptionCount = {
            maxSubscriptionCount: maxSubscriptionCount === null ? null : maxSubscriptionCount,
        };
        return this.client.put(`/services/${serviceId}/maxSubscriptionCount`, body);
    }
}

export default new ServiceAPI(Client);
