import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';
import { IConfiguration } from 'types';

export class ConfigurationAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    getConfig(): Promise<IConfiguration> {
        return this.client.get(`/public/config.json`).then(response => response.data);
    }
}
export default new ConfigurationAPI(Client);
