import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import ServiceAPI from 'utils/api/services.api';
import { RootStore } from 'types';
import { Button } from 'react-bootstrap';
import { loadServiceDetails } from 'redux/serviceDetails/service-details.actions';
import styled from 'styled-components';
import { Spacing } from 'config/styling.constants';
import { toast } from 'react-toastify';

interface ServiceStatusButtonProps {
    serviceId: string;
    status: boolean;
    loadServiceDetails: (serviceId: string) => void;
}

const Container = styled.div`
    display: flex;
    @media (max-width: 600px) {
        width: 200px;
        flex-direction: column;
    }
`;

const StatusButton = styled(Button)`
    @media (max-width: 600px) {
        margin: ${Spacing.xxs};
        width: 100%;
    }
`;

const _ServiceStatusActiveButtons: React.FC<ServiceStatusButtonProps> = ({ serviceId, loadServiceDetails, status }) => {
    const activateService = async active => {
        try {
            await ServiceAPI.updateService(serviceId, { active });
            loadServiceDetails(serviceId);
        } catch {
            toast.error('Failed to change service active status');
        }
    };

    return (
        <Container>
            <StatusButton variant={status ? 'primary' : 'outline-primary'} onClick={() => activateService(true)}>
                ACTIVE
            </StatusButton>
            <StatusButton variant={!status ? 'primary' : 'outline-primary'} onClick={() => activateService(false)}>
                INACTIVE
            </StatusButton>
        </Container>
    );
};

const _ServiceStatusHiddenButtons: React.FC<ServiceStatusButtonProps> = ({ serviceId, loadServiceDetails, status }) => {
    const hideService = async (hide: boolean) => {
        try {
            await ServiceAPI.updateService(serviceId, { hidden: hide });
            loadServiceDetails(serviceId);
        } catch {
            toast.error('Failed to change service hidden status');
        }
    };

    return (
        <Container>
            <StatusButton variant={!status ? 'primary' : 'outline-primary'} onClick={() => hideService(false)}>
                VISIBLE
            </StatusButton>
            <StatusButton variant={status ? 'primary' : 'outline-primary'} onClick={() => hideService(true)}>
                HIDDEN
            </StatusButton>
        </Container>
    );
};

const _ServiceStatusInternalButtons: React.FC<ServiceStatusButtonProps> = ({
    serviceId,
    loadServiceDetails,
    status,
}) => {
    const changeInternalStatus = async (isInternal: boolean) => {
        try {
            await ServiceAPI.updateService(serviceId, { isInternal });
            loadServiceDetails(serviceId);
        } catch {
            toast.error('Failed to change service internal status');
        }
    };

    return (
        <Container>
            <StatusButton variant={!status ? 'primary' : 'outline-primary'} onClick={() => changeInternalStatus(false)}>
                PUBLIC
            </StatusButton>
            <StatusButton variant={status ? 'primary' : 'outline-primary'} onClick={() => changeInternalStatus(true)}>
                INTERNAL
            </StatusButton>
        </Container>
    );
};
const mapStateToProps = (state: RootStore) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServiceDetails: (serviceId: string) => dispatch(loadServiceDetails(serviceId)),
});

export const ServiceStatusActiveButtons = connect(mapStateToProps, mapDispatchToProps)(_ServiceStatusActiveButtons);
export const ServiceStatusHiddenButtons = connect(mapStateToProps, mapDispatchToProps)(_ServiceStatusHiddenButtons);
export const ServiceStatusInternalButtons = connect(mapStateToProps, mapDispatchToProps)(_ServiceStatusInternalButtons);
