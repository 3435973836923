import React from 'react';
import PageLayout from 'components/page.layout';
import ServicesGrid from './services.grid';
import DashboardMessagesGrid from './messages.grid';
import { Title } from 'components/styled-components';
import Button from 'components/button';
import { Spacing } from 'config/styling.constants';
import { RootStore } from 'types';
import { selectSessionIsAdmin } from 'redux/session/session.selector';
import { connect } from 'react-redux';

interface DashboardProps {
    isAdmin: boolean;
}

const DashboardPage = (props: DashboardProps) => {
    return (
        <PageLayout paddingTop="2em">
            <Title marginBottom={Spacing.sm}>Active Services</Title>
            {!props.isAdmin && <Button to={'/services/create'}>Create new Service</Button>}
            <ServicesGrid />
            <DashboardMessagesGrid />
        </PageLayout>
    );
};

const mapStateToProps = (state: RootStore) => ({
    isAdmin: selectSessionIsAdmin(state),
});

export default connect(mapStateToProps)(DashboardPage);
