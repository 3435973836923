import {
    IService,
    IServiceOverview,
    ServiceContent,
    ServicePreconditions,
    ServiceSection,
    RequirementsPayload,
} from 'types';

class ServiceFactory {
    static createInitialService = (id: string): IService => {
        const service = ServiceFactory.createEmptyService();
        service.id = id;
        return service;
    };

    static createEmptyService = (): IService => {
        return {
            id: '',
            name: '',
            description: '',
            imageUrl: '',
            serviceEmail: '',
            content: {
                headline: '',
                subline: '',
                headerImage: '',
                sections: [],
                preconditions: ServiceFactory.createEmptyPreconditions(),
            },
            instruction: {
                sections: [],
            },
            requirements: {
                markets: [],
            },
            subscribedUsersCount: 0,
            surveyQuestions: [],
            active: false,
            hidden: true,
            isInternal: false,
            maxSubscriptionCount: null,
            owners: [],
        };
    };

    static createEmptyBlock = (): ServiceSection => {
        return {
            title: '',
            contentType: 'IMAGE',
            contentUrl: '',
            content: '',
        };
    };

    static createEmptyPreconditions = (): ServicePreconditions => {
        return {
            vehiclePreconditions: [''],
            additionalPreconditions: [''],
            markets: [],
        };
    };

    static createEmptyServiceOverview = (): IServiceOverview => {
        return {
            id: '',
            name: '',
            description: '',
            serviceEmail: '',
            active: false,
            isInternal: false,
            hidden: true,
            subscribed: false,
            markets: [],
            subscribedUsersCount: 0,
            matchRequirements: false,
        };
    };

    static createEmptyServiceContent = (): ServiceContent => {
        return {
            headline: '',
            subline: '',
            headerImage: '',
            sections: [],
            preconditions: ServiceFactory.createEmptyPreconditions(),
        };
    };

    static createEmptyServiceRequirements = (): RequirementsPayload => {
        return {
            markets: [],
        };
    };
}

export default ServiceFactory;
