import React, { ChangeEvent, Fragment } from 'react';
import SectionBlock from './section-block';
import { ServiceContent, ServiceInstruction } from 'types';
import ServiceFactory from 'utils/service.factory';
import { FieldArrayRenderProps } from 'formik';

interface SectionArrayRenderProps {
    values: ServiceContent | ServiceInstruction;
    arrayHelpers: FieldArrayRenderProps;
    handleChange: {
        (e: ChangeEvent<any>): void;
        <T = unknown>(field: T): T extends ChangeEvent<any> ? void : (e: unknown) => void;
    };
    handleBlur: (event: React.FocusEvent<any>) => void;
    setFieldValue: (field: string, value: any) => void;
}

const SectionArrayRender: React.FC<SectionArrayRenderProps> = (props: SectionArrayRenderProps) => {
    const { values, arrayHelpers, handleChange, handleBlur, setFieldValue } = props;

    const showRemoveButton = values.sections.length > 1;

    const onAddClicked = () => {
        arrayHelpers.push(ServiceFactory.createEmptyBlock());
    };

    const onRemoveClicked = (index: number) => {
        arrayHelpers.remove(index);
    };

    return (
        <Fragment>
            {values.sections.map((block, index) => (
                <SectionBlock
                    key={index}
                    index={index}
                    block={block}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    onAddClicked={() => onAddClicked()}
                    onRemoveClicked={() => onRemoveClicked(index)}
                    showRemoveButton={showRemoveButton}
                    showAddButton={values.sections.length - 1 === index ? true : false}
                />
            ))}
        </Fragment>
    );
};
export default SectionArrayRender;
