import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import ServiceAPI from 'utils/api/services.api';
import { ImageUploadForm } from '../content/upload.form';
import Col from 'react-bootstrap/Col';
import ContentSection from 'components/blocks/content-section';
import { toastifyPromise } from '../../../../utils/toast-utils';

const ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Name should have more than 3 characters!')
        .required('Field is required'),
});

interface EditServiceTileFormProps {
    name: string;
    description: string;
    imageUrl?: string;
    serviceId: string;
    onChanged: () => void;
    windowWidth: number;
}

export default function EditServiceTileForm(props: EditServiceTileFormProps) {
    const initialValues = {
        name: props.name || '',
        description: props.description || '',
        imageUrl: props.imageUrl || '',
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) =>
                toastifyPromise(
                    ServiceAPI.updateService(props.serviceId, values),
                    'Failed to update service',
                    'Successfully saved!',
                )
                    .then(() => props.onChanged())
                    .finally(() => setSubmitting(false))
            }
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
            }) => (
                <Form onSubmit={handleSubmit}>
                    <ContentSection title={'Service Tile'}>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Service Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    {errors.name && touched.name && errors.name}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                    />
                                    {errors.description && touched.description && errors.description}
                                </Form.Group>

                                {props.windowWidth >= 770 && (
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        Save Tile
                                    </Button>
                                )}
                            </Col>

                            <Form.Control
                                hidden={true}
                                type="text"
                                name={`imageUrl`}
                                onChange={handleChange}
                                value={values.imageUrl}
                            />
                            <ImageUploadForm
                                setFieldValue={setFieldValue}
                                fieldName="imageUrl"
                                value={values.imageUrl}
                            />
                        </Form.Row>
                        {props.windowWidth < 770 && (
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                                style={{ marginTop: '10px' }}
                            >
                                Save Tile
                            </Button>
                        )}
                    </ContentSection>
                </Form>
            )}
        </Formik>
    );
}
