import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Accept, useDropzone } from 'react-dropzone';
import { Colors } from 'config/styling.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'react-bootstrap/Spinner';

const PreviewContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    background-size: cover;
    color: ${Colors.white};
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-bottom: 10px;
    height: 100%;
`;

const ThumbsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;

    height: 100%;
    width: 100%;
    background-color: rgba(188, 188, 188, 0.75);
    outline-offset: -10px;
    outline: 2px dashed ${Colors.white};
    padding: 10px;
`;

const Text = styled.p`
    padding: 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

interface DropzoneFile extends File {
    preview: string;
}

interface DropzoneProps {
    handleUpload: (files: File[]) => void;
    value?: string;
    loading: boolean;
    uploadComplete: boolean;
    error: boolean;
    accept: Accept;
    maxSizeMb: number | undefined;
    maxFiles: number | undefined;
    showPreview: boolean;
}

const Dropzone: React.FC<DropzoneProps> = props => {
    const [files, setFiles] = useState([] as DropzoneFile[]);
    const [fileToBig, setFileToBig] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        maxSize: props.maxSizeMb ? props.maxSizeMb * 1024 * 1024 : undefined,
        maxFiles: props.maxFiles,
        accept: props.accept,
        onDrop: acceptedFiles => {
            setFileToBig(false);
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
            props.handleUpload(acceptedFiles);
        },
        onDropRejected: () => {
            setFileToBig(true);
        },
    });

    const image = files.length > 0 ? files.map(file => file.preview) : props.value;

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files],
    );

    return (
        <PreviewContainer style={props.showPreview && image ? { backgroundImage: 'url(' + image + ')' } : {}}>
            <input {...getInputProps()} />
            <ThumbsContainer {...getRootProps({ className: 'dropzone' })}>
                <div>
                    {!props.uploadComplete && !fileToBig && !props.error && (
                        <FontAwesomeIcon icon="plus" color="white" size="4x" />
                    )}
                    {props.uploadComplete && <FontAwesomeIcon icon="check" color="white" size="4x" />}
                    {(fileToBig || props.error) && <FontAwesomeIcon icon="times" color="white" size="4x" />}
                    {!(fileToBig || props.error) && (
                        <Text>
                            Drag &apos;n&apos; drop some files here, or click to select files. <br />
                            {props.maxSizeMb && `Max file size: ${props.maxSizeMb} Mb`}
                        </Text>
                    )}
                    {props.loading && !fileToBig && (
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                    )}
                    {fileToBig && (
                        <Text>
                            File is to big
                            <br />
                            {props.maxSizeMb && `Max file size: ${props.maxSizeMb} Mb`}
                        </Text>
                    )}
                    {props.error && !fileToBig && <Text>Failed to Upload</Text>}
                </div>
            </ThumbsContainer>
        </PreviewContainer>
    );
};
export default Dropzone;
