import { ROLE_ADMIN, ROLE_SERVICE_OWNER } from 'utils/session.utils';
import packageJson from '../../package.json';

export const NAV_ITEMS = [
    { href: '/dashboard', name: 'Dashboard', role: '' },
    { href: '/services', name: 'Services', role: '' },
    { href: '/mailbox/services', name: 'Mailbox', role: ROLE_SERVICE_OWNER },
    { href: '/mailbox', name: 'Mailbox', role: ROLE_ADMIN },
    { href: '/marketing', name: 'Marketing', role: '' },
    { href: '/users', name: 'Users', role: ROLE_ADMIN },
];

export type BmwEnvironment = 'LOCAL' | 'INT' | 'PROD';
export const BMW_ENVIRONMENT = (process.env.REACT_APP_BMW_ENV as BmwEnvironment) || 'LOCAL';
export const APP_VERSION = packageJson.version;
