import React from 'react';
import { LoginContainer, Title } from '../../components/styled-components';
import Config from 'config/config';
import { generateAndSaveOAuthState } from 'utils/login.utils';
import { Button } from 'react-bootstrap';

export const LoginPage = () => {
    const AMBaseURL = Config.REACT_APP_WEN_AM_BASE_URL;
    const realmPath = Config.REACT_APP_WEN_REALM_PATH;
    const clientId = Config.REACT_APP_WEN_CLIENT_ID;
    const redirectUri = encodeURIComponent(Config.REACT_APP_WEN_REDIRECT_URI);

    const redirectToOAuth = () => {
        const { nonce, state } = generateAndSaveOAuthState();
        const oauthUrl = `${AMBaseURL}/oauth2/${realmPath}/authorize?client_id=${clientId}&state=${state}&scope=openid%20profile%20email%20groups&redirect_uri=${redirectUri}&response_type=code&nonce=${nonce}`;
        window.location.href = oauthUrl;
    };

    return (
        <LoginContainer>
            <Title>BMW Group Labs Admin Portal</Title>
            <h5
                style={{
                    marginBottom: '40px',
                }}
            >
                <b>Please login to continue</b>
            </h5>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1em' }}>
                <Button variant="primary" size={'lg'} style={{ width: '250px' }} onClick={redirectToOAuth}>
                    Login
                </Button>
            </div>
        </LoginContainer>
    );
};
