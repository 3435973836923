import React, { Component } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { IService, IServicePatch } from 'types';
import ServiceAPI from 'utils/api/services.api';
import { Spacing } from 'config/styling.constants';
import { toastifyPromise } from '../../../../utils/toast-utils';

const SectionContainer = styled.section`
    background-color: #f2f2f2;
    margin-top: ${Spacing.md};
    margin-bottom: ${Spacing.md};
`;

const LeftBlock = styled.div`
    margin: 0 0 10px;

    @media (min-width: 840px) {
        flex: 1;
    }
`;

const RightBlock = styled.div`
    margin: 0;
    @media (min-width: 840px) {
        flex: 2;
        margin: 0 0 0 40px;
    }
`;

const Row = styled.div`
    @media (min-width: 840px) {
        display: flex;
    }
`;

const MessageWrapper = styled.div`
    background-size: 266px 266px;
    padding: ${Spacing.md};
`;

interface EmailFormProps {
    serviceId: string;
}

class EmailForm extends Component<EmailFormProps> {
    initialValues: IServicePatch = {
        serviceEmail: '',
        name: '',
        description: '',
        imageUrl: '',
    };

    constructor(props: EmailFormProps) {
        super(props);
        this.fetchState();
    }

    fetchState() {
        ServiceAPI.getServiceDetails(this.props.serviceId).then((serviceDetails: IService) => {
            this.initialValues.serviceEmail = serviceDetails.serviceEmail;
            this.initialValues.name = serviceDetails.name;
            this.initialValues.description = serviceDetails.description;
            this.initialValues.imageUrl = serviceDetails.imageUrl;
        });
    }

    render() {
        const InitialValues: IServicePatch = this.initialValues;

        const isValidEmail = email => {
            return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
        };
        return (
            <SectionContainer>
                <MessageWrapper>
                    <Formik
                        enableReinitialize={true}
                        initialValues={InitialValues}
                        onSubmit={(values, { setSubmitting }) => {
                            if (isValidEmail(values.serviceEmail)) {
                                toastifyPromise(
                                    ServiceAPI.updateService(this.props.serviceId, values),
                                    'Failed to save email Address',
                                    'Email Address saved successfully',
                                )
                                    .then()
                                    .finally(() => {
                                        this.fetchState();
                                        setSubmitting(false);
                                    });
                            } else {
                                toast.error('Please enter an valid Email Address !');
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <LeftBlock>
                                        <h3>Service Email Address</h3>
                                        <p>
                                            Enter Email Address to which feedback and subscription messages are
                                            intimated
                                        </p>
                                    </LeftBlock>
                                    <RightBlock>
                                        <Form.Group>
                                            <Form.Label>Email Address*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="serviceEmail"
                                                placeholder="Email Address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.serviceEmail}
                                            />
                                        </Form.Group>

                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={isSubmitting || !values.serviceEmail}
                                        >
                                            Save Address
                                        </Button>
                                    </RightBlock>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </MessageWrapper>
            </SectionContainer>
        );
    }
}

export default EmailForm;
