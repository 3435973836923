import React, { useEffect, useState } from 'react';
import LocalStorageService from '../../utils/LocalStorageService';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { assignRoleAction } from '../../redux/session/session.actions';
import { LoginContainer, Title } from 'components/styled-components';
import { DefaultSelectStyles } from 'components/dropdown';
import { nameForRole } from 'utils/session.utils';

const AuthoritySelector = () => {
    const [authorities, setAuthorities] = useState([]);
    const [selectedAuthority, setSelectedAuthority] = useState();

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const storedRoles = LocalStorageService.getUserAuthorizedRoles() || [];
        if (typeof storedRoles === 'string') {
            setAuthorities(JSON.parse(storedRoles));
        }
    }, []);

    const handleSelectChange = ({ value }) => {
        setSelectedAuthority(value);
    };

    const handleSave = () => {
        if (selectedAuthority) {
            LocalStorageService.saveRole(selectedAuthority);
            dispatch(assignRoleAction.complete(selectedAuthority));
            history.push('/dashboard');
        }
    };

    return (
        <LoginContainer>
            <Title>BMW Group Labs Admin Portal</Title>
            <div style={{ marginBottom: '40px', marginTop: '40px' }}>
                <h2>Choose your login role:</h2>
                <Select
                    styles={DefaultSelectStyles}
                    onChange={handleSelectChange}
                    value={selectedAuthority && { value: selectedAuthority, label: nameForRole(selectedAuthority) }}
                    isMulti={false}
                    isSearchable={false}
                    options={authorities.map(authority => {
                        return { value: authority, label: nameForRole(authority) };
                    })}
                />
            </div>
            <Button
                variant="primary"
                size={'lg'}
                style={{ width: '250px' }}
                onClick={handleSave}
                disabled={!selectedAuthority}
            >
                Continue
            </Button>
        </LoginContainer>
    );
};

export default AuthoritySelector;
