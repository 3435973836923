import React from 'react';
import styled from 'styled-components';
import { Colors, Typography } from 'config/styling.constants';
import PieChart from '../../../../components/piechart';
import { GridElement } from 'components/styled-components';
import { AnalyticsEntry } from 'types';

const Tile = styled(GridElement)`
    background-color: ${Colors.white};
    min-height: 300px;
    position: relative;
`;

const Label = styled.label`
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.headingLevelTwoFontSize};
    padding: 20px;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    width: 100%;
    height: 70%;
    position: absolute;
`;

const Default = styled.label`
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.headingLevelTwoFontSize};
    color: ${Colors.darkGrey};
    padding: 110px;
`;

interface PieTileProps {
    name: string;
    items: AnalyticsEntry[];
    showLegend?: boolean;
}

const PieTile: React.FC<PieTileProps> = ({ name, items, showLegend = true }) => {
    return (
        <Tile>
            <Label>{name}</Label>
            <Content>
                {items.length !== 0 ? (
                    <PieChart items={items} showLegend={showLegend} />
                ) : (
                    <Default>No Users Subscribed</Default>
                )}
            </Content>
        </Tile>
    );
};

export default PieTile;
