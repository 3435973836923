import React from 'react';
import styled from 'styled-components';
import { Colors, Typography } from 'config/styling.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridElement } from 'components/styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const Tile = styled(GridElement)`
    background-color: ${Colors.white};
    position: relative;
    min-height: 300px;
`;

const Label = styled.label`
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.headingLevelTwoFontSize};
    padding: 20px;
`;

const ContentBox = styled.div`
    text-align: bottom;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60%;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Count = styled.div`
    font-family: ${Typography.boldFontFamily};
    font-size: 70px;
    margin-right: 10px;
    display: inline;
`;

const Icon = styled.div`
    margin-left: 10px;
    font-size: 40px;
`;

interface CountTileProps {
    name: string;
    count: number;
    icon?: IconProp;
}

const CountTile: React.FC<CountTileProps> = ({ name, count, icon }) => {
    return (
        <Tile>
            <Label>{name}</Label>
            <ContentBox>
                <Content>
                    <Count>{count}</Count>
                    {icon && (
                        <Icon>
                            <FontAwesomeIcon icon={icon} />
                        </Icon>
                    )}
                </Content>
            </ContentBox>
        </Tile>
    );
};

export default CountTile;
