import React, { useEffect, useState } from 'react';
import { RootStore } from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IManager } from '../../types/manager.types';
import UserManagementTable, { UserManagementTableColumn } from '../../components/table/user-management-table';
import { loadServiceOwners } from '../../redux/usersManagement/service-owners.actions';
import { toastifyPromise } from '../../utils/toast-utils';
import ManagersApi from '../../utils/api/managers.api';
import ServiceUtils from 'utils/service.utils';
import { StyledLink } from 'components/styled-components';

interface ServiceOwnersTableProps {
    serviceOwners: IManager[] | undefined;
    error: string | null;
    loadServiceOwners: () => void;
}

const ServiceOwnersTable: React.FC<ServiceOwnersTableProps> = ({ error, loadServiceOwners, serviceOwners }) => {
    const paginationOptions = [25, 50, 75, 100];
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const deleteServiceOwners = async (selectedIds: string[]): Promise<void> => {
        if (!selectedIds || selectedIds.length === 0) {
            return;
        }

        try {
            await toastifyPromise(
                ManagersApi.deleteManagers(selectedIds),
                'Failed to delete service owners',
                'Service owners deleted',
            );
            loadServiceOwners();
        } catch (error) {
            console.error('Error deleting service owners:', error);
        }
    };

    useEffect(() => {
        setErrorMessage(error ? 'Failed to fetch service owners' : null);
    }, [error]);

    useEffect(() => {
        loadServiceOwners();
    }, [loadServiceOwners]);

    const columns: UserManagementTableColumn<IManager>[] = [
        {
            title: 'Username',
            value: item => item.qNumber,
            matchesQuery: (query, item) => item.qNumber.toLowerCase().includes(query.toLowerCase()),
        },
        {
            title: 'E-mail',
            value: item => item.email,
            matchesQuery: (query, item) => item.email.toLowerCase().includes(query.toLowerCase()),
        },
        {
            title: 'Last active',
            value: item => new Date(item.lastActive).toLocaleString(),
        },
        {
            title: 'Owned services',
            // eslint-disable-next-line react/display-name
            value: item => {
                const services = item.ownedServices;
                if (!services) {
                    return '';
                }
                const links = services.map((service, index) => (
                    <React.Fragment key={service.id}>
                        <StyledLink href={ServiceUtils.deepLinkForService(service)}>{service.name}</StyledLink>
                        {index < services.length - 1 && ', '}
                    </React.Fragment>
                ));

                return <>{links}</>;
            },
            matchesQuery: (query, item) => {
                const services = item.ownedServices;
                if (!services) {
                    return false;
                }
                return services.some(service => service.name.toLowerCase().includes(query.toLowerCase()));
            },
        },
    ];

    return (
        <UserManagementTable
            columns={columns}
            paginationOptions={paginationOptions}
            rows={serviceOwners}
            error={errorMessage}
            getIdentifier={item => item.qNumber}
            onDelete={deleteServiceOwners}
        />
    );
};

const mapStateToProps = (state: RootStore) => ({
    error: state.serviceOwnersManagement.error,
    serviceOwners: state.serviceOwnersManagement.managers,
});

const actionCreators = { loadServiceOwners };
const mapDispatchToProps = dispatch => {
    return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOwnersTable);
