import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { IService, RootStore } from 'types';
import api from 'utils/api/services.api';
import { AnyAction } from 'redux';
import MarketUtils from 'utils/markets.utils';

function _loadServiceDetailsThunk(serviceId: string) {
    return async (dispatch: ThunkDispatch<RootStore, {}, AnyAction>) => {
        dispatch(loadServiceDetails.next());

        try {
            const service = await api.getServiceDetails(serviceId);
            service.content.preconditions.markets.sort((a, b) => MarketUtils.helperSortIServiceMarkets(a, b));
            service.content.preconditions.markets.map(item => item.cities.sort());

            dispatch(loadServiceDetails.complete(service));
        } catch (error) {
            dispatch(loadServiceDetails.error(JSON.stringify(error)));
        }
    };
}

export const loadServiceDetails = Object.assign(_loadServiceDetailsThunk, {
    next: createActionCreator('FETCH_SERVICE_DETAILS_NEXT'),
    complete: createActionCreator('FETCH_SERVICE_DETAILS_COMPLETE', resolve => (service: IService) => resolve(service)),
    error: createActionCreator('FETCH_SERVICE_DETAILS_ERROR', resolve => (error: string) => resolve(error)),
});
