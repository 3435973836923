import React from 'react';
import styled from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';
import { IConversationOverview } from 'types';

interface MessageContainerProps {
    selected?: boolean;
    read?: boolean;
}

const MessageContainer = styled.div<MessageContainerProps>`
	background-color: ${Colors.white};
	padding: ${Spacing.sm};
	${props => props.selected && `background-color: ${Colors.lightGrey};`}
	${props => !props.read && `border-left: solid 4px ${Colors.cyan};`}
  ${props => !props.read && `font-weight: bold;`}
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
	margin-bottom: ${Spacing.xs};
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const TextItem = styled.div<MessageContainerProps>`
    color: ${Colors.black};
    font-size: ${Typography.baseFontSize};
    margin: auto 0;
    flex: 1;
`;
const Name = styled(TextItem)`
    text-align: left;
`;

const Time = styled(TextItem)`
    text-align: right;
`;

const Body = styled(TextItem)`
    text-align: center;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export interface DashboardMessageItemProps extends MessageContainerProps {
    messageItem: IConversationOverview;
    onClick: (item: IConversationOverview) => void;
}

const DashboardMessageItem: React.FC<DashboardMessageItemProps> = ({ selected, messageItem, onClick }) => {
    const createdDate = messageItem.lastMessageAt ? new Date(messageItem.lastMessageAt) : undefined;

    return (
        <MessageContainer
            selected={selected}
            onClick={() => onClick(messageItem)}
            read={!messageItem.hasUnreadMessages}
        >
            <Name>{messageItem.serviceName || 'General feedback'}</Name>
            <Body>{messageItem.userMail}</Body>
            {createdDate && <Time>{createdDate.toLocaleString()}</Time>}
        </MessageContainer>
    );
};
export default DashboardMessageItem;
