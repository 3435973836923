import React, { useEffect, useState } from 'react';
import { IUser, RootStore } from '../../types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserManagementTable, { UserManagementTableColumn } from '../../components/table/user-management-table';
import { loadUsers } from '../../redux/usersManagement/users.actions';
import { toastifyPromise } from '../../utils/toast-utils';
import UserAPI from '../../utils/api/users.api';
import ServiceUtils from 'utils/service.utils';
import { StyledLink } from 'components/styled-components';

interface UserTableProps {
    users: IUser[] | undefined;
    error: string | null;
    loadUsers: () => void;
}

const UserTable: React.FC<UserTableProps> = ({ error, loadUsers, users }) => {
    const paginationOptions = [250, 500, 750, 1000];
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const deleteUsers = async (selectedIds: string[]): Promise<void> => {
        if (!selectedIds || selectedIds.length === 0) {
            return;
        }

        try {
            await toastifyPromise(UserAPI.deleteUsers(selectedIds), 'Failed to delete users', 'Users deleted');
            loadUsers();
        } catch (error) {
            console.error('Error deleting users:', error);
        }
    };

    useEffect(() => {
        setErrorMessage(error ? 'Failed to fetch users' : null);
    }, [error]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const columns: UserManagementTableColumn<IUser>[] = [
        {
            title: 'E-mail',
            value: item => item.email,
            matchesQuery: (query, item) => item.email.toLowerCase().includes(query.toLowerCase()),
        },
        {
            title: 'GCID',
            value: item => item.gcid || '',
            matchesQuery: (query, item) => (item.gcid ? item.gcid.toLowerCase().includes(query.toLowerCase()) : false),
        },
        { title: 'Last active', value: item => new Date(item.lastActive).toLocaleString() },
        { title: 'Created at', value: item => new Date(item.createdAt).toLocaleString() },
        {
            title: 'Subscribed services',
            // eslint-disable-next-line react/display-name
            value: item => {
                const services = item.subscribedServices;
                if (!services) {
                    return '';
                }
                const links = services.map((service, index) => (
                    <React.Fragment key={service.id}>
                        <StyledLink href={ServiceUtils.deepLinkForService(service)}>{service.name}</StyledLink>
                        {index < services.length - 1 && ', '}
                    </React.Fragment>
                ));

                return <>{links}</>;
            },
            matchesQuery: (query, item) => {
                const services = item.subscribedServices;
                if (!services) {
                    return false;
                }
                return services.some(service => service.name.toLowerCase().includes(query.toLowerCase()));
            },
        },
    ];

    return (
        <UserManagementTable
            columns={columns}
            paginationOptions={paginationOptions}
            rows={users}
            error={errorMessage}
            getIdentifier={item => item.gcid}
            onDelete={deleteUsers}
        />
    );
};

const mapStateToProps = (state: RootStore) => ({
    error: state.usersManagement.error,
    users: state.usersManagement.users,
});

const actionCreators = { loadUsers };
const mapDispatchToProps = dispatch => {
    return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
