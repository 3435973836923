import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { loginAction } from 'redux/session/session.actions';
import { RootState } from '../../redux/store';
import { getAndRemoveOAuthState } from '../../utils/login.utils';

interface AuthResponse {
    success: boolean;
    data?: any;
    error?: string;
}

const useWENRedirect = () => {
    const [authResponse, setAuthResponse] = useState<AuthResponse | null>(null);
    const location = useLocation();
    const history = useHistory();
    const dispatch: ThunkDispatch<RootState, {}, AnyAction> = useDispatch();

    useEffect(() => {
        const fetchAuthToken = async (authCode: string, nonce: string) => {
            try {
                const action = await dispatch(loginAction(authCode, nonce));
                if (action.type === 'FETCH_LOGIN_REQUEST_COMPLETE') {
                    setAuthResponse({ success: true, data: action.payload });
                    history.replace('/dashboard');
                } else {
                    setAuthResponse({ success: false, error: 'Login failed' });
                    history.replace('/login');
                    toast.error('Login failed');
                }
            } catch (error) {
                setAuthResponse({ success: false, error: error.message });
                history.replace('/login');
                toast.error(`Login failed: ${error.message}`);
            }
        };

        const params = new URLSearchParams(location.search);
        const authCode = params.get('code');
        const receivedState = params.get('state');
        const { nonce, state: localState } = getAndRemoveOAuthState();
        if (!authCode || !receivedState) {
            history.replace('/');
            return;
        }
        if (receivedState !== localState) {
            toast.error(`Login failed: State mismatch. Please try again.`);
            history.replace('/login');
            return;
        }
        if (authCode && nonce) {
            fetchAuthToken(authCode, nonce);
        } else {
            setAuthResponse({ success: false, error: 'Missing auth code or nonce' });
        }
    }, [location.search, dispatch, history]);

    return authResponse;
};

export default useWENRedirect;
