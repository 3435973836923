import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { LoadServicesPayload, RootStore } from 'types';
import api from 'utils/api/services.api';
import { AnyAction } from 'redux';
import MarketUtils from 'utils/markets.utils';

function _loadServicesThunk() {
    return async (dispatch: ThunkDispatch<RootStore, {}, AnyAction>) => {
        dispatch(loadServices.next());

        try {
            const services = await api.getServices();
            services.map(service =>
                service.markets ? service.markets.sort((a, b) => MarketUtils.helperSortMarkets(a, b)) : service.markets,
            );

            dispatch(loadServices.complete({ receivedAt: Date.now(), services: services }));
        } catch (error) {
            dispatch(loadServices.error(JSON.stringify(error)));
        }
    };
}

export const loadServices = Object.assign(_loadServicesThunk, {
    next: createActionCreator('LOAD_SERVICES_NEXT'),
    complete: createActionCreator('LOAD_SERVICES_COMPLETE', resolve => (servicesAction: LoadServicesPayload) =>
        resolve(servicesAction),
    ),
    error: createActionCreator('LOAD_SERVICES_ERROR', resolve => (error: string) => resolve(error)),
});
