import React, { Component } from 'react';
import { FieldArray, Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ServiceFactory from 'utils/service.factory';
import SectionArrayRender from './content/section-array-render';
import ServiceAPI from 'utils/api/services.api';
import PreviewButton from './content/service.preview.modal';
import { ServiceInstruction } from 'types';
import ServiceUtils from 'utils/service.utils';
import { toastifyPromise } from '../../../utils/toast-utils';

interface InstructionFormProps {
    serviceId: string;
    initialValues: ServiceInstruction;
    onSubmit: () => void;
}

export default class InstructionForm extends Component<InstructionFormProps> {
    getInitialValues() {
        // initialize sections with one element if array is empty
        const sections =
            this.props.initialValues.sections.length > 0
                ? this.props.initialValues.sections.map(ServiceUtils.toEditableSection)
                : [ServiceFactory.createEmptyBlock()];

        return {
            sections: sections || [],
        };
    }

    render() {
        return (
            <Formik
                initialValues={this.getInitialValues()}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    const normalizedValues = ServiceUtils.normalizeContent(values);

                    toastifyPromise(
                        ServiceAPI.updateInstruction(this.props.serviceId, normalizedValues),
                        'Failed to update content',
                        'Successfully saved',
                    )
                        .then(() => this.props.onSubmit())
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <h3>Edit Service Instructions</h3>
                        <FieldArray
                            name="sections"
                            render={arrayHelpers => (
                                <SectionArrayRender
                                    values={values}
                                    arrayHelpers={arrayHelpers}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        />
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            )}
                            Save Content
                        </Button>
                        <PreviewButton serviceContent={values} />
                    </Form>
                )}
            </Formik>
        );
    }
}
