import React from 'react';

export interface PrettyPrintJsonProps {
    data: object;
}

const PrettyPrintJson: React.FC<PrettyPrintJsonProps> = ({ data }) => (
    <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
);

export default PrettyPrintJson;
