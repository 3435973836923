import {
    USER_SESSION_ATTRIBUTE_NAME,
    USER_SESSION_ATTRIBUTE_ASSUMED_ROLE,
    USER_SESSION_ATTRIBUTE_TOKEN,
} from 'utils/LocalStorageService';
import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import { assignRoleAction, loginAction, logoutAction } from './session.actions';

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(loginAction.complete, () => defaultErrorState),
    handleAction(loginAction.error, (_, { payload }) => payload),
    handleAction(logoutAction.complete, () => defaultErrorState),
]);

export const defaultUsernameState: string = localStorage.getItem(USER_SESSION_ATTRIBUTE_NAME) || '';
export const usernameReducer = createReducer(defaultUsernameState, handleAction => [
    handleAction(loginAction.complete, (_, { payload }) => payload.username),
    handleAction(loginAction.error, () => ''),
    handleAction(logoutAction.complete, () => ''),
]);

export const defaultTokenState: string = localStorage.getItem(USER_SESSION_ATTRIBUTE_TOKEN) || '';
export const tokenReducer = createReducer(defaultTokenState, handleAction => [
    handleAction(loginAction.complete, (_, { payload }) => payload.token),
    handleAction(loginAction.error, () => ''),
    handleAction(logoutAction.complete, () => ''),
]);

export const defaultRoleState: string = localStorage.getItem(USER_SESSION_ATTRIBUTE_ASSUMED_ROLE) || '';
export const roleReducer = createReducer(defaultRoleState, handleAction => [
    handleAction(loginAction.complete, (_, { payload }) => {
        return payload.roles.length === 1 ? payload.roles[0] : '';
    }),
    handleAction(assignRoleAction.complete, (_, { payload }) => {
        return payload.role;
    }),
    handleAction(loginAction.error, () => ''),
    handleAction(logoutAction.complete, () => ''),
]);

export type ISessionStore = ReturnType<typeof sessionReducer>;
export const sessionReducer = combineReducers({
    error: errorReducer,
    username: usernameReducer,
    token: tokenReducer,
    role: roleReducer,
});
