import React from 'react';
import styled from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';
import { IConversationOverview } from 'types';

interface ConversationsContainerProps {
    selected?: boolean;
    read?: boolean;
}

const ConversationsContainer = styled.div<ConversationsContainerProps>`
    background-color: ${Colors.white};
    padding: ${Spacing.xs};
    ${props => props.selected && `background-color: ${Colors.transparentBlue};`}
    ${props => !props.read && `border-left: solid 4px ${Colors.cyan};`}
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: ${Spacing.xs};
    margin-right: 5px;
    cursor: pointer;
`;

const TimeSpan = styled.span`
    color: ${Colors.darkGrey};
    font-size: 13px;
`;

const SubTitle = styled.p`
    font-size: ${Typography.baseFontSize};
    font-family: ${Typography.baseFontFamily};
    margin: 0;
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export interface ChatConversationItemProps extends ConversationsContainerProps {
    messageItem: IConversationOverview;
    onClick: (item: IConversationOverview) => void;
}

const ChatConversationItem: React.FC<ChatConversationItemProps> = ({ selected, messageItem, onClick }) => {
    const createdDate = messageItem.lastMessageAt ? new Date(messageItem.lastMessageAt) : undefined;

    return (
        <ConversationsContainer
            selected={selected}
            read={!messageItem.hasUnreadMessages}
            onClick={() => onClick(messageItem)}
        >
            {createdDate && <TimeSpan>{createdDate.toLocaleString()}</TimeSpan>}
            {messageItem.userMail && (
                <>
                    <h5>{messageItem.userMail}</h5>
                    <SubTitle>{messageItem.serviceName}</SubTitle>
                </>
            )}
            {!messageItem.userMail && <h5>{messageItem.serviceName}</h5>}
        </ConversationsContainer>
    );
};

export default ChatConversationItem;
