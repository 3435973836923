import React from 'react';
import styled, { css } from 'styled-components';
import DefaultImage from 'assets/images/Bild_2.jpg';
import { Colors, Spacing } from 'config/styling.constants';
import { ServiceSectionContentType } from 'types';

export interface ShowcaseBlockProps {
    readonly swap?: boolean;
    readonly greyBackground?: boolean;
    readonly children?: any;
    readonly contentType?: ServiceSectionContentType;
    readonly contentUrl?: string;
}

const Section = styled.section`
    max-width: 1024px;

    margin-top: ${Spacing.md};
`;

const BlockContent = styled.section`
    @media (min-width: 768px) {
        display: flex;
    }
`;

const ImageBox = styled.div`
    background-color: ${Colors.lightGrey};
    @media (min-width: 768px) {
        flex: 2;
    }
`;

const Image = styled.img`
    width: 100%;
    max-height: 315px;
    @media (min-width: 640px) {
        max-height: 100%;
        object-fit: cover;
    }
`;

const Video = styled.iframe`
    width: 100%;
    height: 100%;
    min-height: 315px;
`;

const BlockInfo = styled.div<ShowcaseBlockProps>`
    padding: ${Spacing.md};
    ${props =>
        props.greyBackground &&
        css`
            background-color: ${Colors.lightGrey};
        `}
    ${props =>
        props.swap &&
        css`
            order: -1;
        `}
	flex: 1;
`;

const ServiceShowcaseSection = ({ children, swap, greyBackground, contentType, contentUrl }: ShowcaseBlockProps) => {
    return (
        <Section>
            <BlockContent>
                {contentType !== 'CUSTOM' && (
                    <ImageBox>
                        {contentUrl ? (
                            <>
                                {contentType === 'IMAGE' && <Image src={contentUrl} alt={contentUrl} />}
                                {contentType === 'VIDEO' && (
                                    <Video src={contentUrl} allow="autoplay; encrypted-media" allowFullScreen />
                                )}
                            </>
                        ) : (
                            <Image src={DefaultImage} alt="default" />
                        )}
                    </ImageBox>
                )}
                <BlockInfo swap={swap} greyBackground={greyBackground}>
                    {contentType === 'CUSTOM' ? (
                        <>
                            <h2>Custom content</h2>
                            <a href={contentUrl} target={'_blank'} rel="noopener noreferrer">
                                {contentUrl}
                            </a>
                        </>
                    ) : (
                        children
                    )}
                </BlockInfo>
            </BlockContent>
        </Section>
    );
};

export default ServiceShowcaseSection;
