import React, { ChangeEvent } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import ContentSection from 'components/blocks/content-section';
import { ArchiveUploadForm, ImageUploadForm } from './upload.form';
import BlockButtons from './section-buttons';
import styled from 'styled-components';
import { EditableServiceSection } from 'types';
import { AddLinkInfo } from 'components/styled-components';
import { Tab, Tabs } from 'react-bootstrap';
import { ArchiveUploadResult } from 'types/service.api.types';
import { DefaultSelectStyles } from 'components/dropdown';
import { BMW_ENVIRONMENT } from 'config/constants';

const VideoContainer = styled(Form.Group)`
    width: 100%;
    padding: 0 10px;
    @media (min-width: 640px) {
        width: 50%;
    }
`;

interface SectionBlockProps {
    block: EditableServiceSection;
    index: number;
    handleChange: {
        (e: ChangeEvent<any>): void;
        <T = unknown>(field: T): T extends ChangeEvent<any> ? void : (e: unknown) => void;
    };
    handleBlur: (event: React.FocusEvent<any>) => void;
    setFieldValue: (field: string, value: any) => void;
    onAddClicked: () => void;
    onRemoveClicked: () => void;
    showRemoveButton: boolean;
    showAddButton: boolean;
}

interface CustomContentTabProps {
    block: EditableServiceSection;
    index: number;
    setFieldValue: (field: string, value: any) => void;
    handleChange: {
        (e: ChangeEvent<any>): void;
        <T = unknown>(field: T): T extends ChangeEvent<any> ? void : (e: unknown) => void;
    };
    handleBlur: (event: React.FocusEvent<any>) => void;
}

const SectionBlock: React.FC<SectionBlockProps> = ({
    block,
    index,
    handleChange,
    handleBlur,
    setFieldValue,
    onAddClicked,
    onRemoveClicked,
    showRemoveButton,
    showAddButton,
}) => {
    const sectionsButtons = (
        <BlockButtons
            onAddClicked={onAddClicked}
            onRemoveClicked={onRemoveClicked}
            showRemoveButton={showRemoveButton}
            showAddButton={showAddButton}
        />
    );

    const onTabSelect = (eventKey: string) => {
        setFieldValue(`sections.${index}.contentType`, eventKey);
    };

    return (
        <ContentSection title={`Section Block ${index + 1}`} buttons={sectionsButtons}>
            <Tabs activeKey={block.contentType} id={String(block.id)} onSelect={onTabSelect}>
                <Tab eventKey="IMAGE" title="Text & Image">
                    <AddLinkInfo>
                        INFO: if you would like to use links, insert them within &lt; &gt; symbols in the content
                        section.
                    </AddLinkInfo>

                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Headline</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`sections.${index}.title`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={block.title || ''}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Content</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name={`sections.${index}.content`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={block.content || ''}
                                />
                            </Form.Group>
                        </Col>

                        <ImageUploadForm
                            setFieldValue={setFieldValue}
                            fieldName={`sections.${index}.imageUrl`}
                            value={block.imageUrl || ''}
                        />
                    </Form.Row>
                </Tab>
                <Tab eventKey="VIDEO" title="Text & Video">
                    <AddLinkInfo>
                        INFO: if you would like to use links, insert them within &lt; &gt; symbols in the content
                        section.
                    </AddLinkInfo>

                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Headline</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`sections.${index}.title`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={block.title || ''}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Content</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name={`sections.${index}.content`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={block.content || ''}
                                />
                            </Form.Group>
                        </Col>

                        <VideoContainer>
                            <Form.Label>YouTube Video</Form.Label>
                            <Form.Control
                                type="text"
                                name={`sections.${index}.videoUrl`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={block.videoUrl || ''}
                            />
                        </VideoContainer>
                    </Form.Row>
                </Tab>
                {BMW_ENVIRONMENT !== 'PROD' && (
                    <Tab eventKey="CUSTOM" title="Custom web content">
                        <CustomContentTab
                            block={block}
                            index={index}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                        />
                    </Tab>
                )}
            </Tabs>
        </ContentSection>
    );
};

function CustomContentTab({ index, block, setFieldValue, handleChange, handleBlur }: CustomContentTabProps) {
    const handleUploadResult = (_, uploadResult: ArchiveUploadResult) => {
        if (uploadResult.entrypoints.length === 0) {
            setFieldValue(`sections.${index}.customContent.uploadResult`, undefined);
            return;
        }
        setFieldValue(`sections.${index}.customContent.uploadResult`, uploadResult);
        const indexEntrypoints = uploadResult.entrypoints
            .filter(file => file.endsWith('index.html'))
            .sort((a, b) => a.length - b.length);
        const entrypoint = indexEntrypoints.length === 0 ? uploadResult.entrypoints[0] : indexEntrypoints[0];
        setFieldValue(`sections.${index}.customContent.entrypoint`, entrypoint);
    };

    const onSelectChange = ({ value }) => {
        setFieldValue(`sections.${index}.customContent.entrypoint`, value);
    };

    return (
        <Form.Row>
            <Col>
                {block.customContent && (
                    <Form.Group>
                        {!block.customContent.uploadResult && block.customContent.url && (
                            <>
                                <Form.Label>Existing content</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`sections.${index}.customContent.url`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={block.customContent.url}
                                    readOnly
                                />
                            </>
                        )}
                        {block.customContent.uploadResult && (
                            <>
                                <Form.Label>Content entrypoint</Form.Label>
                                <Select
                                    styles={DefaultSelectStyles}
                                    onChange={onSelectChange}
                                    value={
                                        block.customContent.entrypoint && {
                                            value: block.customContent.entrypoint,
                                            label: block.customContent.entrypoint,
                                        }
                                    }
                                    isMulti={false}
                                    isDisabled={block.customContent.uploadResult.entrypoints.length === 1}
                                    options={block.customContent.uploadResult.entrypoints.map(entrypoint => {
                                        return { value: entrypoint, label: entrypoint };
                                    })}
                                />
                            </>
                        )}
                    </Form.Group>
                )}
            </Col>

            <ArchiveUploadForm
                fieldName={`sections.${index}.customContent.uploadResult`}
                setFieldValue={handleUploadResult}
            />
        </Form.Row>
    );
}

export default SectionBlock;
