import { createReducer } from 'deox';
import { combineReducers } from 'redux';
import { fetchConversations, fetchMessages } from './mailbox.actions';
import { logoutAction } from 'redux/session/session.actions';
import { IConversation, IConversationOverview } from 'types';

export const defaultConversationsState: IConversationOverview[] = [];
export const conversationReducer = createReducer(defaultConversationsState, handleAction => [
    handleAction(fetchConversations.complete, (_, { payload }) => payload),
    handleAction(fetchConversations.error, state => state),
    handleAction(logoutAction.complete, () => defaultConversationsState),
]);

export const defaultMessagesState: IConversation = {
    id: '',
    userId: '',
    userMail: '',
    messages: [],
};
export const messagesReducer = createReducer(defaultMessagesState, handleAction => [
    handleAction(fetchMessages.complete, (_, { payload }) => payload),
    handleAction(fetchMessages.error, state => state),
    handleAction(logoutAction.complete, () => defaultMessagesState),
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(fetchConversations.complete, () => defaultErrorState),
    handleAction(fetchConversations.error, (_, { payload }) => payload),
]);

export const defaultIsFetchingState = false;
export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
    handleAction(fetchMessages.next, () => true),
    handleAction([fetchMessages.complete, fetchMessages.error], () => false),
]);

export const mailboxReducer = combineReducers({
    conversations: conversationReducer,
    conversationMessages: messagesReducer,
    error: errorReducer,
    isFetchingMessages: isFetchingReducer,
});

export type MailboxState = ReturnType<typeof mailboxReducer>;
