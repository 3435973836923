import { createActionCreator } from 'deox';
import { RootStore } from 'types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import managersApi from '../../utils/api/managers.api';
import { IManager } from '../../types/manager.types';

function _loadAdminsThunk() {
    return async (dispatch: ThunkDispatch<RootStore, {}, AnyAction>) => {
        dispatch(loadAdmins.next());

        try {
            const admins = await managersApi.getAdmins();

            dispatch(loadAdmins.complete(admins));
        } catch (error) {
            dispatch(loadAdmins.error(error));
        }
    };
}

export const loadAdmins = Object.assign(_loadAdminsThunk, {
    next: createActionCreator('LOAD_ADMINS_NEXT'),
    complete: createActionCreator('LOAD_ADMINS_COMPLETE', resolve => (admins: IManager[]) => resolve(admins)),
    error: createActionCreator('LOAD_ADMINS_ERROR', resolve => (error: string) => resolve(error)),
});
