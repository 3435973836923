import { createReducer } from 'deox';
import { combineReducers } from 'redux';
import { loadAdmins } from './admins.actions';
import { IManager } from '../../types/manager.types';

const defaultIsLoadingState = false;
export const isLoadingReducer = createReducer(defaultIsLoadingState, handleAction => [
    handleAction(loadAdmins.next, () => true),
    handleAction([loadAdmins.complete, loadAdmins.error], () => false),
]);

const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(loadAdmins.complete, () => defaultErrorState),
    handleAction(loadAdmins.error, (_, { payload }) => payload),
]);

const defaultAdminsState: IManager[] = [];
export const adminsReducer = createReducer(defaultAdminsState, handleAction => [
    handleAction(loadAdmins.complete, (_, { payload }) => payload),
    handleAction(loadAdmins.error, status => status),
]);

export const adminsManagementReducer = combineReducers({
    isLoading: isLoadingReducer,
    error: errorReducer,
    managers: adminsReducer,
});

export type IAdminsStore = ReturnType<typeof adminsManagementReducer>;
