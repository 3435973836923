import React, { Component } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import MarketingAPI from 'utils/api/marketing.api';
import { ImageUploadForm } from '../services/details/content/upload.form';
import Col from 'react-bootstrap/Col';
import { toastifyPromise } from '../../utils/toast-utils';
import { IServiceOverview, MarketingEntry, RootStore } from 'types';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { selectServicesItems } from 'redux/servicesOverview/services-overview.selector';
import { loadServices } from 'redux/servicesOverview/services-overview.actions';
import InputSelectionBox from 'components/inputs/inputSelectionBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MarketingFormValues {
    id?: number;
    headline: string;
    description: string;
    imageUrl: string;
    serviceId: string | null;
    isServiceRequirements: boolean;
}

interface CreateMarketingFormProps {
    onCreated: () => void;
    services: IServiceOverview[];
    loadServices: () => void;
    buttonLabel: string;
    entry?: MarketingEntry;
}

interface CreateMarketingState {
    isServiceRequirements: boolean;
    selectedServiceId: string | null;
}

const minLengthHeadline = 3;

const Section = styled.section`
    background-color: #f2f2f2;
    padding: 1em;
    margin-bottom: 1em;
    margin-top: 1em;
`;

class CreateMarketingForm extends Component<CreateMarketingFormProps, CreateMarketingState> {
    constructor(props) {
        super(props);
        this.state = {
            isServiceRequirements: false,
            selectedServiceId: this.props.entry ? this.props.entry.serviceId : null,
        };
        this.handleServiceChange = this.handleServiceChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        this.props.loadServices();
    }

    handleServiceChange(services: IServiceOverview[], event: React.ChangeEvent<HTMLSelectElement>) {
        const service = services[event.currentTarget.value];
        this.setState({ selectedServiceId: service ? service.id : null });
    }

    onDelete(entryId?: number) {
        if (entryId) {
            toastifyPromise(
                MarketingAPI.deleteMarketing(entryId),
                'Failed to delete marketing entry',
                'Marketing Entry deleted',
            ).then(() => this.props.onCreated());
        }
    }

    render() {
        const InitialValues: MarketingFormValues = {
            id: this.props.entry ? this.props.entry.id : 0,
            headline: this.props.entry ? this.props.entry.headline : '',
            description: this.props.entry ? this.props.entry.description : '',
            imageUrl: this.props.entry ? this.props.entry.imageUrl : '',
            serviceId: this.props.entry ? this.props.entry.serviceId : null,
            isServiceRequirements: this.props.entry ? this.props.entry.serviceId !== null : false,
        };

        const createSubset = (values: MarketingFormValues): MarketingEntry => {
            return {
                id: values.id,
                headline: values.headline,
                description: values.description,
                imageUrl: values.imageUrl,
                serviceId: values.serviceId,
            };
        };

        return (
            <Formik
                initialValues={InitialValues}
                onSubmit={(values, { setSubmitting }) => {
                    if (!this.state.selectedServiceId && values.isServiceRequirements) {
                        values.serviceId = this.props.services[0].id;
                    } else if (values.isServiceRequirements) {
                        values.serviceId = this.state.selectedServiceId;
                    } else {
                        values.serviceId = null;
                    }
                    const succMessage = values.id === 0 ? 'Marketing Entry created' : 'Marketing Entry updated';
                    toastifyPromise(
                        MarketingAPI.updateMarketing(createSubset(values)).then(() => this.props.onCreated()),
                        'Failed to create marketing entry',
                        succMessage,
                    ).finally(() => setSubmitting(false));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Section>
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Headline</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="headline"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.headline}
                                        />
                                        {errors.headline && touched.headline && errors.headline}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                        />
                                        {errors.description && touched.description && errors.description}
                                    </Form.Group>
                                    {values.isServiceRequirements && (
                                        <Form.Group>
                                            <InputSelectionBox
                                                label="SERVICE"
                                                placeholder="Services"
                                                defaultValue={this.props.services
                                                    .findIndex(elem => elem.id === values.serviceId)
                                                    .toString()}
                                                handleChange={event =>
                                                    this.handleServiceChange(this.props.services, event)
                                                }
                                                list={this.props.services.map(service => service.name)}
                                            />
                                            {errors.serviceId && touched.serviceId && errors.serviceId}
                                        </Form.Group>
                                    )}

                                    <Form.Group>
                                        <Form.Check
                                            onChange={handleChange}
                                            name="isServiceRequirements"
                                            type="checkbox"
                                            checked={values.isServiceRequirements}
                                            label="Create service specific entry"
                                        />
                                        {errors.isServiceRequirements &&
                                            touched.isServiceRequirements &&
                                            errors.isServiceRequirements}
                                    </Form.Group>
                                </Col>

                                <Form.Control
                                    hidden={true}
                                    type="text"
                                    name={`imageUrl`}
                                    onChange={handleChange}
                                    value={values.imageUrl}
                                />
                                <ImageUploadForm
                                    setFieldValue={setFieldValue}
                                    fieldName="imageUrl"
                                    value={values.imageUrl}
                                />
                            </Form.Row>
                        </Section>

                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting || values.headline.length < minLengthHeadline || !values.description}
                        >
                            {this.props.buttonLabel}
                        </Button>

                        {this.props.buttonLabel !== 'Create Entry' && (
                            <Button variant="danger" onClick={() => this.onDelete(values.id)}>
                                <span>
                                    <FontAwesomeIcon icon="trash-alt" /> Delete
                                </span>
                            </Button>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}
const mapStateToProps = (state: RootStore) => ({
    services: selectServicesItems(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadServices: () => dispatch(loadServices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMarketingForm);
