import React, { Component } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import MessageAPI from 'utils/api/messages.api';
import { IPostMessage } from 'types';
import { Spacing } from 'config/styling.constants';
import { toastifyPromise } from '../../../../utils/toast-utils';

const SectionContainer = styled.section`
    background-color: #f2f2f2;
    margin-top: ${Spacing.md};
    margin-bottom: ${Spacing.md};
`;

const LeftBlock = styled.div`
    margin: 0 0 10px;

    @media (min-width: 840px) {
        flex: 1;
    }
`;

const RightBlock = styled.div`
    margin: 0;
    @media (min-width: 840px) {
        flex: 2;
        margin: 0 0 0 40px;
    }
`;

const Row = styled.div`
    @media (min-width: 840px) {
        display: flex;
    }
`;

const MessageWrapper = styled.div`
    background-size: 266px 266px;
    padding: ${Spacing.md};
`;

interface BroadcastMessageFormProps {
    serviceId: string;
}

class BroadcastMessageForm extends Component<BroadcastMessageFormProps> {
    render() {
        const InitialValues: IPostMessage = {
            message: '',
        };

        return (
            <SectionContainer>
                <MessageWrapper>
                    <Formik
                        initialValues={InitialValues}
                        onSubmit={(values, { setSubmitting, resetForm }) =>
                            toastifyPromise(
                                MessageAPI.postBroadcastMessage(this.props.serviceId, values),
                                'Failed to submit broadcast message',
                                'Broadcast message sent successfully',
                            )
                                .then(() => resetForm())
                                .finally(() => setSubmitting(false))
                        }
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <LeftBlock>
                                        <h3>Broadcast Message</h3>
                                        <p>Send a message to all users of this service</p>
                                    </LeftBlock>
                                    <RightBlock>
                                        <Form.Group>
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="message"
                                                placeholder="Your Message"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.message}
                                            />
                                        </Form.Group>

                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={isSubmitting || !values.message}
                                        >
                                            Send Message
                                        </Button>
                                    </RightBlock>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </MessageWrapper>
            </SectionContainer>
        );
    }
}

export default BroadcastMessageForm;
