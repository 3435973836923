import React from 'react';
import styled from 'styled-components';
import { Typography } from 'config/styling.constants';
import CheckboxGroup from 'components/inputs/CheckboxGroup';

const ServiceFilterContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;
    margin-bottom: 20px;
    flex-wrap: wrap;
`;

const Heading = styled.h5`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: ${Typography.baseFontFamily};
`;

const FilterGroup = styled.div`
    display: flex;
    gap: 40px;
    flex-wrap: nowrap;
    align-items: center;
`;

interface ServiceFiltersProps {
    filters: {
        active: string | string[] | null;
        visible: string | string[] | null;
        internal: string | string[] | null;
    };
    onFilterChange: (tag: 'active' | 'visible' | 'internal', selectedValue: string | string[] | null) => void;
}

const ServiceFilters: React.FC<ServiceFiltersProps> = ({ filters, onFilterChange }) => {
    const filterOptions: { tag: 'active' | 'visible' | 'internal'; options: { label: string; value: string }[] }[] = [
        {
            tag: 'active',
            options: [
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' },
            ],
        },
        {
            tag: 'visible',
            options: [
                { label: 'Visible', value: 'visible' },
                { label: 'Hidden', value: 'hidden' },
            ],
        },
        {
            tag: 'internal',
            options: [
                { label: 'Public', value: 'public' },
                { label: 'Internal', value: 'internal' },
            ],
        },
    ];

    return (
        <ServiceFilterContainer>
            <Heading>Filter services by status</Heading>
            <FilterGroup>
                {filterOptions.map(({ tag, options }) => (
                    <CheckboxGroup
                        key={tag}
                        options={options}
                        selectedValue={filters[tag]}
                        handleCheckboxChange={selectedValue => onFilterChange(tag, selectedValue)}
                        isExclusive={true}
                    />
                ))}
            </FilterGroup>
        </ServiceFilterContainer>
    );
};

export default ServiceFilters;
