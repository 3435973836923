import React from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import ServiceAPI from 'utils/api/services.api';
import { Spacing } from 'config/styling.constants';
import { toastifyPromise } from '../../../../utils/toast-utils';

const SectionContainer = styled.section`
    background-color: #f2f2f2;
    margin-top: ${Spacing.md};
    margin-bottom: ${Spacing.md};
`;

const LeftBlock = styled.div`
    margin: 0 0 10px;

    @media (min-width: 840px) {
        flex: 1;
    }
`;

const RightBlock = styled.div`
    margin: 0;
    @media (min-width: 840px) {
        flex: 2;
        margin: 0 0 0 40px;
    }
`;

const Row = styled.div`
    @media (min-width: 840px) {
        display: flex;
    }
`;

const MessageWrapper = styled.div`
    background-size: 266px 266px;
    padding: ${Spacing.md};
`;

interface LimitSubscriptionMember {
    serviceId: string;
    maxSubscriptionCount: number | null;
    onSuccess: () => void;
}

function LimitSubscriptionForm({ serviceId, maxSubscriptionCount, onSuccess }: LimitSubscriptionMember) {
    const InitialValues = {
        maxSubscriptionCount: maxSubscriptionCount,
    };
    const isValidLimit = maxLimit => {
        return /^[0-9]*$|^/i.test(maxLimit);
    };

    return (
        <SectionContainer>
            <MessageWrapper>
                <Formik
                    enableReinitialize={true}
                    initialValues={InitialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        if (isValidLimit(values.maxSubscriptionCount)) {
                            toastifyPromise(
                                ServiceAPI.updateLimitSubscriptionMember(serviceId, values.maxSubscriptionCount),
                                'Failed to save new limit',
                                'Limit has been saved successfully',
                            )
                                .then(() => onSuccess())
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        } else {
                            toast.error('Please enter a valid limit value!');
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <LeftBlock>
                                    <h3>Maximum Subscribers</h3>
                                    <p>You can limit the maximum number of subscribers</p>
                                </LeftBlock>
                                <RightBlock>
                                    <Form.Group>
                                        <Form.Label>Maximum Subscribers</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="maxSubscriptionCount"
                                            placeholder="e.g. 500"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={'' + values.maxSubscriptionCount}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" disabled={isSubmitting || !values}>
                                        Save limit
                                    </Button>
                                </RightBlock>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </MessageWrapper>
        </SectionContainer>
    );
}

export default LimitSubscriptionForm;
