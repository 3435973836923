import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';
import FlagIcon from 'utils/FlagIcon.js';
import DefaultBackgroundImage from 'assets/images/Bild_5.png';
import { RequirementCountryItem, ServicePreconditions } from 'types';
import MarketsUtils from 'utils/markets.utils';
import ServiceFactory from 'utils/service.factory';

export interface RequirementsSectionProps {
    readonly title?: string;
    readonly backgroundImage?: string;
    readonly preconditions?: ServicePreconditions;
}

const Section = styled.section<RequirementsSectionProps>`
    margin-top: ${Spacing.md};
    min-height: 370px;

    padding: ${Spacing.md} ${Spacing.md} ${Spacing.xl};
    background-color: ${Colors.black};
    color: ${Colors.white};
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;

    ${props =>
        props.backgroundImage &&
        css`
            background-image: linear-gradient(to right, black 35%, rgba(0, 0, 0, 0.2)), url(${props.backgroundImage});
        `}
`;

const BlockContent = styled.div`
    background-color: transparent;

    @media (min-width: 640px) {
        display: flex;
        background-color: transparent;
        align-items: flex-start;
        justify-content: space-between;
    }
`;

const Colum = styled.dl`
    margin-top: ${Spacing.md};

    @media (min-width: 640px) {
        margin-top: 0;
        flex-basis: 30%;
    }
`;

const ColumTitle = styled.dt`
    margin-bottom: ${Spacing.sm};
    padding-bottom: ${Spacing.xs};
    border-bottom: 2px solid ${Colors.lightGrey};
    color: ${Colors.lightGrey};
    font-size: ${Typography.smallTextFontSize};
    line-height: ${Typography.smallTextLineHeight};
    letter-spacing: ${Typography.smallTextLetterSpacing};
    font-weight: bold;
`;

const ColumContent = styled.dd`
    font-size: ${Typography.bodyTextFontSize};
    line-height: ${Typography.bodyTextLineHeight};
    letter-spacing: ${Typography.bodyTextLetterSpacing};
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: ${Spacing.sm};
    }
`;

const FlagContainer = styled.div`
    max-width: 30px;
    max-height: 20px;
    margin-right: ${Spacing.sm};
`;

const RequirementsSection: React.FC<RequirementsSectionProps> = ({ title, preconditions, backgroundImage }) => {
    const pre = preconditions ? preconditions : ServiceFactory.createEmptyPreconditions();
    const locations = pre.markets ? MarketsUtils.mapServiceRequirements(pre.markets) : undefined;

    return (
        <Section backgroundImage={backgroundImage || DefaultBackgroundImage}>
            <h2>{title}</h2>
            <BlockContent>
                <Colum>
                    {locations && (
                        <Fragment>
                            <ColumTitle>COUNTRY</ColumTitle>
                            {locations.map((item: RequirementCountryItem) => (
                                <ColumContent key={item.countryCode}>
                                    <FlagContainer>
                                        <FlagIcon code={item.countryCode} size="lg" />
                                    </FlagContainer>
                                    {item.label}
                                </ColumContent>
                            ))}
                        </Fragment>
                    )}
                </Colum>
                <Colum>
                    {pre.vehiclePreconditions.length > 0 && (
                        <Fragment>
                            <ColumTitle>VEHICLE</ColumTitle>
                            {pre.vehiclePreconditions.map((item, index) => (
                                <ColumContent key={index}>{item}</ColumContent>
                            ))}
                        </Fragment>
                    )}
                </Colum>
                <Colum>
                    {pre.additionalPreconditions.length > 0 && (
                        <Fragment>
                            <ColumTitle>ADDITIONAL</ColumTitle>
                            {pre.additionalPreconditions.map((item, index) => (
                                <ColumContent key={index}>{item}</ColumContent>
                            ))}
                        </Fragment>
                    )}
                </Colum>
            </BlockContent>
        </Section>
    );
};
export default RequirementsSection;
