import { createActionCreator } from 'deox';
import { RootStore } from 'types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import managersApi from '../../utils/api/managers.api';
import { IManager } from '../../types/manager.types';

function _loadServiceOwnersThunk() {
    return async (dispatch: ThunkDispatch<RootStore, {}, AnyAction>) => {
        dispatch(loadServiceOwners.next());

        try {
            const serviceOwners = await managersApi.getServiceOwners();

            dispatch(loadServiceOwners.complete(serviceOwners));
        } catch (error) {
            dispatch(loadServiceOwners.error(error));
        }
    };
}

export const loadServiceOwners = Object.assign(_loadServiceOwnersThunk, {
    next: createActionCreator('LOAD_SERVICE_OWNERS_NEXT'),
    complete: createActionCreator('LOAD_SERVICE_OWNERS_COMPLETE', resolve => (serviceOwners: IManager[]) =>
        resolve(serviceOwners),
    ),
    error: createActionCreator('LOAD_SERVICE_OWNERS_ERROR', resolve => (error: string) => resolve(error)),
});
