import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';
import { Field, FieldArray } from 'formik';
import TagInput from 'components/inputs/tag-input';
import { IServiceMarket } from 'types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import marketsUtils from 'utils/markets.utils';
import FlagIcon from 'utils/FlagIcon';

const Label = styled.label`
    font-family: ${Typography.baseFontFamily};
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
    color: ${Colors.black};
    font-weight: bold;
`;

const CityLabel = styled.span`
    font-family: ${Typography.baseFontFamily};
    font-size: ${Typography.baseFontSize};
    font-weight: normal;
    text-transform: none;
`;

const CitiesListContainer = styled.div`
    margin-bottom: ${Spacing.lg};
`;

interface InputListBoxProps {
    values: IServiceMarket[];
    label: string;
    name: string;
}

function CitiesListControl(props: InputListBoxProps) {
    const { values, name } = props;

    return (
        <CitiesListContainer>
            <Label>{props.label}</Label>
            <FieldArray
                name={name}
                render={() => (
                    <Fragment>
                        {values.length === 0 && <p>Add markets in general tab.</p>}
                        {values.map((item, index) => (
                            <Form.Group key={item.code} as={Row} controlId="formPlaintextEmail">
                                <Form.Label column md={6}>
                                    <FlagIcon code={item.code.toLowerCase()} size="lg" className="marketFlag" />
                                    <CityLabel>{marketsUtils.getCountryLabel(item)}</CityLabel>
                                </Form.Label>
                                <Col md={6}>
                                    <Field
                                        name={`${name}.${index}.cities`}
                                        component={TagInput}
                                        placeholder={'Enter a city and press enter...'}
                                    />
                                </Col>
                            </Form.Group>
                        ))}
                    </Fragment>
                )}
            />
        </CitiesListContainer>
    );
}

export default CitiesListControl;
