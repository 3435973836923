import React from 'react';
import PageLayout from 'components/page.layout';
import CreateMarketingForm from './create.form';

const CreateMarketing = props => {
    const onCreated = () => props.history.push('/marketing');

    return (
        <PageLayout paddingTop="2em">
            <h1>Create New Marketing Entry</h1>
            <CreateMarketingForm onCreated={onCreated} buttonLabel="Create Entry" />
        </PageLayout>
    );
};
export default CreateMarketing;
