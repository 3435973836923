import React from 'react';
import PageLayout from '../../components/page.layout';
import { LoginContainer } from 'components/styled-components';
import useWENRedirect from '../hooks/useWENRedirect';
import Spinner from 'react-bootstrap/Spinner';

const Page: React.FC = () => {
    useWENRedirect();
    return (
        <PageLayout paddingTop="2em">
            <LoginContainer className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
            </LoginContainer>
        </PageLayout>
    );
};

export default Page;
