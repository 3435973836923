import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from 'components/navigation/navigation';
import Footer from 'components/footer';
import ScrollToTop from 'components/scroll-to-top';
import AppStartComponent from 'components/app-start-component';
import Routes from './routes';
import Config from 'config/config';
import { Slide, ToastContainer } from 'react-toastify';

const App: React.FC = function() {
    return (
        <Router basename={Config.BASE_ROUTER_PATH}>
            <AppStartComponent />
            <ScrollToTop>
                <Navigation></Navigation>
                <Routes />
                <Footer></Footer>
            </ScrollToTop>
            <ToastContainer position="bottom-right" hideProgressBar transition={Slide} />
        </Router>
    );
};

export default App;
