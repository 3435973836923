import React from 'react';
import styled from 'styled-components';
import { Colors, Spacing } from 'config/styling.constants';
import NormalLogo from 'assets/images/logos/BMWGroupNext_BMW_MINI_Grey-Colour_RGB.png';
import { MobileButton, NavigationItem, NavigationLinks, NavLink } from './navigation-components';
import { AuthenticationStateEnum } from 'redux/session/session.selector';

interface NavigationItems {
    href: string;
    name: string;
    role: string;
}

export interface MobileNavigationProps {
    isOpen: boolean;
    onClick?: () => void;
    navItems?: NavigationItems[];
    authenticationState: AuthenticationStateEnum;
    logoutAction?: () => void;
}

const Container = styled.aside<MobileNavigationProps>`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    will-change: transform, visibility;
    transform: ${props => (props.isOpen ? `translateX(0%)` : `translateX(100%)`)};
    transition: visibility 150ms linear, transform 250ms ease-in-out;
    background-color: ${Colors.lightGrey};
    width: 100%;
    z-index: 3;
    max-height: 100vh;
    padding: ${Spacing.sm};
    overflow: auto;

    @media (min-width: 940px) {
        display: none;
    }
`;

const Header = styled.header`
    margin-bottom: ${Spacing.lg};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const Image = styled.img`
    max-width: 250px;
    transform: none;
    position: static;
`;

const MobileNavigation: React.FC<MobileNavigationProps> = ({
    isOpen,
    onClick,
    navItems,
    authenticationState,
    logoutAction,
}) => {
    const showNavigationElements = authenticationState === 'LOGGED_IN';
    const showLogoutButton = authenticationState === 'LOGGED_IN' || authenticationState === 'REQUIRES_ROLE_SELECTION';
    return (
        <Container isOpen={isOpen} authenticationState={authenticationState} className="mobile-navigation">
            <Header>
                <a href="/">
                    <Image src={NormalLogo} alt="BMW Group labs logo"></Image>
                </a>
                <MobileButton
                    onClick={onClick}
                    aria-haspopup="true"
                    aria-controls="mobile-nav"
                    aria-expanded="false"
                    close-nav
                >
                    &times; <span className="sr-only">Close menu</span>
                </MobileButton>
            </Header>
            <NavigationLinks>
                {showNavigationElements &&
                    navItems &&
                    navItems.map((item, index) => (
                        <NavigationItem key={index}>
                            <NavLink to={item.href} onClick={onClick}>
                                {item.name}
                            </NavLink>
                        </NavigationItem>
                    ))}
                {showLogoutButton && (
                    <NavigationItem key={'logout'}>
                        <NavLink
                            to={'/'}
                            onClick={() => {
                                if (onClick && logoutAction) {
                                    onClick();
                                    logoutAction();
                                }
                            }}
                        >
                            Logout
                        </NavLink>
                    </NavigationItem>
                )}
            </NavigationLinks>
        </Container>
    );
};

export default MobileNavigation;
