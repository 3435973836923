import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Colors, Gradients, Spacing, Typography } from 'config/styling.constants';
import { NAV_ITEMS } from 'config/constants';
import Logo from './logo';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootStore } from 'types';
import { ThunkDispatch } from 'redux-thunk';
import { logoutAction } from 'redux/session/session.actions';
import {
    AuthenticationStateEnum,
    selectSessionGetAuthenticationState,
    selectSessionRole,
    selectSessionUsername,
} from 'redux/session/session.selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownComponent from './dropdown';
import LoadingBar from 'react-redux-loading-bar';
import MobileNavigation from './mobile-navigation';
import { MobileButton, NavigationItem, NavigationLinks, NavLink } from './navigation-components';
import { nameForRole } from 'utils/session.utils';

const NavigationWrapper = styled.nav`
    background-color: ${Colors.lightGrey};
    padding: ${Spacing.sm};
`;

// outsource Container component
const Container = styled.div`
    max-width: 1024px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

export const DropdownLink = styled(Link)`
    color: ${Colors.darkGrey};
    width: 100%;
    font-weight: bold;
    text-decoration: none;
    font-size: ${Typography.headingLevelTwoFontSize};
    padding: ${Spacing.xs} ${Spacing.sm};
    display: inline-block;
    font-size: inherit;

    :hover {
        text-decoration: none;
        color: ${Colors.darkGrey};
        background-color: ${Colors.transparentBlue};
    }
`;

export const DropdownItem = styled.span`
    color: ${Colors.darkGrey};
    width: 100%;
    text-decoration: none;
    font-size: ${Typography.headingLevelTwoFontSize};
    padding: ${Spacing.xs} ${Spacing.sm};
    display: inline-block;
    font-size: inherit;
`;

const RightButton = styled.div`
    position: absolute;
    right: 120px;
`;

const UserIcon = <FontAwesomeIcon icon="user" color="grey" style={{ marginRight: '5px' }} />;

export interface NavigationProps {
    username?: string;
    authenticationState: AuthenticationStateEnum;
    role?: string;
    logoutAction: () => void;
}

interface NavigationState {
    isOpen: boolean;
}

class Navigation extends React.Component<NavigationProps, NavigationState> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler = () => {
        this.state.isOpen ? this.setState({ isOpen: false }) : this.setState({ isOpen: true });
    };

    render() {
        const showNavigationElements = this.props.authenticationState === 'LOGGED_IN';
        const showLogoutButton =
            this.props.authenticationState === 'LOGGED_IN' ||
            this.props.authenticationState === 'REQUIRES_ROLE_SELECTION';
        const role = this.props.role;
        const navItems = NAV_ITEMS.filter(value => value.role === role || value.role === '');
        return (
            <Fragment>
                <LoadingBar style={{ backgroundImage: Gradients.defaultBlue }} showFastActions />
                <NavigationWrapper>
                    <Container>
                        {showNavigationElements && <Logo href="/dashboard" />}
                        {!showNavigationElements && <Logo href="/" />}
                        <NavigationLinks>
                            {showNavigationElements &&
                                navItems.map((item, index) => (
                                    <NavigationItem key={index}>
                                        <NavLink to={item.href}>{item.name}</NavLink>
                                    </NavigationItem>
                                ))}

                            <RightButton>
                                {!showLogoutButton && (
                                    <NavigationItem marginRight={Spacing.sm}>
                                        <NavLink to="/login">Login</NavLink>
                                    </NavigationItem>
                                )}
                                {showLogoutButton && (
                                    <Fragment>
                                        <DropdownComponent title={this.props.username} icon={UserIcon}>
                                            <div>
                                                {role && <DropdownItem>Role: {nameForRole(role)}</DropdownItem>}
                                                <DropdownLink to="/login" onClick={() => this.props.logoutAction()}>
                                                    Logout
                                                </DropdownLink>
                                            </div>
                                        </DropdownComponent>
                                    </Fragment>
                                )}
                            </RightButton>
                        </NavigationLinks>
                        <MobileButton
                            onClick={this.onClickHandler}
                            aria-haspopup="true"
                            aria-controls="mobile-nav"
                            aria-expanded="false"
                            open-nav
                        >
                            &#x2630; <span className="sr-only">Open menu</span>
                        </MobileButton>
                    </Container>
                    <MobileNavigation
                        isOpen={this.state.isOpen}
                        onClick={this.onClickHandler}
                        navItems={navItems}
                        authenticationState={this.props.authenticationState}
                        logoutAction={this.props.logoutAction}
                    />
                </NavigationWrapper>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootStore) => ({
    username: selectSessionUsername(state),
    role: selectSessionRole(state),
    authenticationState: selectSessionGetAuthenticationState(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    logoutAction: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
