export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    IS_DEV: process.env.NODE_ENV !== 'production',
    BMW_ENV: process.env.REACT_APP_BMW_ENV as 'LOCAL' | 'INT' | 'PROD',
    LOADING_BAR_CONFIG: {
        promiseTypeSuffixes: ['NEXT', 'COMPLETE', 'ERROR'],
    },
    BASE_URL: process.env.REACT_APP_BACKEND_URL || '/',
    BASE_ROUTER_PATH: process.env.PUBLIC_URL || '/',
    REACT_APP_WEN_AM_BASE_URL: process.env.REACT_APP_WEN_AM_BASE_URL || '',
    REACT_APP_WEN_REALM_PATH: process.env.REACT_APP_WEN_REALM_PATH || '',
    REACT_APP_WEN_CLIENT_ID: process.env.REACT_APP_WEN_CLIENT_ID || '',
    REACT_APP_WEN_REDIRECT_URI: process.env.REACT_APP_WEN_REDIRECT_URI || '',
};
