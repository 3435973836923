import React, { useEffect } from 'react';
import styled from 'styled-components';
import PageLayout from 'components/page.layout';
import { IBroadcastConversation, IConversationOverview, IMessage, IPostMessage, RootStore } from 'types';
import MessageAPI from 'utils/api/messages.api';
import Button from 'components/button';
import { connect } from 'react-redux';
import {
    selectMailboxAdminConversations,
    selectMailboxError,
    selectMailboxIsFetching,
    selectMailboxMessages,
    selectMailboxServiceConversations,
} from 'redux/mailbox/mailbox.selector';
import { ThunkDispatch } from 'redux-thunk';
import { fetchConversations, fetchMessages, resetMessages } from 'redux/mailbox/mailbox.actions';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Title } from 'components/styled-components';
import MessageBox from './message-box';
import ConversationBox from './conversation-box';
import _, { findIndex } from 'lodash';
import { Colors, Spacing } from 'config/styling.constants';
import { toastifyPromise } from '../../utils/toast-utils';
import { toast } from 'react-toastify';
import { selectSessionIsAdmin } from '../../redux/session/session.selector';

const Row = styled.div`
    display: flex;
    margin-top: ${Spacing.xs};
`;

const Left = styled.div`
    flex: 1;
`;

const Right = styled.div`
    flex: 2;
    padding-left: 15px;
`;

const buttonStyle = {
    color: Colors.cyan,
    backgroundColor: Colors.white,
};

const buttonActiveStyle = {
    color: Colors.white,
    backgroundColor: Colors.cyan,
};

interface MailboxState {
    selectedConversationId?: string;
    selectedBroadcastIndex?: number;
    broadcasts: IBroadcastConversation[];
    windowWidth: number;
}

interface MailboxProps extends RouteComponentProps {
    conversations: IConversationOverview[];
    conversationMessages: IMessage[];
    isFetchingMessages: boolean;
    username: string;
    isAdmin: boolean;
    fetchConversations: () => void;
    fetchMessages: (conversationId: string) => void;
    resetMessages: () => void;
    error?: string;
}

class Mailbox extends React.Component<MailboxProps, MailboxState> {
    constructor(props: MailboxProps) {
        super(props);
        this.state = {
            selectedConversationId: this.getQueryParameter('conversationId'),
            selectedBroadcastIndex: undefined,
            broadcasts: [],
            windowWidth: window.innerWidth,
        };

        this.selectConversationByIndex = this.selectConversationByIndex.bind(this);
        this.selectBroadcastByIndex = this.selectBroadcastByIndex.bind(this);
        this.getQueryParameter = this.getQueryParameter.bind(this);
        this.getIndexByConversation = this.getIndexByConversation.bind(this);
        this.getConversation = this.getConversation.bind(this);
        this.onSendMessage = this.onSendMessage.bind(this);
        this.updateWindowWidth = this.updateWindowWidth.bind(this);
    }

    componentDidMount() {
        this.loadBroadcasts();
        if (this.props.error) {
            toast('Failed to fetch mailbox messages');
        }
        const selectedIndex = this.getIndexByConversation(this.state.selectedConversationId);
        this.selectConversationByIndex(selectedIndex ? selectedIndex : 0);
        window.addEventListener('resize', this.updateWindowWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    }

    updateWindowWidth() {
        this.setState({ windowWidth: window.innerWidth });
    }

    getQueryParameter(name: string): string | undefined {
        const value = new URLSearchParams(this.props.location.search).get(name);
        return value ? value : undefined;
    }

    componentDidUpdate(prevProps: MailboxProps) {
        // Reload messages if conversations change or another conversation is selected
        const conversationsChanged = !_.isEqual(this.props.conversations, prevProps.conversations);

        // Entered the page, changed a tab or sent a new message (i.e. new conversations have been loaded)
        if (conversationsChanged) {
            const selectedIndex = this.getIndexByConversation(this.state.selectedConversationId);
            this.selectConversationByIndex(selectedIndex ? selectedIndex : 0);
        }
    }

    getBroadcastMessage(broadcasts, index: number): IMessage {
        const b = broadcasts[index];
        return {
            ...b,
            body: b.subtitle,
            senderName: b.channelName,
            createdAt: b.timestamp || '',
        };
    }

    loadBroadcasts() {
        MessageAPI.getBroadcasts().then(broadcasts => {
            if (broadcasts.length > 0) {
                this.setState({
                    broadcasts,
                    selectedBroadcastIndex: 0,
                });
            }
        });
    }

    selectBroadcastByIndex(broadcastIndex: number) {
        this.setState({
            selectedBroadcastIndex: broadcastIndex,
        });
    }

    selectConversationByIndex(conversationIndex: number) {
        const conversation = this.getConversation(conversationIndex);
        if (conversation) {
            const { id } = conversation;

            if (!this.props.isFetchingMessages) {
                this.props.fetchMessages(id);
            }

            this.setState({
                selectedConversationId: id,
            });
        } else {
            this.props.resetMessages();
        }
    }

    getConversation(conversationIndex: number): IConversationOverview | undefined {
        if (this.props.conversations.length > conversationIndex) {
            return this.props.conversations[conversationIndex];
        } else {
            return undefined;
        }
    }

    getIndexByConversation(conversationId?: string): number {
        const index = findIndex(this.props.conversations, c => c.id === conversationId);
        return index === -1 ? 0 : index;
    }

    onSendMessage(messageBody: string) {
        const conversation = this.props.conversations.find(
            conversation => conversation.id === this.state.selectedConversationId,
        );
        if (!conversation) {
            return;
        }
        const message: IPostMessage = {
            message: messageBody,
            recipientId: conversation.userId,
        };
        toastifyPromise(MessageAPI.postMessage(conversation.serviceId, message), 'Failed to send message').then(() => {
            // Reload conversations to reflect changes in order by latest message
            // Messages will be reloaded by componentDidUpdate
            this.props.fetchConversations();
        });
    }

    render() {
        return (
            <PageLayout paddingTop="2em">
                <Title marginBottom={Spacing.sm}>Mailbox</Title>
                <Row style={{ marginBottom: this.state.windowWidth <= 600 ? '10px' : undefined }}>
                    {this.props.isAdmin && (
                        <Button to="/mailbox" style={buttonStyle} activeStyle={buttonActiveStyle}>
                            General
                        </Button>
                    )}
                    <Button to="/mailbox/services" style={buttonStyle} activeStyle={buttonActiveStyle}>
                        Services
                    </Button>
                    <Button to="/mailbox/broadcasts" style={buttonStyle} activeStyle={buttonActiveStyle}>
                        Broadcasts
                    </Button>
                </Row>
                <Switch>
                    <Route path="/mailbox/broadcasts">
                        <ConversationContainer
                            conversations={this.state.broadcasts.map((conversation, index) => {
                                return {
                                    ...conversation,
                                    id: `${index}`,
                                    userId: '',
                                    userMail: '',
                                    hasUnreadMessages: false,
                                };
                            })}
                            selectedIndex={this.state.selectedBroadcastIndex}
                            selectedMessages={
                                this.state.broadcasts.length > 0
                                    ? this.state.broadcasts[this.state.selectedBroadcastIndex || 0].messages.map(
                                          conversation => {
                                              return {
                                                  ...conversation,
                                                  isIncoming: false,
                                                  isBroadcast: true,
                                              };
                                          },
                                      )
                                    : undefined
                            }
                            titleMode="SERVICE_NAME"
                            windowWidth={this.state.windowWidth}
                            selectConversation={this.selectBroadcastByIndex}
                        />
                    </Route>
                    <Route>
                        <ConversationContainer
                            conversations={this.props.conversations}
                            selectedIndex={this.getIndexByConversation(this.state.selectedConversationId)}
                            selectedMessages={this.props.conversationMessages}
                            titleMode="USER_MAIL"
                            windowWidth={this.state.windowWidth}
                            selectConversation={this.selectConversationByIndex}
                            onSendMessage={this.onSendMessage}
                        />
                    </Route>
                </Switch>
            </PageLayout>
        );
    }
}

interface ConversationContainerProps {
    conversations: IConversationOverview[];
    selectedIndex: number | undefined;
    selectedMessages: IMessage[] | undefined;
    titleMode: 'USER_MAIL' | 'SERVICE_NAME';
    windowWidth: number;
    selectConversation: (index: number) => void;
    onSendMessage?: (text: string) => void;
}

function ConversationContainer({
    conversations,
    selectedIndex,
    selectedMessages,
    titleMode,
    windowWidth,
    selectConversation,
    onSendMessage,
}: ConversationContainerProps) {
    return (
        <>
            {windowWidth > 600 && (
                <Row>
                    <Left>
                        <ConversationBox
                            conversations={conversations}
                            selectedIndex={selectedIndex}
                            selectConversation={selectConversation}
                        />
                    </Left>
                    <Right>
                        <MessageBox messages={selectedMessages || []} onSendMessage={onSendMessage} />
                    </Right>
                </Row>
            )}
            {windowWidth <= 600 && (
                <>
                    <ConversationBox
                        conversations={conversations}
                        selectedIndex={selectedIndex}
                        selectConversation={selectConversation}
                    />
                    <hr />
                    <h5 style={{ marginBottom: '20px' }}>
                        {conversations.length > 0
                            ? titleMode === 'USER_MAIL'
                                ? conversations[selectedIndex || 0].userMail
                                : conversations[selectedIndex || 0].serviceName
                            : ''}
                    </h5>
                    <MessageBox messages={selectedMessages || []} onSendMessage={onSendMessage} />
                </>
            )}
        </>
    );
}

const mapStateToProps = (state: RootStore) => ({
    serviceConversations: selectMailboxServiceConversations(state),
    adminConversations: selectMailboxAdminConversations(state),
    conversationMessages: selectMailboxMessages(state),
    isAdmin: selectSessionIsAdmin(state),
    error: selectMailboxError(state),
    isFetchingMessages: selectMailboxIsFetching(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    fetchConversations: () => dispatch(fetchConversations()),
    fetchMessages: (conversationId: string) => dispatch(fetchMessages(conversationId)),
    resetMessages: () => dispatch(resetMessages()),
});

interface MailboxConversationsSwitcherProps {
    serviceConversations: IConversationOverview[];
    adminConversations: IConversationOverview[];
    conversationMessages: IMessage[];
    username: string;
    isAdmin: boolean;
    fetchConversations: () => void;
    fetchMessages: (conversationId: string) => void;
    resetMessages: () => void;
    isFetchingMessages: boolean;
    error?: string;
}

const MailboxConversationsSwitcher: React.FC<MailboxConversationsSwitcherProps & RouteComponentProps> = props => {
    const { fetchConversations, adminConversations, serviceConversations } = props;

    useEffect(() => {
        fetchConversations();
    }, [fetchConversations]);

    return (
        <Switch>
            <Route path={'/mailbox/services'}>
                <Mailbox conversations={serviceConversations} {...props} />
            </Route>
            <Route path="/mailbox">
                <Mailbox conversations={adminConversations} {...props} />
            </Route>
        </Switch>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MailboxConversationsSwitcher);
