import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/checkmark_rg.svg';

interface CheckboxProps {
    isSelected: boolean;
    onClick: () => void;
}

const CheckboxWrapper = styled.div<CheckboxProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 2px solid #bbb;
    cursor: pointer;
    background: ${props => (props.isSelected ? '#262626' : 'transparent')};
    border: ${props => (props.isSelected ? 'none' : '2px solid #bbb')};

    &:hover {
        border-color: #666;
    }
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
    width: 16px;
    height: 16px;
    fill: white;
`;

export const Checkbox: React.FC<CheckboxProps> = ({ isSelected, onClick }) => (
    <CheckboxWrapper isSelected={isSelected} onClick={onClick}>
        {isSelected && <StyledCheckmarkIcon />}
    </CheckboxWrapper>
);
