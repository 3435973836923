import React from 'react';
import styled from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';
import { FlexContainer as SectionHeader } from 'components/styled-components';

interface SectionBlockProps {
    title?: string;
    buttons?: React.ReactNode;
    padding?: string;
}

const Section = styled.section`
    margin: ${Spacing.md} 0;
`;
interface SectionProps {
    padding?: string;
}

export const SectionContent = styled.section<SectionProps>`
    margin-top: 0;
    padding: ${props => (props.padding ? props.padding : `${Spacing.md} ${Spacing.md}`)};
    background-color: ${Colors.lightGrey};
`;

export const SectionTitle = styled.h3`
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.baseFontSize};
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: ${Spacing.sm};
`;

const ContentSection: React.FC<SectionBlockProps> = ({ title, children, buttons, padding }) => {
    return (
        <Section>
            <SectionHeader>
                <SectionTitle>{title}</SectionTitle>
                {buttons}
            </SectionHeader>

            <SectionContent padding={padding}>{children}</SectionContent>
        </Section>
    );
};
export default ContentSection;
