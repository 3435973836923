import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';

export class UsersAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    getUsers() {
        return this.client.get(`/users`).then(response => response.data);
    }

    deleteUsers(gcids: string[]) {
        const params = { gcids: gcids.join(',') };
        return this.client.delete('/users', { params });
    }
}

export default new UsersAPI(Client);
