import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors, Gradients, Spacing, Typography } from 'config/styling.constants';
import DefaultImage from 'assets/images/services/coming_soon_defaultpic.png';
import MarketsUtils from 'utils/markets.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IServiceOverview } from 'types';
import { Badge, GridElement } from 'components/styled-components';
import ServiceUtils from 'utils/service.utils';

interface ServiceChildProps {
    image?: string;
    disabled?: boolean;
}

interface ServiceTileProps extends ServiceChildProps {
    service: IServiceOverview;
    to?: string;
}

const GridItem = styled(GridElement)`
    a:hover {
        text-decoration: none;
    }
`;

const ServiceItem = styled.div<ServiceChildProps>`
    min-height: 300px;
    display: block;
    color: ${Colors.white};
    text-decoration: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    background-image: url("${props => props.image || `${DefaultImage}`}");
`;

const ServiceGradient = styled.div<ServiceChildProps>`
    background-image: ${Gradients.serviceTileDefault};
    padding: ${Spacing.sm};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .service:hover &,
    .service:focus & {
        background-image: ${props =>
            props.disabled ? `${Gradients.serviceTileDisabled}` : `${Gradients.serviceTileBlue}`};
    }
`;

const Title = styled.h3`
    font-family: ${Typography.boldFontFamily};
    font-size: ${Typography.headingLevelOneFontSize};
    line-height: ${Typography.headingLevelOneLineHeight};
    letter-spacing: ${Typography.headingLevelOneLetterSpacing};
    margin-top: ${Spacing.sm};
`;

const Overview = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    gap: 10px;
`;

const UserCount = styled(Badge)`
    padding: ${Spacing.xs};
    margin-right: ${Spacing.sm};
    margin-bottom: ${Spacing.sm};
    position: absolute;
    bottom: 0;
    right: 0;
`;

export default function ServiceTile({ service, to }: ServiceTileProps) {
    const upperCaseTitle = service.name ? service.name.toUpperCase() : '';
    const toLink = to || '';
    const locations = service.markets ? MarketsUtils.mapServiceTile(service.markets) : '';

    return (
        <GridItem>
            <Link to={toLink} className="service">
                <ServiceItem image={service.imageUrl}>
                    <ServiceGradient disabled={!service.active}>
                        <Overview>
                            <Badge>{ServiceUtils.activeTitle(service)}</Badge>
                            <Badge>{ServiceUtils.hiddenTitle(service)}</Badge>
                            <Badge>{ServiceUtils.internalTitle(service)}</Badge>
                        </Overview>
                        <Title>{upperCaseTitle}</Title>
                        <p>{locations}</p>
                        <UserCount>
                            {service.subscribedUsersCount} <FontAwesomeIcon icon="user-friends" />
                        </UserCount>
                    </ServiceGradient>
                </ServiceItem>
            </Link>
        </GridItem>
    );
}
