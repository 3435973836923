export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SERVICE_OWNER = 'ROLE_SERVICE_OWNER';

export function nameForRole(role: string) {
    switch (role) {
        case ROLE_ADMIN:
            return 'Admin';
        case ROLE_SERVICE_OWNER:
            return 'Service owner';
        default:
            return '';
    }
}
