import { IConfigMarket, RootStore, TemplateRequirement } from 'types';

export function selectConfigurationMarkets(state: RootStore): IConfigMarket[] {
    return state.configuration.markets;
}

export function selectConfigurationRequirements(state: RootStore): TemplateRequirement[] {
    return state.configuration.requirements;
}

export function selectConfigurationError(state: RootStore): string {
    return state.configuration.error;
}
