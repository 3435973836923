import React from 'react';
import ServiceGrid from './grid';
import PageLayout from 'components/page.layout';
import Button from 'components/button';
import { selectSessionIsAdmin } from 'redux/session/session.selector';
import { connect } from 'react-redux';
import { RootStore } from 'types';

interface ServiceOverviewProps {
    isAdmin: boolean;
}

const ServiceOverview = (props: ServiceOverviewProps) => {
    return (
        <PageLayout paddingTop="2em">
            <h1>Services</h1>
            {!props.isAdmin && <Button to="/services/create">Create new Service</Button>}
            <ServiceGrid />
        </PageLayout>
    );
};

const mapStateToProps = (state: RootStore) => ({
    isAdmin: selectSessionIsAdmin(state),
});

export default connect(mapStateToProps)(ServiceOverview);
