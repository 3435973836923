import { IServiceOverview, RootStore } from 'types';

export function selectServicesIsFetchingState(state: RootStore): boolean {
    return state.servicesOverview.isFetching;
}

export function selectServicesErrorState(state: RootStore): string {
    return state.servicesOverview.error;
}

export function selectServicesItems(state: RootStore): IServiceOverview[] {
    return state.servicesOverview.services;
}

export function selectLastServices(state: RootStore): IServiceOverview[] {
    return state.servicesOverview.services.filter((value: IServiceOverview) => value.active).slice(0, 3) || [];
}
