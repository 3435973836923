import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import { loadMarketing } from './marketing.actions';
import { MarketingEntry } from 'types';

export const defaultIsFetchingState = false;
export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
    handleAction(loadMarketing.next, () => true),
    handleAction([loadMarketing.complete, loadMarketing.error], () => false),
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(loadMarketing.complete, () => defaultErrorState),
    handleAction(loadMarketing.error, (_, { payload }) => payload),
]);

export const defaultMarketingState: MarketingEntry[] = [];
export const marketingReducer = createReducer(defaultMarketingState, handleAction => [
    handleAction(loadMarketing.complete, (_, { payload }) => payload),
    handleAction(loadMarketing.error, status => status),
]);

export type MarketingStore = ReturnType<typeof marketingEntryReducer>;
export const marketingEntryReducer = combineReducers({
    isFetching: isFetchingReducer,
    error: errorReducer,
    marketing: marketingReducer,
});
