import { combineReducers } from 'redux';
import { mailboxReducer as mailbox } from './mailbox/mailbox.reducer';
import { serviceOverviewReducer as servicesOverview } from 'redux/servicesOverview/services-overview.reducer';
import { serviceDetailsReducer as serviceDetails } from 'redux/serviceDetails/service-details.reducer';
import { usersManagementReducer as usersManagement } from 'redux/usersManagement/users.reducer';
import { adminsManagementReducer as adminsManagement } from 'redux/usersManagement/admins.reducer';
import { serviceOwnersManagementReducer as serviceOwnersManagement } from 'redux/usersManagement/service-owners.reducer';
import { sessionReducer as session } from 'redux/session/session.reducer';
import { configurationReducer as configuration } from 'redux/configuration/configuration.reducer';
import { marketingEntryReducer as marketing } from 'redux/marketing/marketing.reducer';
import { loadingBarReducer } from 'react-redux-loading-bar';

export default combineReducers({
    session,
    servicesOverview,
    serviceDetails,
    usersManagement,
    adminsManagement,
    serviceOwnersManagement,
    configuration,
    mailbox,
    marketing,
    loadingBar: loadingBarReducer,
});
