import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Colors, Spacing, Typography } from 'config/styling.constants';

type BlockButtonProps = {
    onAddClicked: () => void;
    onRemoveClicked: () => void;
    showRemoveButton: boolean;
    showAddButton: boolean;
};

const TextButton = styled.button`
    padding: 0;
    margin: 0;
    border-radius: 0;
    font-weight: bold;
    line-height: ${Typography.bodyTextLineHeight};
    font-size: ${Typography.bodyTextFontSize};
    letter-spacing: ${Typography.bodyTextLetterSpacing};
    background-color: transparent;
    border-color: transparent;
    color: ${Colors.cyan};
    padding: ${Spacing.xs};

    :hover {
        color: ${Colors.petrol};
    }
    :focus {
        outline: 0;
    }
`;

const BlockButtons: React.FC<BlockButtonProps> = ({
    onAddClicked,
    onRemoveClicked,
    showRemoveButton,
    showAddButton,
}) => {
    return (
        <div>
            {showAddButton && (
                <TextButton type="button" onClick={onAddClicked}>
                    <FontAwesomeIcon icon="plus" /> Add
                </TextButton>
            )}
            {showRemoveButton && (
                <TextButton type="button" onClick={onRemoveClicked}>
                    <FontAwesomeIcon icon="trash-alt" /> Remove
                </TextButton>
            )}
        </div>
    );
};

export default BlockButtons;
