import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ConfirmAlertProps {
    show: boolean;
    title: string;
    onDone: (confirmed: boolean) => void;
}

export const ConfirmAlert: React.FC<ConfirmAlertProps> = ({ show, title, children, onDone }) => {
    return (
        <>
            <Modal show={show} onHide={() => onDone(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => onDone(true)}>
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={() => onDone(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
