export const USER_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';
export const USER_SESSION_ATTRIBUTE_TOKEN = 'userToken';
export const USER_SESSION_ATTRIBUTE_ASSUMED_ROLE = 'userRole';
export const USER_SESSION_ATTRIBUTE_AUTHORIZED_ROLES = 'authorizedRoles';
const USER_SESSION_ATTRIBUTE_STATE = 'state';
const USER_SESSION_ATTRIBUTE_NONCE = 'nonce';

class LocalStorageService {
    saveSession(token: string, username: string, roles: string[]) {
        localStorage.setItem(USER_SESSION_ATTRIBUTE_TOKEN, token);
        localStorage.setItem(USER_SESSION_ATTRIBUTE_NAME, username);
        if (roles.length === 1) {
            this.saveRole(roles[0]);
        } else {
            this.saveRoles(roles);
        }
    }

    saveRoles(roles: string[]) {
        localStorage.setItem(USER_SESSION_ATTRIBUTE_AUTHORIZED_ROLES, JSON.stringify(roles));
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_ASSUMED_ROLE);
    }

    saveRole(role: string) {
        localStorage.setItem(USER_SESSION_ATTRIBUTE_ASSUMED_ROLE, role);
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_AUTHORIZED_ROLES);
    }

    removeSession() {
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_NAME);
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_TOKEN);
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_ASSUMED_ROLE);
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_AUTHORIZED_ROLES);
    }

    getToken() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_TOKEN);
    }

    getState() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_STATE);
    }

    getUserAuthorizedRoles() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_AUTHORIZED_ROLES);
    }

    getAssumedRole() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_ASSUMED_ROLE);
    }

    getNonce() {
        return localStorage.getItem(USER_SESSION_ATTRIBUTE_NONCE);
    }

    saveState(state: string) {
        localStorage.setItem(USER_SESSION_ATTRIBUTE_STATE, state);
        return state;
    }
    saveNonce(nonce: string) {
        localStorage.setItem(USER_SESSION_ATTRIBUTE_NONCE, nonce);
        return nonce;
    }
    removeNonce() {
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_NONCE);
    }
    removeState() {
        localStorage.removeItem(USER_SESSION_ATTRIBUTE_STATE);
    }
}

export default new LocalStorageService();
