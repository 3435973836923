import React, { Fragment } from 'react';
import styled from 'styled-components';
import { IMessage } from 'types';
import MessageItem from 'components/mailbox/message-item';
import { scroller } from 'react-scroll';
import ReplyForm from 'components/mailbox/reply.form';

const MessagesContainer = styled.div`
    overflow-x: hidden;
    height: auto;
    max-height: 60vh;
    min-height: 60vh;
    overflow-anchor: auto;
`;

interface MessageBoxProps {
    messages: IMessage[];
    onSendMessage?: (text: string) => void;
}

class MessageBox extends React.Component<MessageBoxProps> {
    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps: MessageBoxProps) {
        if (this.props.messages !== prevProps.messages) {
            this.scrollToBottom();
        }
    }

    scrollToBottom() {
        this.props.messages.length > 0 &&
            scroller.scrollTo(`message-${this.props.messages.length - 1}`, {
                delay: 0,
                containerId: 'message-box',
            });
    }

    render() {
        const { messages, onSendMessage } = this.props;
        const sortedMessages = messages.sort((a: IMessage, b: IMessage) => {
            if (!a && !b) return 0;
            if (!a) return -1;
            if (!b) return +1;
            const aD = new Date(a.createdAt);
            const bD = new Date(b.createdAt);
            return aD.getTime() - bD.getTime();
        });

        return (
            <Fragment>
                <MessagesContainer id="message-box">
                    {sortedMessages.map((msg, index) => (
                        <MessageItem key={index} id={index} messageItem={msg} />
                    ))}
                </MessagesContainer>

                {messages.length > 0 && onSendMessage && <ReplyForm onSendMessage={onSendMessage} />}
            </Fragment>
        );
    }
}
export default MessageBox;
