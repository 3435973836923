import React, { useEffect } from 'react';
import { MarketingEntry, RootStore } from 'types';
import styled from 'styled-components';
import { Spacing } from 'config/styling.constants';
import MarketingTile from './marketing-tile';
import PageLayout from 'components/page.layout';
import { Title } from 'components/styled-components';
import Button from 'components/button';
import { ThunkDispatch } from 'redux-thunk';
import { loadMarketing } from 'redux/marketing/marketing.actions';
import { connect } from 'react-redux';

const Container = styled.div`
    padding-bottom: 3rem;
    padding-top: ${Spacing.sm};
`;

const MarketingGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

interface MarketingProps {
    serviceId: string;
    marketing: MarketingEntry[];
    loadMarketing: () => void;
}

const MarketingPage: React.FC<MarketingProps> = ({ serviceId, marketing, loadMarketing }) => {
    useEffect(() => {
        loadMarketing();
    }, [loadMarketing]);

    return (
        <PageLayout paddingTop="2em">
            <Title marginBottom={Spacing.sm}>Marketing</Title>
            <Button to="/marketing/create">Create new Marketing Entry</Button>
            <Container>
                <MarketingGrid>
                    {marketing.length > 0 &&
                        marketing.map(entry => (
                            <MarketingTile key={entry.id} entry={entry} to={`/marketing/update/${entry.id}`} />
                        ))}
                </MarketingGrid>
            </Container>
        </PageLayout>
    );
};

const mapStateToProps = (state: RootStore) => ({
    marketing: state.marketing.marketing,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    loadMarketing: () => dispatch(loadMarketing()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketingPage);
