import Client from 'utils/api/api-client';
import { AxiosInstance } from 'axios';
import { MarketingEntry } from 'types';

export class MarketingAPI {
    private client: AxiosInstance;

    constructor(axiosClient: AxiosInstance) {
        this.client = axiosClient;
    }

    getMarketing() {
        return this.client.get(`/marketing`).then(res => res.data);
    }

    updateMarketing(marketingEntry: MarketingEntry) {
        return this.client.put(`/marketing`, marketingEntry);
    }

    deleteMarketing(id: number) {
        const params = { id };
        return this.client.delete(`/marketing`, { params });
    }
}

export default new MarketingAPI(Client);
