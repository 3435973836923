import React, { Fragment } from 'react';
import styled from 'styled-components';
import Header from 'components/header/header-page';

const Main = styled.main`
    background-color: #f8f9fa;
    ${(props: PageLayoutProps) => props.paddingTop && `padding-top: ${props.paddingTop};`}
`;

const Container = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px 0 20px;
    min-height: 75vh;

    @media (max-width: 1024px) {
        padding: 20px 20px;
    }
`;

export interface PageLayoutProps {
    readonly title?: string;
    readonly headerImage?: string;
    readonly subtitle?: string;
    readonly isLarge?: boolean;
    readonly paddingTop?: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({ title, headerImage, subtitle, isLarge, children, paddingTop }) => {
    return (
        <Fragment>
            {title && <Header title={title} image={headerImage} isLarge={isLarge} subtitle={subtitle} />}
            <Main paddingTop={paddingTop}>
                <Container>{children && <div>{children}</div>}</Container>
            </Main>
        </Fragment>
    );
};
export default PageLayout;
