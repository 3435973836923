import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import { loadServices } from './services-overview.actions';
import { IServiceOverview } from 'types';

export const defaultIsFetchingState = false;
export const isFetchingReducer = createReducer(defaultIsFetchingState, handleAction => [
    handleAction(loadServices.next, () => true),
    handleAction([loadServices.complete, loadServices.error], () => false),
]);

export const defaultErrorState = '';
export const errorReducer = createReducer(defaultErrorState, handleAction => [
    handleAction(loadServices.complete, () => defaultErrorState),
    handleAction(loadServices.error, (_, { payload }) => payload),
]);

export const defaultServicesState: IServiceOverview[] = [];
export const servicesReducer = createReducer(defaultServicesState, handleAction => [
    handleAction(loadServices.complete, (_, { payload }) => payload.services),
    handleAction(loadServices.error, status => status),
]);

export const defaultLastUpdatedState = 0;
export const lastUpdatedReducer = createReducer(defaultLastUpdatedState, handleAction => [
    handleAction(loadServices.complete, (_, { payload }) => payload.receivedAt),
    handleAction(loadServices.error, status => status),
]);

export type IServicesStore = ReturnType<typeof serviceOverviewReducer>;
export const serviceOverviewReducer = combineReducers({
    isFetching: isFetchingReducer,
    error: errorReducer,
    services: servicesReducer,
    lastUpdated: lastUpdatedReducer,
});
