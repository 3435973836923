import React from 'react';
import styled from 'styled-components';
import { IMessage } from 'types';
import { Colors, Spacing } from 'config/styling.constants';

interface MessageWrapperProps {
    isIncoming: boolean;
    isOwn: boolean;
}

const MessageItemContainer = styled.div<MessageWrapperProps>`
    background-color: ${Colors.lightGrey};
    padding: ${Spacing.xs};
    background-color: ${props => (props.isOwn ? Colors.transparentBlue : Colors.white)};
    margin-bottom: 5px;
    margin-right: 60px;

    ${props =>
        !props.isIncoming &&
        `
        margin-right: 0;
        margin-left: 60px;
    `}
`;

const MessageInfo = styled.span`
    color: ${Colors.darkGrey};
    font-size: 13px;
`;

const BodyText = styled.p`
    font-size: 16px;
    margin-top: ${Spacing.xs};
    margin-bottom: ${Spacing.xs};
    white-space: pre-line;
`;

const FloatRight = styled.span`
    float: right;
`;

const BroadcastBadge = styled.span`
    padding: 2px 4px;
    margin-left: 8px;
    text-transform: uppercase;

    background-color: ${Colors.darkGrey};
    color: ${Colors.lightGrey};
`;

export interface MessageItemProps {
    messageItem: IMessage;
    id: number;
}

const MessageItem: React.FC<MessageItemProps> = ({ messageItem, id }) => {
    const createdDate = new Date(messageItem.createdAt);

    return (
        <MessageItemContainer isIncoming={messageItem.isIncoming} isOwn={messageItem.isOwn} id={`message-${id}`}>
            <MessageInfo>
                <FloatRight>{createdDate.toLocaleString()}</FloatRight>
                {messageItem.sender}
                {messageItem.isBroadcast && <BroadcastBadge>BROADCAST</BroadcastBadge>}
            </MessageInfo>
            <BodyText>{messageItem.message}</BodyText>
        </MessageItemContainer>
    );
};
export default MessageItem;
