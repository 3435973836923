import { createActionCreator } from 'deox';
import { ThunkDispatch } from 'redux-thunk';
import { RootStore, MarketingEntry } from 'types';
import api from 'utils/api/marketing.api';
import { AnyAction } from 'redux';

function _loadMarketingThunk() {
    return async (dispatch: ThunkDispatch<RootStore, {}, AnyAction>) => {
        dispatch(loadMarketing.next());

        try {
            const marketing = await api.getMarketing();

            dispatch(loadMarketing.complete(marketing));
        } catch (error) {
            dispatch(loadMarketing.error(JSON.stringify(error)));
        }
    };
}

export const loadMarketing = Object.assign(_loadMarketingThunk, {
    next: createActionCreator('FETCH_MARKETING_NEXT'),
    complete: createActionCreator('FETCH_MARKETING_COMPLETE', resolve => (marketing: MarketingEntry[]) =>
        resolve(marketing),
    ),
    error: createActionCreator('FETCH_MARKETING_ERROR', resolve => (error: string) => resolve(error)),
});
